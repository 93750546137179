import React, { useState, useEffect, useRef } from "react"

import { StaticImage } from "gatsby-plugin-image"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { SplitText } from "gsap/SplitText"
import "./klassenSection.css"

import {
  MotoIcon,
  AutoIcon,
  MofaIcon,
  TraktorIcon,
  KreiselIcon,
} from "../shared/icons"
import { Link } from "gatsby"

const KlassenSection = (): JSX.Element => {
  const klassen_section = React.useRef<HTMLDivElement>(null)
  const klassen_bg = React.useRef<HTMLDivElement>(null)

  const kreiselRef = useRef<HTMLDivElement>(null)

  const [init, setInit] = useState<boolean>(true)

  function resize(): void {
    if (klassen_section.current && klassen_bg.current) {
      // console.log(
      //   `hookRef div Height: ${klassen_section.current.clientHeight}`
      // )

      klassen_bg.current.style.height = `calc(${String(
        klassen_section.current.clientHeight
      )}px + 2rem)`
    }
  }

  // Update 'height' when the window resizes
  useEffect(() => {
    if (init) {
      setInit(false)
      resize()
    } else {
      window.addEventListener("resize", resize)
    }
  }, [init, setInit])

  // Use Effect für ScrollAnimationen
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, SplitText)

    console.clear()

    // SCHRÄGEHEITS ANI TYPO

    const body = document.getElementsByTagName("body")
    const split = new SplitText("#aas", { type: "chars,words,lines" })
    const splitB = new SplitText("#bs", { type: "chars,words,lines" })
    const splitM = new SplitText("#mofa", { type: "chars,words,lines" })
    const splitL = new SplitText("#l", { type: "chars,words,lines" })
    const splitUnd = new SplitText("#und", { type: "chars,words,lines" })
    const splitDich = new SplitText("#dich", { type: "chars,words,lines" })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: "#klassen-section",
          start: "top center-=10%",
        },
      })
      .from("#heading2", {
        x: -100,
        opacity: 0,
      })
      .from(split.words, {
        x: 200,
        opacity: 0,
        ease: "circ.out",
        stagger: 0.05,
        duration: 0.5,
      })
      .from(
        ".af-icon",
        {
          x: 200,
          opacity: 0,
          ease: "circ.out",
          stagger: 0.05,
          duration: 0.5,
        },
        "-=0.4"
      )
      .from("#warten", {
        y: 100,
        opacity: 0,
        ease: "power4.out",
      })

    // NICHT GENUTZTER ABSCHNITT
    // .from(split.words, {
    //   x: 200,
    //   opacity: 0,
    //   ease: "circ.out",
    //   stagger: 0.05,
    //   duration: 0.5,
    // })
    // .from(".icon-2", {
    //   x: innerWidth,
    //   ease: "power4.out ",
    // })
    // .from(splitB.words, {
    //   x: 200,
    //   opacity: 0,
    //   ease: "circ.out",
    //   stagger: 0.05,
    //   duration: 0.5,
    // })
    // .from(splitM.words, {
    //   x: 200,
    //   opacity: 0,
    //   ease: "circ.out",
    //   stagger: 0.05,
    //   duration: 0.5,
    // })
    // .from(".icon-3", {
    //   x: innerWidth,
    //   ease: "power4.out ",
    // })
    // .from(splitUnd.words, {
    //   y: 200,
    //   opacity: 0,
    //   ease: "circ.out",
    //   stagger: 0.05,
    //   duration: 0.5,
    // })
    // .from(splitL.words, {
    //   x: 200,
    //   opacity: 0,
    //   ease: "circ.out",
    //   stagger: 0.05,
    //   duration: 0.5,
    // })
    // .from(".icon-4", {
    //   x: innerWidth,
    //   ease: "power4.out ",
    // })
    // .from(splitDich.words, {
    //   y: 200,
    //   opacity: 0,
    //   ease: "circ.out",
    //   stagger: 0.15,
    //   duration: 0.5,
    // })
    // NICHT GENUTZER ABSCHNITT ENDE

    gsap.to("#kreisel-icon", {
      rotate: -720,
      scrollTrigger: {
        scrub: true,
      },
    })
  }, [])

  return (
    <>
      {/* Hintergrund Grün */}
      <div
        ref={klassen_bg}
        className="klassen-bg klassen-container absolute transform rotate-1 bg-primary left-0 scale-x-150 scale-y-105 -z-40 w-full"
      ></div>
      {/* FÜHRERSCHEINKLASSEN */}
      <div
        id="klassen-section"
        ref={klassen_section}
        className="pt-12 sm:pt-16 xl:pt-32 xxxl:pt-48 sm:flex sm:flex-wrap z-10 relative w-full  snap-center"
      >
        {/* Titel */}
        <div className="w-full">
          <h2
            id="heading2"
            className="text-white relative z-10 sm:w-2/3 lg:w-1/2"
          >
            Dein Führerschein in den Klassen
          </h2>
        </div>
        {/* Reihe Bild / Klassen */}
        <div className="0  w-full flex flex-wrap flex-col sm:flex-row sm:flex-nowrap ">
          {/* Spalte 1 Bild */}
          <div className="sm:w-full relative sm:flex sm:items-center pr-12 xll:pr-24">
            {/* Bild */}
            <div ref={kreiselRef} className="img-wrapper  relative">
              <StaticImage
                src="../../images/Fuehrerscheinklassen2.png"
                alt="Foto Führerscheinklassen Achims Fahrschule"
              ></StaticImage>
              {/* Kreisel */}
              <KreiselIcon
                isWhite
                className="w-32 absolute -right-16 -top-10 sm:-top-12 sm:-right-6 xll:-right-24 xll:w-48 xll:-top-24"
              />
            </div>
          </div>
          {/* Spalte 2 Klassen*/}
          <div className="sm:w-full sm:flex sm:flex-wrap sm:flex-col sm:items-center sm:justify-center sm:ml-auto">
            <div>
              <h2
                id="aas"
                className="text-white leading-relaxed text-2.25xl sm:text-3xl lg:4.5xl xl:text-6xl xxl:text-7xl aas "
              >
                <span className="letter-links">
                  {" "}
                  <Link to="/fuehrerscheinklassen/fuehrerscheinklasse-a1">
                    A1
                  </Link>
                  ,{" "}
                  <Link to="/fuehrerscheinklassen/fuehrerscheinklasse-a2">
                    A2
                  </Link>
                  ,{" "}
                  <Link to="/fuehrerscheinklassen/fuehrerscheinklasse-a">
                    A
                  </Link>
                  ,
                </span>
                <MotoIcon
                  isWhite
                  className="inline h-8 mb-2  ml-2 sm:ml-4 lg:h-12 xl:h-14 icon-1 af-icon"
                ></MotoIcon>{" "}
                <br />
                <span className="letter-links">
                  <Link to="/fuehrerscheinklassen/fuehrerscheinklasse-b">
                    B
                  </Link>
                  ,{" "}
                  <Link to="/fuehrerscheinklassen/fuehrerscheinklasse-b">
                    B197
                  </Link>
                  ,{" "}
                  <Link to="/fuehrerscheinklassen/fuehrerscheinklasse-be">
                    BE
                  </Link>
                </span>
                <AutoIcon
                  isWhite
                  className="inline fill-secondary h-8 mb-2 ml-2 sm:ml-4 lg:h-12 xl:h-14 af-icon"
                ></AutoIcon>
                <br />
                <span className="letter-links">
                  <Link to="/fuehrerscheinklassen/fuehrerscheinklasse-mofa">
                    Mofa
                  </Link>
                  ,
                </span>
                <MofaIcon
                  isWhite
                  className="inline h-8 mb-2 ml-2 sm:ml-4 lg:h-12 xl:h-14 af-icon"
                ></MofaIcon>
                <br />
                <span className="">und </span>
                <br />{" "}
                <span className="letter-links">
                  <Link to="/fuehrerscheinklassen/fuehrerscheinklasse-traktor">
                    L
                  </Link>{" "}
                </span>
                <TraktorIcon
                  isWhite
                  className="inline h-8 mb-2 ml-2 sm:ml-4 lg:h-12 xl:h-14 af-icon"
                ></TraktorIcon>
              </h2>
            </div>
          </div>
        </div>
        {/* Reihe Warten auf dich */}
        <div className="w-full">
          <h2 id="warten" className="text-white mb-0">
            ...wartet auf dich!
          </h2>
        </div>
      </div>
    </>
  )
}

export default KlassenSection
