import React from "react"
import { Link } from "gatsby"

type MoreButtonProps = {
  children: React.ReactNode
}

const MoreButton = ({ children }: MoreButtonProps): JSX.Element => (
  <button className="bg-secondary text-white text-sm xll:text-lg px-6 py-3 text-bold rounded-full">
    {children}
  </button>
)

export default MoreButton
