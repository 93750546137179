import React from "react"
import { classNameProps } from "../../types/classNameProps"

const BurgerMenu = ({ className }: classNameProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="62"
      height="36.717"
      viewBox="0 0 62 36.717"
      className={className}
    >
      <g transform="translate(-1798.5 -78.825)">
        <line
          x2="62"
          transform="translate(1798.5 80.325)"
          fill="none"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <line
          x2="62"
          transform="translate(1798.5 97.184)"
          fill="none"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <line
          x2="62"
          transform="translate(1798.5 114.042)"
          fill="none"
          strokeWidth="4"
          strokeLinecap="round"
        />
      </g>
    </svg>
  )
}

export default BurgerMenu
