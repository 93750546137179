/**
 *  Header component iconGrid
 *
 *  This statefull component shows how to use react hooks and basic property types with gatsby
 */
import React from "react"
import { gsap } from "gsap"
import { classNameProps } from "../../types/classNameProps"
import {
  MotoIcon,
  AutoIcon,
  MofaIcon,
  TraktorIcon,
  StopIcon,
  VorsichtIcon,
  UturnIcon,
} from "./icons"

const { useEffect, useRef } = React

const Icongrid = (className: classNameProps): JSX.Element => {
  const el = React.useRef<HTMLDivElement>(null)
  const q = gsap.utils.selector(el)

  // wait until DOM has been rendered
  useEffect(() => {
    gsap.from(q(".box"), {
      x: 100,
      opacity: 0,
      stagger: {
        from: "start",
        amount: 1.3,
        grid: [6, 6],
        ease: "power1.inout",
      },
    })
  }, [])

  const icons = []

  for (let i = 0; i < 36; i = i + 6) {
    // note: we are adding a key prop here to allow react to uniquely identify each
    // element in this array. see: https://reactjs.org/docs/lists-and-keys.html

    icons.push(
      <>
        <div
          className="w-10 h-10 md:w-20 md:h-20 box m-3 flex justify-center object-center"
          key={i}
        >
          <MotoIcon className="w-full "></MotoIcon>
        </div>
        <div
          className="w-10 h-10 md:w-20 md:h-20 box m-3 flex justify-center object-center"
          key={i + 1}
        >
          <AutoIcon className="w-16 "></AutoIcon>
        </div>
        <div
          className="w-10 h-10 md:w-20 md:h-20 box m-3 flex justify-center object-center"
          key={i + 2}
        >
          <MofaIcon className="w-full "></MofaIcon>
        </div>
        <div
          className="w-10 h-10 md:w-20 md:h-20 box m-3 flex justify-center object-center"
          key={i + 3}
        >
          <TraktorIcon className="w-full "></TraktorIcon>
        </div>
        <div
          className="w-10 h-10 md:w-16 md:h-16 box m-3 flex justify-center object-center"
          key={i + 4}
        >
          <VorsichtIcon className="w-full"></VorsichtIcon>
        </div>
        <div
          className="w-10 h-10 md:w-16 md:h-16 box m-3 flex justify-center object-center"
          key={i + 5}
        >
          <UturnIcon className="w-full"></UturnIcon>
        </div>
        <div
          className="w-10 h-10 md:w-16 md:h-16 box m-3 flex justify-center object-center"
          key={i + 5}
        >
          <StopIcon className="w-full"></StopIcon>
        </div>
      </>
    )
  }

  return (
    <div
      ref={el}
      className="grid grid-cols-7 grid-rows-1 fixed top-0 right-0 icon-container  opacity-10 "
    >
      {icons}
    </div>
  )
}

export default Icongrid
