/**
 *  SampleQuery page
 *
 *  This page shows how to use graphql with pages in gatsby
 */

import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

type SampleQueryProps = {
  site: {
    buildTime: string
  }
}

export const query = graphql`
  query GetBuildTime {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`

const SampleQuery = ({ data }: PageProps<SampleQueryProps>): JSX.Element => {
  const { buildTime } = data.site
  return (
    <Layout>
      <SEO title="home" />
      <h1>Hi from sample query page</h1>
      <p>Build @{buildTime}</p>
      <Link to="/">Go back to the landing page</Link>
    </Layout>
  )
}

export default SampleQuery
