/**
 *  404 page
 *
 *  This page will not be shown on dev mode
 *  However you can explicitly route to it
 */

import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = (): JSX.Element => (
  <Layout>
    <SEO title="404: Seite nicht gefunden" />
    <div className="container-fluid">
      <div className="grid place-items-center">
        <div className="first-section-spacing-top pt-12 md:pt-16 xl:pt-32 xxxl:pt-48 relative overflow-hidden flex flex-wrap">
          <div>
            <h1 className="text-bold text-6xl sm:text-7xl xl:text-8.5xl xxl:text-9xl">
              404
            </h1>
          </div>
        </div>
        <div className="pb-10">
          <div className="text-lg xs:text-center">
            <span>
              Die von dir gesuchte Seite konnte nicht gefunden werden.
            </span>
          </div>
          <div className="text-lg">
            <span>
              Besuche unsere&nbsp;
              <a href="/" style={{ color: "#94C337" }}>
                <span>Startseite</span>
              </a>
              . Dort findest du bestimmt was du suchst!
            </span>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
