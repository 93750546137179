import React from "react"

type CheckboxProps = {
  children: React.ReactNode
}

const Checkbox = ({ children }: CheckboxProps): JSX.Element => {
  return (
    <div className="bg-gray-white border rounded-sm  border-secondary checkbox-bg flex flex-shrink-0 justify-center items-center relative">
      {children}
      <div className="check-icon hidden bg-primary text-white rounded-sm">
        <svg
          className="icon icon-tabler icon-tabler-check"
          xmlns="http://www.w3.org/2000/svg"
          width={18}
          height={18}
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <path d="M5 12l5 5l10 -10" />
        </svg>
      </div>
    </div>
  )
}
export default Checkbox
