import React from "react"

import { classNameProps } from "../../types/classNameProps"

const Street2 = ({ className = "moin" }: classNameProps): JSX.Element => {
  return (
    <svg
      className={"street2 " + className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 218.67 1893.77"
    >
      <g id="a" />
      <g id="b">
        <g id="c">
          <rect
            className="animated-stripes"
            x="108.67"
            y="1754.05"
            width="13.68"
            height="109.51"
            transform="translate(-14.81 1.01) rotate(-.47)"
            fill="none"
            stroke="#554b6d"
            strokeMiterlimit="10"
            strokeWidth="3"
          />
          <rect
            className="animated-stripes"
            x="106.88"
            y="1535.01"
            width="13.68"
            height="109.51"
            transform="translate(-13.02 .98) rotate(-.47)"
            fill="none"
            stroke="#554b6d"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
          <rect
            className="animated-stripes"
            x="105.08"
            y="1315.99"
            width="13.68"
            height="109.51"
            transform="translate(-11.28 .97) rotate(-.47)"
            fill="none"
            stroke="#554b6d"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
          <rect
            className="animated-stripes"
            x="103.28"
            y="1096.96"
            width="13.68"
            height="109.52"
            transform="translate(-9.47 .95) rotate(-.47)"
            fill="none"
            stroke="#554b6d"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
          <rect
            className="animated-stripes"
            x="101.48"
            y="877.9"
            width="13.68"
            height="109.53"
            transform="translate(-7.63 .92) rotate(-.47)"
            fill="none"
            stroke="#554b6d"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
          <rect
            className="animated-stripes"
            x="99.69"
            y="658.88"
            width="13.68"
            height="109.53"
            transform="translate(-5.84 .9) rotate(-.47)"
            fill="none"
            stroke="#554b6d"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
          <polyline
            className="animated-stripes"
            points="111.12 439.79 112.02 549.31 98.34 549.43 97.44 439.9 111.12 439.79"
            fill="none"
            stroke="#554b6d"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
          <rect
            className="animated-stripes"
            x="96.09"
            y="220.81"
            width="13.68"
            height="109.53"
            transform="translate(-2.25 .85) rotate(-.47)"
            fill="none"
            stroke="#554b6d"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
          <rect
            className="animated-stripes"
            x="94.3"
            y="1.79"
            width="13.68"
            height="109.53"
            transform="translate(-.46 .83) rotate(-.47)"
            fill="none"
            stroke="#554b6d"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
          <line
            className="animated-form"
            x1="1"
            y1="2.76"
            x2="8.87"
            y2="1893.77"
            fill="none"
            stroke="#554b6d"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
          <line
            className="animated-form"
            x1="202.16"
            y1="0"
            x2="217.67"
            y2="1890.97"
            fill="none"
            stroke="#554b6d"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
        </g>
      </g>
    </svg>
  )
}

export default Street2
