import { useStaticQuery, graphql } from "gatsby"

const useSiteContactData = (): any => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            STRASSE1
            STRASSE2
            PLZ1
            PLZ2
            ORT1
            ORT2
            OPENING1A
            OPENING1B
            OPENING2A
            OPENING2B
            telefon
            email
            whatsapp
          }
        }
      }
    `
  )
  return site.siteMetadata
}

export default useSiteContactData
