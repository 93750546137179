import React, { useState } from "react"

import RegisterButton from "../shared/registerButton"
import { StaticImage } from "gatsby-plugin-image"
import "./voranmeldungForm.css"
import { Link } from "gatsby"

import { AppContext } from "../layout"

type VoranmeldungFormProps = {
  active: boolean
}

const VoranmeldungForm = ({ active }: VoranmeldungFormProps): JSX.Element => {
  // Boolean for Closing Button if Message was Success
  const { isOpen, toggleMenu } = React.useContext(AppContext)

  // Datenschutz Box
  const [isChecked, setIsChecked] = useState<boolean>(false)

  // ErrorMessage State
  const [errorMessage, setErrorMessage] = useState<string>("")

  // Check if Form was succesfully sent
  const [isSending, setIsSending] = useState<boolean>(false)

  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  // Inputfelder State
  const [formValues, setFormValues] = useState({
    vorname: "",
    nachname: "",
    geburtsort: "",
    geburtstag: "",
    strasse: "",
    ort: "",
    telefon: "",
    email: "",
    website: "",
  })

  // State vorhandene Führerscheinklassen
  const [checkedState, setCheckedState] = useState({
    a: false,
    a1: false,
    a2: false,
    b: false,
    be: false,
    b197: false,
    l: false,
    mofa: false,
  })

  // State vorhandene Führerscheinklassen
  const [stateGewuenschte, setStateGewuenschte] = useState({
    a: false,
    a1: false,
    a2: false,
    b: false,
    be: false,
    b197: false,
    l: false,
    mofa: false,
  })

  // Handle Change on Input VORHANDENE Klassen
  const handleCheckbox = (checked: boolean, name: string): void => {
    setCheckedState({ ...checkedState, [name]: checked })
  }

  // Handle Change on Input GEWUENSCHTE Klassen
  const handleCheckboxGewuenschte = (checked: boolean, name: string): void => {
    setStateGewuenschte({ ...stateGewuenschte, [name]: checked })
  }

  // Textarea State
  const [textareaValue, setTextareaValue] = useState<string>("")

  // Input Felder Change Listener
  const changeHandler = (e: React.FormEvent<HTMLInputElement>): void => {
    setFormValues({
      ...formValues,
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  // textArea Feld Change Listener
  const changeTextarea = (e: any): void => {
    setTextareaValue(e.currentTarget.value)
  }

  // Validierung des Datenschutz Inputs
  const checkInput = (event: any): void => {
    setIsChecked((current) => !current)
  }

  // Wurde die Checkbox Datenschutz geklick?
  const checkForm = (event: any): void => {
    event.preventDefault()

    console.log("Checkform...")
    if (isChecked) {
      // Sende Nachricht an Server
      submit(event)
    } else setErrorMessage("Du musst den Datenschutzerklärungen zustimmen!")
  }

  // Sende Nachricht an Backend
  const submit = (event: any): void => {
    event.preventDefault()

    // Array zum Speichern der gecheckten Boxen Vorhanden Klassen
    const checkedVorhandene: string[] = []

    Object.entries(checkedState).forEach(([key, value]) => {
      if (value) {
        checkedVorhandene.push(key)
      }
    })

    // Array zum Speichern der gecheckten Boxen gewuenschte Klassen
    const checkedGewuenschte: string[] = []

    Object.entries(stateGewuenschte).forEach(([key, value]) => {
      if (value) {
        checkedGewuenschte.push(key)
      }
    })

    setIsSending(true)

    fetch(
      "https://cms.achimsfahrschule.net/api/forms/submit/voranmeldung?token=697eba4bf671957e73ff9354a57194",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          form: {
            vorname: formValues.vorname,
            nachname: formValues.nachname,
            email: formValues.email,
            telefon: formValues.telefon,
            strasse: formValues.strasse,
            ort: formValues.ort,
            geburtstag: formValues.geburtstag,
            geburtsort: formValues.geburtsort,
            message: textareaValue,
            vorhandene: checkedVorhandene,
            gewuenschte: checkedGewuenschte,

            // Wenn der Wert von Website Input nicht leer ist, soll dieser mitgeschickt werden( Nachricht wird dann über das Backend an HoneyPot Formular gesendet)
            ...(formValues.website != "" && { website: formValues.website }),
          },
        }),
      }
    )
      .then((entry) => entry.json())
      .then((entry) => {
        setIsSending(false)

        if (entry.error) {
          setErrorMessage(entry.error)
        } else {
          setIsSuccess(true)
          setTextareaValue("")
        }
      })
  }

  return (
    <div
      id="voranmeldung"
      className={`overflow-y-scroll w-full bg-white absolute h-screen top-0 z-10 transition-transform -translate-y-full ${
        active ? "active relative" : ""
      }`}
    >
      <div className="container-fluid pb-48 spacing-top-voranmeldung">
        <h2 className="w-full sm:w-7/12">Voranmeldung</h2>
        {/* <!-- Content --> */}
        <div className="w-full h-fit-content flex flex-col pb-48 lg:flex-row lg:flex-no-wrap lg:space-x-16 xll:space-x-32 xxl:space-x-64">
          {/* <!-- Spalte 1 Text --> */}
          <div className="w-full lg:w-1/3 flex-shrink-0">
            <p>
              Wenn Du bei uns einen Führerschein machen möchtest, dann nutze
              bitte dieses Formular, um uns Deine Daten zu übermitteln. Diese
              nutzen wir, um die nötigen Unterlagen hier bei uns vorzubereiten.
            </p>
            <p>
              <span className="text-bold">
                Mit diesem Formular gehst du keinen Vertrag bei uns ein
              </span>
              – dafür benötigen wir dann Deine Unterschrift hier vor Ort. Deine
              übermittelten Daten helfen uns dabei die nötigen Unterlagen
              vorzubereiten und einen Ersttermin zu vereinbaren.
            </p>
            <p>
              <span className="text-primary">* </span>
              -gekennzeichnete sind Pflichtfelder.
            </p>
          </div>
          {/* <!-- Spalte 2 Formular --> */}

          {/* form container */}
          <div className="w-full lg:w-2/3 relative">
            {/* Sending Animation */}
            <div
              className={`absolute w-full h-full  justify-center items-center z-10 ${
                isSending == true ? "flex" : "hidden"
              }`}
            >
              <div className="lds-ring border-primary">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            {/* Success Message */}
            <div
              className={`relative w-full h-full  md:items-center pt-24 z-10 flex-col ${
                isSuccess == true ? "flex" : "hidden"
              }`}
            >
              <p className=" text-bold text-lg text-primary">
                Deine Nachricht wurde erfolgreich versendet!
              </p>
              <button
                className="bg-secondary text-white text-sm xll:text-lg px-6 py-3 text-bold rounded-full"
                onClick={() => {
                  if (isOpen) {
                    toggleMenu(false)
                  } else {
                    toggleMenu(true)
                  }
                }}
              >
                Schließen
              </button>
            </div>
            <form
              onSubmit={checkForm}
              id="formular"
              className={`${
                isSuccess == true ? "hidden" : ""
              } w-full flex flex-col flex-wrap lg:flex-row  relative ${
                isSending == true ? "opacity-20" : ""
              }`}
            >
              {/* Inputs */}
              <div className="flex flex-col md:flex-row md:flex-wrap md:space-y-4 mb-12">
                {/* Reihe Vorname / Nachname */}
                <div className="w-full md:flex md:flex-nowrap md:space-x-6">
                  {/* <!-- Vorname --> */}
                  <div className="w-full ">
                    <label className="" htmlFor="vorname">
                      Vorname<span className="text-primary"> *</span>
                    </label>
                    <input
                      id="vorname"
                      className="border border-gray-500 w-full"
                      type="text"
                      name="vorname"
                      placeholder="Vorname"
                      onChange={changeHandler}
                      value={formValues.vorname}
                    />
                  </div>
                  {/* <!-- Nachnahme --> */}
                  <div className="w-full">
                    <label className="block" htmlFor="nachname">
                      Nachname<span className="text-primary"> *</span>
                    </label>
                    <input
                      id="nachname"
                      className="border border-gray-500 w-full"
                      type="text"
                      name="nachname"
                      placeholder="Nachname"
                      onChange={changeHandler}
                      value={formValues.nachname}
                    />
                  </div>
                </div>
                {/* Reihe Telefon / E-Mail*/}
                <div className="w-full md:flex md:flex-nowrap md:space-x-6">
                  {/* <!-- EMail --> */}
                  <div className="w-full md:w-1/2">
                    <label className="" htmlFor="email">
                      E-Mail<span className="text-primary"> *</span>
                    </label>
                    <input
                      id="email"
                      className="border border-gray-500 w-full"
                      type="text"
                      name="email"
                      placeholder="E-Mail"
                      onChange={changeHandler}
                      value={formValues.email}
                    />
                  </div>
                  {/* <!-- Telefon (optional) --> */}
                  <div className="w-full md:w-1/2">
                    <label className="block" htmlFor="telefon">
                      Telefon<span className="text-primary"> *</span>
                    </label>
                    <input
                      id="telefon"
                      className="border border-gray-500 w-full"
                      type="text"
                      name="telefon"
                      placeholder="Telefon"
                      onChange={changeHandler}
                      value={formValues.telefon}
                    />
                  </div>
                </div>
                {/* ReiheOrt und Straße*/}
                <div className="w-full md:flex md:flex-nowrap md:space-x-6">
                  {/* <!-- Straße  --> */}
                  <div className="w-full md:w-1/2">
                    <label className="block" htmlFor="strasse">
                      Straße, Hausnummer<span className="text-primary"> *</span>
                    </label>
                    <input
                      id="strasse"
                      className="border border-gray-500 w-full"
                      type="text"
                      name="strasse"
                      placeholder="Straße, Hausnummer"
                      onChange={changeHandler}
                      value={formValues.strasse}
                    />
                  </div>
                  {/* <!-- PLZ ORt --> */}
                  <div className="w-full md:w-1/2">
                    <label className="" htmlFor="ort">
                      PLZ / Ort<span className="text-primary"> *</span>
                    </label>
                    <input
                      id="ort"
                      className="border border-gray-500 w-full"
                      type="text"
                      name="ort"
                      placeholder="PLZ / Ort"
                      onChange={changeHandler}
                      value={formValues.ort}
                    />
                  </div>
                </div>
                <div className="w-full md:flex md:flex-nowrap md:space-x-6">
                  {/* <!-- Geburtstag --> */}
                  <div className="w-full md:w-1/2">
                    <label className="" htmlFor="geburtstag">
                      Geburtsdatum<span className="text-primary"> *</span>
                    </label>
                    <input
                      id="geburtstag"
                      className="border border-gray-500 w-full"
                      type="text"
                      name="geburtstag"
                      placeholder="Geburtsdatum"
                      onChange={changeHandler}
                      value={formValues.geburtstag}
                    />
                  </div>
                  {/* <!-- Geburtsort  --> */}
                  <div className="w-full md:w-1/2">
                    <label className="block" htmlFor="geburtsort">
                      Geburtsort<span className="text-primary"> *</span>
                    </label>
                    <input
                      id="geburtsort"
                      className="border border-gray-500 w-full"
                      type="text"
                      name="geburtsort"
                      placeholder="Geburtsort"
                      onChange={changeHandler}
                      value={formValues.geburtsort}
                    />
                  </div>
                  {/* <!-- Website / Honeypot --> */}
                  <div className="w-full hidden">
                    <label className="" htmlFor="website">
                      Website
                    </label>
                    <input
                      id="website"
                      className="border border-gray-500 w-full"
                      type="text"
                      name="website"
                      placeholder="Website"
                      onChange={changeHandler}
                      value={formValues.website}
                    />
                  </div>
                </div>
              </div>
              {/* Checkboxen */}
              <div className="w-full mb-12 md:flex md:flex-nowrap md:space-x-12">
                {/* <!-- Auswahl 1 Vorhandene Führerscheinlassen--> */}
                <div className="md:w-1/2">
                  <h2 className="text-base text-bold mb-4">
                    Vorhandene Führerscheinklassen
                  </h2>
                  {/* <!-- Raster --> */}
                  <div className="grid grid-cols-3 grid-rows-3 h-fit-content checkboxes gap-y-6">
                    <div className="row-start-1 col-span-1 row-span-1">
                      <input
                        name="a"
                        type="checkbox"
                        onChange={() => handleCheckbox(!checkedState["a"], "a")}
                        checked={checkedState["a"]}
                      />
                      <span>A</span>
                    </div>
                    <div className="row-start-1 col-start-2 col-span-1 row-span-1">
                      <input
                        name="a1"
                        type="checkbox"
                        onChange={() =>
                          handleCheckbox(!checkedState["a1"], "a1")
                        }
                        checked={checkedState["a1"]}
                      />
                      <span>A1</span>
                    </div>
                    <div className="row-start-1 col-start-3 col-span-1 row-span-1">
                      <input
                        name="a2"
                        type="checkbox"
                        onChange={() =>
                          handleCheckbox(!checkedState["a2"], "a2")
                        }
                        checked={checkedState["a2"]}
                      />
                      <span>A2</span>
                    </div>
                    <div className="row-start-2 col-start-1 col-span-1 row-span-1">
                      <input
                        name="b"
                        type="checkbox"
                        onChange={() => handleCheckbox(!checkedState["b"], "b")}
                        checked={checkedState["b"]}
                      />
                      <span>B</span>
                    </div>
                    <div className="row-start-2 col-start-2 col-span-1 row-span-1">
                      <input
                        name="be"
                        type="checkbox"
                        onChange={() =>
                          handleCheckbox(!checkedState["be"], "be")
                        }
                        checked={checkedState["be"]}
                      />
                      <span>BE</span>
                    </div>

                    <div className="row-start-2 col-start-3 col-span-1 row-span-1">
                      <input
                        name="be197"
                        type="checkbox"
                        onChange={() =>
                          handleCheckbox(!checkedState["b197"], "b197")
                        }
                        checked={checkedState["b197"]}
                      />
                      <span>B 197</span>
                    </div>
                    <div className="col-span-1 row-span-1 row-start-3 col-start-1">
                      <input
                        name="l"
                        type="checkbox"
                        onChange={() => handleCheckbox(!checkedState["l"], "l")}
                        checked={checkedState["l"]}
                      />
                      <span>L</span>
                    </div>
                    <div className="col-span-1 col-start-2 row-start-3 col-start-2">
                      <input
                        name="mofa"
                        type="checkbox"
                        onChange={() =>
                          handleCheckbox(!checkedState["mofa"], "mofa")
                        }
                        checked={checkedState["mofa"]}
                      />
                      <span>Mofa</span>
                    </div>
                  </div>
                </div>
                {/* <!-- Auswahl 2 Gewünschte Führerscheinlassen--> */}
                <div className="w-full mt-14 md:w-1/2 md:mt-0">
                  <h2 className="text-base text-bold mb-4">
                    Gewünschte Führerscheinklassen
                  </h2>
                  {/* <!-- Raster 2 --> */}
                  <div className="grid grid-cols-3 grid-rows-3 h-fit-content checkboxes gap-y-6">
                    <div className="row-start-1 col-span-1 row-span-1">
                      <input
                        name="a"
                        type="checkbox"
                        onChange={() =>
                          handleCheckboxGewuenschte(!stateGewuenschte["a"], "a")
                        }
                        checked={stateGewuenschte["a"]}
                      />
                      <span>A</span>
                    </div>
                    <div className="row-start-1 col-start-2 col-span-1 row-span-1">
                      <input
                        name="a1"
                        type="checkbox"
                        onChange={() =>
                          handleCheckboxGewuenschte(
                            !stateGewuenschte["a1"],
                            "a1"
                          )
                        }
                        checked={stateGewuenschte["a1"]}
                      />
                      <span>A1</span>
                    </div>
                    <div className="row-start-1 col-start-3 col-span-1 row-span-1">
                      <input
                        name="a2"
                        type="checkbox"
                        onChange={() =>
                          handleCheckboxGewuenschte(
                            !stateGewuenschte["a2"],
                            "a2"
                          )
                        }
                        checked={stateGewuenschte["a2"]}
                      />
                      <span>A2</span>
                    </div>
                    <div className="row-start-2 col-start-1 col-span-1 row-span-1">
                      <input
                        name="b"
                        type="checkbox"
                        onChange={() =>
                          handleCheckboxGewuenschte(!stateGewuenschte["b"], "b")
                        }
                        checked={stateGewuenschte["b"]}
                      />
                      <span>B</span>
                    </div>
                    <div className="row-start-2 col-start-2 col-span-1 row-span-1">
                      <input
                        name="be"
                        type="checkbox"
                        onChange={() =>
                          handleCheckboxGewuenschte(
                            !stateGewuenschte["be"],
                            "be"
                          )
                        }
                        checked={stateGewuenschte["be"]}
                      />
                      <span>BE</span>
                    </div>

                    <div className="row-start-2 col-start-3 col-span-1 row-span-1">
                      <input
                        name="be197"
                        type="checkbox"
                        onChange={() =>
                          handleCheckboxGewuenschte(
                            !stateGewuenschte["b197"],
                            "b197"
                          )
                        }
                        checked={stateGewuenschte["b197"]}
                      />
                      <span>B 197</span>
                    </div>
                    <div className="col-span-1 row-span-1 row-start-3 col-start-1">
                      <input
                        name="l"
                        type="checkbox"
                        onChange={() =>
                          handleCheckboxGewuenschte(!stateGewuenschte["l"], "l")
                        }
                        checked={stateGewuenschte["l"]}
                      />
                      <span>L</span>
                    </div>
                    <div className="col-span-1 col-start-2 row-start-3 col-start-2">
                      <input
                        name="mofa"
                        type="checkbox"
                        onChange={() =>
                          handleCheckboxGewuenschte(
                            !stateGewuenschte["mofa"],
                            "mofa"
                          )
                        }
                        checked={stateGewuenschte["mofa"]}
                      />
                      <span>Mofa</span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Nachrichten Feld --> */}
              <div className="w-full mb-12">
                <label className="" htmlFor="message">
                  Was Du uns noch mitteilen möchtest
                </label>
                <textarea
                  id="message"
                  className=" w-full"
                  name="message"
                  placeholder="Deine Nachricht"
                  onChange={changeTextarea}
                  value={textareaValue}
                ></textarea>
              </div>
              {/* <!-- Datenschutzerklärung --> */}
              <div className="message-container w-full mb-12">
                <label className="" htmlFor="datenschutz">
                  Datenschutzerklärung<span className="text-primary"> *</span>
                </label>
                <div className="w-full flex items-center">
                  <input
                    name="datenschutz"
                    type="checkbox"
                    onChange={checkInput}
                  />
                  <span>
                    Hiermit bestätigst Du, dass Du mit unseren{" "}
                    <a
                      href="/datenschutz"
                      target="_blank"
                      className="text-bold text-secondary hover:cursor-pointer"
                    >
                      Datenschutzerklärungen
                    </a>{" "}
                    einverstanden bist.
                  </span>
                </div>
              </div>
              {/* Error Messages */}
              <div
                className={`msg-container  w-full text-bold text-lg text-red-500 ${
                  errorMessage != "" ? "block mb-12" : "hidden"
                }`}
              >
                {errorMessage}
              </div>
              {/* <!-- Button Absenden --> */}
              <button
                type="submit"
                className="bg-secondary text-white text-sm xll:text-lg px-6 py-3 text-bold rounded-full w-fit"
              >
                Absenden
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VoranmeldungForm
