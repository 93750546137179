import React from "react"
import { classNameProps } from "../../types/classNameProps"
import { StaticImage } from "gatsby-plugin-image"
import { Location } from "../../types/enum"

type LocationMapProps = {
  className?: string
  link?: string
  imageType?: Location
  title?: string
  address?: {
    street?: string
    city?: string
    zipcode?: string
  }
  opening?: {
    option1?: string
    option2?: string
  }
}

const LocationMap = ({
  className,
  link = "/",
  imageType,
  title = "Tarup",
  address = { street: "Bäckerweg 28", city: "Flensburg", zipcode: "24943" },
  opening = {
    option1: "Mo - Mi 19:30 - 20:00 Uhr",
    option2: "Mi - Fr 19:00 - 20:30 Uhr",
  },
}: LocationMapProps): JSX.Element => {
  return (
    <>
      {/* Ueberschrift */}
      <div className="h-11 lg:h-fit overflow-y-visible z-10 relative lg:mb-4 xxl:mb-6">
        <h3 className="text-secondary mb-0 text-2xl  xxl:text-3xl">
          Fahrschule <br className="lg:hidden" />
          in {title}
        </h3>
      </div>
      {/* Karte */}
      <div className="img-wrapper -z-10 pl-8 lg:pl-0 lg:flex lg:flex-nowrap lg:space-x-8">
        <div className="w-full lg:w-1/4 xll:w-5/12 max-h-64 xl:max-h-32 overflow-hidden">
          <a href={link} className="cursor-pointer">
            {imageType === Location.TARUP ? (
              <StaticImage
                className="object-cover h-full w-full"
                src="../../images/anfahrt-tarup.jpg"
                alt={`Foto Standort Achims Fahrschule`}
              ></StaticImage>
            ) : (
              <StaticImage
                className="object-cover h-full w-full"
                src="../../images/anfahrt-satrup.jpg"
                alt={`Foto Standort Achims Fahrschule`}
              ></StaticImage>
            )}
          </a>
        </div>
        {/* Reihe Adresse */}
        <div className="flex flex-nowrap space-x-8 sm:space-x-4 xl:space-x-8 mt-3  sm:mt-4 sm:mt-5 lg:w-3/4 xll:w-7/12">
          {/* Spalte 1 */}
          <div className="md:flex flex-col justify-center">
            <h4 className="text-secondary text-base xxl:text-xl">Adresse</h4>
            <ul>
              <li>{address.street}</li>
              <li>
                {address.zipcode} {address.city}
              </li>
            </ul>
          </div>
          {/* Spalte 2 */}
          <div className="md:flex flex-col justify-center">
            <h4 className="text-secondary text-base xxl:text-xl">
              Öffnungszeiten
            </h4>
            <ul>
              <li>{opening.option1}</li>
              <li>{opening.option2}</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default LocationMap
