import React from "react"
import { Link } from "gatsby"

type LicenceLinkProps = {
  to: string
  children: React.ReactNode | undefined
  active?: boolean
}

const LicenceLink = ({
  children,
  to,
  active = false,
}: LicenceLinkProps): JSX.Element => {
  return (
    <Link to={to}>
      <button
        className={`${
          active ? "bg-primary" : "bg-secondary"
        }  text-white text-sm xll:text-lg px-6 py-3 text-bold rounded-full mb-0 transition-colors hover:bg-primary`}
      >
        {children}
      </button>
    </Link>
  )
}

export default LicenceLink
