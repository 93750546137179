import React from "react"
import { classNameProps } from "../../types/classNameProps"

const VoranmeldungIcon = ({ className }: classNameProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44.002"
      height="53.434"
      viewBox="0 0 44.002 53.434"
      className={className}
    >
      <g id="Gruppe_2366" data-name="Gruppe 2366" transform="translate(0)">
        <g
          id="Gruppe_2195"
          data-name="Gruppe 2195"
          transform="translate(12.576 24.466)"
        >
          <path
            id="Pfad_7696"
            data-name="Pfad 7696"
            d="M26.28,52.977a2.076,2.076,0,0,1-1.481-.614l-4.887-4.887a2.1,2.1,0,0,1,0-2.963l1.481-1.481a2.15,2.15,0,0,1,2.963,0l1.925,1.925L33.1,38.14a2.15,2.15,0,0,1,2.963,0l1.483,1.482a2.1,2.1,0,0,1,0,2.963l-9.78,9.779A2.076,2.076,0,0,1,26.28,52.977Zm0-2.1-.742.742Zm-3.407-6.37L21.391,45.99l4.887,4.887L36.06,41.1l-1.481-1.481-8.3,8.3Z"
            transform="translate(-19.3 -37.548)"
          />
        </g>
        <path
          id="Pfad_7697"
          data-name="Pfad 7697"
          d="M29.938,68.045,29.23,66.1l.984-.378a19.769,19.769,0,0,0,7.363-4.641l3.732-3.732a2.1,2.1,0,0,1,2.644-.261L47.9,59.715a.8.8,0,0,0,.169.092l.065-.023.829,1.917a1.875,1.875,0,0,1-.472.174Zm12.855-9.215-3.732,3.732c-.122.124-.247.245-.373.365l6.842-2.273Z"
          transform="translate(-10.183 -19.767)"
        />
        <path
          id="Pfad_7698"
          data-name="Pfad 7698"
          d="M38.764,14.471H35.512a5.28,5.28,0,0,1,.106,1.047v1.048h3.145a3.145,3.145,0,0,1,3.143,3.143V53.235a3.147,3.147,0,0,1-3.143,3.143H5.238a3.147,3.147,0,0,1-3.143-3.143V19.71a3.145,3.145,0,0,1,3.143-3.143H9.157V15.518a5.28,5.28,0,0,1,.106-1.047H5.238A5.243,5.243,0,0,0,0,19.709V53.235a5.244,5.244,0,0,0,5.238,5.241H38.764A5.244,5.244,0,0,0,44,53.235V19.709A5.243,5.243,0,0,0,38.764,14.471Z"
          transform="translate(0 -5.042)"
        />
        <path
          id="Pfad_7699"
          data-name="Pfad 7699"
          d="M42.053,47.23V23a2.1,2.1,0,0,0-2.1-2.1h-1.7V23h1.695V47.231L36.012,44.6a2.1,2.1,0,0,0-2.644.261L29.636,48.6a19.774,19.774,0,0,1-14.074,5.831H8.524V23h2.5V20.9h-2.5a2.1,2.1,0,0,0-2.1,2.1v31.43a2.1,2.1,0,0,0,2.092,2.1h7.037A21.852,21.852,0,0,0,31.12,50.077l3.732-3.732,3.943,2.627a1.981,1.981,0,0,0,3.258-1.743Z"
          transform="translate(-2.241 -7.282)"
        />
        <g
          id="Gruppe_2196"
          data-name="Gruppe 2196"
          transform="translate(8.783)"
        >
          <path
            id="Pfad_7700"
            data-name="Pfad 7700"
            d="M35.479,5.238h-.612a8.38,8.38,0,0,0-15.538,0h-.612a5.244,5.244,0,0,0-5.238,5.238v5.239a4.2,4.2,0,0,0,4.19,4.19H36.525a4.2,4.2,0,0,0,4.19-4.19V10.476A5.244,5.244,0,0,0,35.479,5.238ZM38.23,15.55a2.1,2.1,0,0,1-2.1,2.1H17.276a2.1,2.1,0,0,1-2.1-2.1V10.312a3.145,3.145,0,0,1,3.143-3.143h2.184a6.286,6.286,0,0,1,12.4,0h2.183a3.145,3.145,0,0,1,3.143,3.143Z"
            transform="translate(-13.479)"
          />
          <path
            id="Pfad_7701"
            data-name="Pfad 7701"
            d="M37.116,11.416h-4.19V10.368a4.19,4.19,0,0,0-8.381,0v1.048H20.354a1.05,1.05,0,0,0-1.048,1.048V17.7H38.164V12.464A1.049,1.049,0,0,0,37.116,11.416Zm-6.294.164h-4.19v-2.1h4.19Z"
            transform="translate(-15.509 -2.152)"
          />
        </g>
      </g>
    </svg>
  )
}

export default VoranmeldungIcon
