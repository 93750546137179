import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

const Location = (): JSX.Element => {
  const data =
    useStaticQuery<GatsbyTypes.getLocationDataIntoLocationComponentQuery>(graphql`
      query getLocationDataIntoLocationComponent {
        site {
          siteMetadata {
            STRASSE1
            STRASSE2
            PLZ1
            PLZ2
            ORT1
            ORT2
            OPENING1A
            OPENING1B
            OPENING2A
            OPENING2B
          }
        }
      }
    `)

  if (!data.site?.siteMetadata)
    throw new Error("SiteMetaData.title possibly undefined")

  const locationData = data.site?.siteMetadata

  console.log(locationData)

  // Adressen
  const locations = {
    location1: {
      street: locationData.STRASSE1,
      city: locationData.ORT1,
      zipcode: locationData.PLZ1,
    },
    location2: {
      street: locationData.STRASSE2,
      city: locationData.ORT2,
      zipcode: locationData.PLZ2,
    },
  }
  // Öffnungszeiten
  const opening = {
    opening1: {
      option1: locationData.OPENING1A,
      option2: locationData.OPENING1B,
    },
    opening2: {
      option1: locationData.OPENING2A,
      option2: locationData.OPENING2B,
    },
  }

  return (
    <div className=" section-spacing-bottom flex flex-col space-y-8 sm:flex-row sm:flex-nowrap sm:space-x-8 sm:space-y-0 lg:space-x-16">
      {/* Spalte 1 */}
      <div className="w-full sm:w-1/2">
        {/* Ueberschrift */}
        <div className="h-11 sm:h-14 md:h-16 lg:h-20 xl:h-28 xll:h-28 xxl:h-32 overflow-y-visible z-10 relative">
          <h3 className="text-secondary mb-0">
            Fahrschule
            <br />
            in {locations.location1.city}
          </h3>
        </div>
        {/* Bild */}
        <div className="img-wrapper -z-10 pl-8 xl:pl-16 ">
          <StaticImage
            src="../../images/AchimsFahrschule-Standort-Tarup-dsktp.jpg"
            alt="Foto Standort Achims Fahrschule Tarup"
          ></StaticImage>
          {/* Reihe Adresse */}
          <div className="flex flex-wrap flex-col xs:flex-row md:flex-col lg:flex-row mt-3  sm:mt-4 stand-info">
            {/* Spalte 1 */}
            <div className="mr-8 lg:mr-10 xll:mr-16 mb-4 ">
              <h4>Adresse</h4>
              <ul>
                <li>{locations.location1.street}</li>
                <li>{`${locations.location1.zipcode} ${locations.location1.city}`}</li>
              </ul>
            </div>
            {/* Spalte 2 */}
            <div>
              <h4>Öffnungszeiten</h4>
              <ul>
                <li>{opening.opening1.option1}</li>
                <li>{opening.opening1.option2}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Spalte 2 */}
      <div className="w-full sm:w-1/2">
        {/* Ueberschrift */}
        <div className="h-11 sm:h-14 md:h-16 lg:h-20 xl:h-28  xll:h-28 xxl:h-32  overflow-y-visible z-10 relative">
          <h3 className="text-secondary mb-0">
            Fahrschule
            <br />
            in {locations.location2.city}
          </h3>
        </div>
        {/* Bild */}
        <div className="img-wrapper -z-10 pl-8 xl:pl-16">
          <StaticImage
            src="../../images/AchimsFahrschule-Standort-Satrup-dsktp.jpg"
            alt="Foto Standort Achims Fahrschule Satrup"
          ></StaticImage>
          {/* Reihe Adresse */}
          <div className="flex flex-wrap flex-col xs:flex-row md:flex-col lg:flex-row mt-3  sm:mt-4 stand-info">
            {/* Spalte 1 */}
            <div className="mr-8 lg:mr-10 xll:mr-16 mb-4 ">
              <h4>Adresse</h4>
              <ul>
                <li>{locations.location2.street}</li>
                <li>{`${locations.location2.zipcode} ${locations.location2.city}`}</li>
              </ul>
            </div>
            {/* Spalte 2 */}
            <div>
              <h4>Öffnungszeiten</h4>
              <ul>
                <li>{opening.opening2.option1}</li>
                <li>{opening.opening2.option2}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Location
