exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-dein-weg-zum-fuehrerschein-tsx": () => import("./../../../src/pages/dein-weg-zum-fuehrerschein.tsx" /* webpackChunkName: "component---src-pages-dein-weg-zum-fuehrerschein-tsx" */),
  "component---src-pages-fuehrerscheinklassen-tsx": () => import("./../../../src/pages/fuehrerscheinklassen.tsx" /* webpackChunkName: "component---src-pages-fuehrerscheinklassen-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-samplepage-tsx": () => import("./../../../src/pages/samplepage.tsx" /* webpackChunkName: "component---src-pages-samplepage-tsx" */),
  "component---src-pages-samplequery-tsx": () => import("./../../../src/pages/samplequery.tsx" /* webpackChunkName: "component---src-pages-samplequery-tsx" */),
  "component---src-pages-theorieunterricht-tsx": () => import("./../../../src/pages/theorieunterricht.tsx" /* webpackChunkName: "component---src-pages-theorieunterricht-tsx" */),
  "component---src-templates-licence-detail-template-tsx": () => import("./../../../src/templates/licence-detail-template.tsx" /* webpackChunkName: "component---src-templates-licence-detail-template-tsx" */)
}

