import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import {
  CheckListIcon,
  AutoIcon,
  HutIcon,
  BfIcon,
  TraktorIcon,
  MofaIcon,
  MotoIcon,
} from "../shared/icons"

import YAMLData from "../../content/licence/allLicences.yaml"

type LicenceInfoBoxProps = {
  title: string
  location: string
  children?: React.ReactNode | undefined
  icon?: string
}

const LicenceInfoBox: React.FC<LicenceInfoBoxProps> = ({
  children,
  title,
  location,
  icon,
}: LicenceInfoBoxProps): JSX.Element => {
  const yamlData = YAMLData as Array<Record<string, unknown>>
  const data = yamlData.filter((data) => data["slug"] === location)[0][
    "content"
  ] as Array<Record<string, unknown>>

  const boxes = data.map((box) => {
    if (box["type"] === "list") {
      const description = box["description"] as Array<Record<string, unknown>>
      return (
        <div className="flex mb-16 md:mb-0 license-info-box">
          <div className="w-8 relative mr-8 xl:w-16">
            {/* Checklist */}
            {box["icon"] === "checklist" ? (
              <CheckListIcon className="inline-block h-12 w-8   xl:mb-12 -translate-y-2 xl:w-14 xl:scale-100 xl:translate-y-0"></CheckListIcon>
            ) : (
              ""
            )}
            {/* HutIcon */}
            {box["icon"] === "pruefung" ? (
              <HutIcon className="inline-block h-12 w-8 mb-8 mr-14 xl:mb-12 -translate-y-2  scale-150 xl:w-14 xl:scale-100 xl:translate-y-0"></HutIcon>
            ) : (
              ""
            )}
            {/* Moto */}
            {box["icon"] === "moto" ? (
              <MotoIcon className="inline-block h-12 w-8 mb-8 mr-14 xl:mb-12 -translate-y-2 scale-125 xl:w-12 xl:scale-100 xl:translate-y-0"></MotoIcon>
            ) : (
              ""
            )}
            {/* Mofa */}
            {box["icon"] === "mofa" ? (
              <MofaIcon className="inline-block h-12 w-8 mb-8 mr-14 xl:mb-12 -translate-y-2 scale-125 xl:w-12 xl:scale-100 xl:translate-y-0"></MofaIcon>
            ) : (
              ""
            )}
            {/* traktor */}
            {box["icon"] === "traktor" ? (
              <TraktorIcon className="inline-block h-12 w-8 mb-8 mr-14 xl:mb-12 -translate-y-3 scale-150 xl:w-12 xl:scale-150 xl:translate-y-0"></TraktorIcon>
            ) : (
              ""
            )}
            {/* Auto */}
            {box["icon"] === "auto" ? (
              <AutoIcon className="inline-block h-12 w-8 mb-8 mr-14 xl:mb-12 -translate-y-2 scale-125 xl:w-12 xl:scale-100 xl:translate-y-0"></AutoIcon>
            ) : (
              ""
            )}
            {/* BF17 */}
            {box["icon"] === "bf17" ? (
              <BfIcon className="inline-block h-12 w-8  mb-8 scale-150 mr-14 -translate-y-2 xl:w-14 xl:translate-y-0"></BfIcon>
            ) : (
              ""
            )}
          </div>
          <div className="w-full ">
            <h3 className="text-xl md:text-2xl xl:text-4xl">
              {String(box["title"])}
            </h3>
            <div className="w-full">
              <ul>
                {description.map((li) => (
                  // eslint-disable-next-line react/jsx-key
                  <li>{String(li["item"])}</li>
                ))}
              </ul>
              {/* // Quellenangabe für das darfst du fahren */}
              {box["title"] === "Das darfst du fahren" ? (
                <div className="flex w-full mt-6 xxxl:mt-24 md:justify-end">
                  <span className="mr-1">Quelle: </span>
                  <a href="https://www.tuvsud.com/de-de/branchen/mobilitaet-und-automotive/fuehrerschein-und-pruefung/fuehrerschein-und-pruefung/fuehrerschein-klassen">
                    {" "}
                    https://www.tuvsud.com/
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )
    }
    return (
      // eslint-disable-next-line react/jsx-key
      <div className="flex  mb-16 md:mb-0">
        <div className="w-8 relative mr-8 xl:w-16  ">
          {/* Hut */}
          {box["icon"] === "pruefung" ? (
            <HutIcon className="inline-block h-12 w-8  mb-8 mr-14 xl:mb-12 -translate-y-2 scale-150"></HutIcon>
          ) : (
            ""
          )}
          {/* Moto */}
          {box["icon"] === "moto" ? (
            <MotoIcon className="inline-block h-12 w-8 mb-8 mr-14 xl:mb-12 -translate-y-2 scale-125 xl:w-12 xl:scale-100 xl:translate-y-0"></MotoIcon>
          ) : (
            ""
          )}
          {/* Mofa */}
          {box["icon"] === "mofa" ? (
            <MofaIcon className="inline-block h-12 w-8 mb-8 mr-14 xl:mb-12 -translate-y-2 scale-125 xl:w-12 xl:scale-100 xl:translate-y-0"></MofaIcon>
          ) : (
            ""
          )}
          {/* traktor */}
          {box["icon"] === "traktor" ? (
            <TraktorIcon className="inline-block h-12 w-8 mb-8 mr-14 xl:mb-12 -translate-y-3 scale-150 xl:w-12 xl:scale-150 xl:translate-y-0"></TraktorIcon>
          ) : (
            ""
          )}
          {/* Auto */}
          {box["icon"] === "auto" ? (
            <AutoIcon className="inline-block h-12 w-8 mb-8 mr-14 xl:mb-12 -translate-y-2 scale-125 xl:w-12 xl:scale-100 xl:translate-y-0"></AutoIcon>
          ) : (
            ""
          )}
          {/* BF17 */}
          {box["icon"] === "bf17" ? (
            <BfIcon className="inline-block h-12 w-8  mb-8 scale-150 mr-14 -translate-y-2 xl:w-14 xl:translate-y-0"></BfIcon>
          ) : (
            ""
          )}
        </div>
        <div className="w-full">
          <h3 className="text-xl md:text-2xl xl:text-4xl">
            {String(box["title"])}
          </h3>
          <div className="w-full ">
            <p className="mb-0">{String(box["description"])}</p>
            {/* // Quellenangabe für das darfst du fahren */}
            {box["title"] === "Das darfst du fahren" ? (
              <div className="flex w-full mt-6 xxxl:mt-24 md:justify-end">
                <span className="mr-1">Quelle: </span>
                <a href="https://www.tuvsud.com/de-de/branchen/mobilitaet-und-automotive/fuehrerschein-und-pruefung/fuehrerschein-und-pruefung/fuehrerschein-klassen">
                  {" "}
                  https://www.tuvsud.com/
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    )
  })

  return (
    <div>
      <div className="md:grid md:grid-row-2 md:grid-cols-2 md:gap-16">
        {boxes}
      </div>
    </div>
  )

  // return (
  //   /* Grid */
  //   <div className="md:grid md:grid-row-2 md:grid-cols-2 md:gap-16">
  //     {/* Position 1/1 */}
  //     <div className="flex">
  //       <div className="w-32">
  //         <StaticImage src="../../images/sample_icon.png" alt="sample_icon" />
  //       </div>
  //       <div className="w-full">
  //         {/* <h2>{title}</h2> */}
  //         <h3>Voraussetzungen</h3>
  //         <div className="w-full">
  //           <ul>
  //             <li>Mindestalter 17 Jahre (BF)</li>
  //             <li>Kein Vorbesitz erfolrderlich</li>
  //             <li>Einschließlich: Klassen L, AM</li>
  //           </ul>
  //           {/* {children} */}
  //         </div>
  //       </div>
  //     </div>
  //     {/* Position 1/2 */}
  //     <div className="flex">
  //       <div className="w-32">
  //         <StaticImage src="../../images/sample_icon.png" alt="sample_icon" />
  //       </div>
  //       <div className="w-full">
  //         {/* <h2>{title}</h2> */}
  //         <h3>Teilnahme an Prüfung</h3>
  //         <div className="w-full">
  //           <ul>
  //             <li>Theorie: 3 Monat vor erreichen des Mindestalters</li>
  //             <li>Praxis: 1 Monat vor erreichen des Mindestalters</li>
  //           </ul>
  //           {/* {children} */}
  //         </div>
  //       </div>
  //     </div>
  //     {/* Position 2/1 */}
  //     <div className="flex">
  //       <div className="w-32">
  //         <StaticImage src="../../images/sample_icon.png" alt="sample_icon" />
  //       </div>
  //       <div className="w-full">
  //         {/* <h2>{title}</h2> */}
  //         <h3>Das darfst du fahren</h3>
  //         <div className="w-full">
  //           <p>
  //             Kraftfahrzeuge mit einer zulässigen Gesamtmasse von nicht mehr als
  //             3,5t, die nur zur Beförderung von nicht mehr als 8 Personen außer
  //             dem Fahrzeugführer ausgelegt und gebaut sind - die Kombination aus
  //             Zugfahrzeug und Anhänger darf 3,5t zGM nicht überschreiten.
  //           </p>
  //           {/* {children} */}
  //         </div>
  //       </div>
  //     </div>
  //     {/* Position 2/2 */}
  //     <div className="flex">
  //       <div className="w-32">
  //         <StaticImage src="../../images/sample_icon.png" alt="sample_icon" />
  //       </div>
  //       <div className="w-full">
  //         {/* <h2>{title}</h2> */}
  //         <h3>Begleitendes Fahren</h3>
  //         <div className="w-full">
  //           <p>
  //             Seit mindestens fünf Jahren ununterbrochen die Fahrerlaubnis der
  //             Klasse B (früher: Klasse 3), vorausgesetzt nicht mehr als einen
  //             Punkt im Fahreignungsregister. Mindestalter 30 Jahre.
  //           </p>
  //           {/* {children} */}
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )
}

export default LicenceInfoBox
