import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import "./licenceTeaser.css"

import { MofaIcon, AutoIcon, MotoIcon, TraktorIcon } from "../shared/icons"

type DrivingLicenceCategoryProps = {
  title?: string
  category?: string
  categoryIcon?: never
  teaserImage?: never
  description?: string
  children?: React.ReactNode | undefined
}

const LicenceTeaserComponent = ({
  title,
  category,
  categoryIcon,
  teaserImage,
  description,
  children,
}: DrivingLicenceCategoryProps): JSX.Element => {
  if (!categoryIcon) throw new Error("categoryIcon missing")
  if (!teaserImage) throw new Error("teaserImage missing")

  return (
    <div className="sm:flex sm:flex-col sm:justify-center sm:relative test-lg test-xl test-xxl">
      <div className="sm:w-full sm:relative">
        <div className="text-primary text-ultrabold text-6xl sm:text-7xl xl:text-8.5xl xxl:text-9xl  inline whitespace-nowrap mb-2">
          {category}
        </div>
        {/* Icons */}
        {/* Moto */}
        {category === "A" || category === "A1" || category === "A2" ? (
          <MotoIcon className="inline-block h-10 mb-8 ml-2 xl:h-16 xl:mb-12"></MotoIcon>
        ) : (
          ""
        )}
        {/* Auto */}
        {category === "B" || category === "BE" || category === "B197" ? (
          <AutoIcon className="inline-block h-10 mb-8 ml-2 xl:h-16 xl:mb-12"></AutoIcon>
        ) : (
          ""
        )}
        {/* Mofa */}
        {category === "Mofa" ? (
          <MofaIcon className="inline-block h-10 mb-8 ml-2 xl:h-16 xl:mb-12"></MofaIcon>
        ) : (
          ""
        )}
        {/* Traktor */}
        {category === "Traktor" ? (
          <TraktorIcon className="inline-block h-10 mb-8 ml-2 xl:h-16 xl:mb-12"></TraktorIcon>
        ) : (
          ""
        )}
      </div>
      <div className="sm:w-7/12 sm:absolute sm:right-0 sm:-z-10  flex flex-col justify-center mb-4 sm:mb-0">
        <div className="aspect-video sm:aspect-[4/3] overflow-hidden relative flex object-center justify-center">
          <GatsbyImage
            image={teaserImage}
            alt={teaserImage}
            className="object-fill"
          />
        </div>
      </div>

      <div className="sm:w-full sm:relative  ">
        {description && <p className="sm:w-1/3 md:mb-8">{description}</p>}
        {children}
      </div>
    </div>
  )
}

export default LicenceTeaserComponent
