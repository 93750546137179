import React from "react"

import { graphql, PageProps, Link } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import LicenceTeaserComponent from "../components/licenceTeaser/licenceTeaser"
import LicencesNavigation from "../components/licencesNavigation/licencesNavigation"
import { formatLinks } from "../helper/format"

export const licenceTeaserQuery = graphql`
  query GetLicenceTeaser {
    allMarkdownRemark(
      filter: { frontmatter: { contentType: { eq: "licenceTeaser" } } }
      sort: { fields: frontmatter___id, order: ASC }
    ) {
      nodes {
        frontmatter {
          category
          contentType
          description
          id
          slug
          title
          icon {
            childImageSharp {
              gatsbyImageData
            }
          }
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

const DrivingLicenceCategories = ({
  data,
}: PageProps<GatsbyTypes.GetLicenceTeaserQuery>): JSX.Element => {
  const { nodes } = data.allMarkdownRemark
  const links = formatLinks([...nodes])

  return (
    <Layout>
      <SEO
        title="Achims Fahrschule | Dein Führerschein in den Klassen A und B"
        description="Bei uns findest du die Führerscheinklasse, die du suchst. Egal ob Motorrad- oder Autoführerschein. Wir bringen dich ans Ziel!"
      />
      <div className="container-fluid first-section-spacing-top pt-12 md:pt-16 xl:pt-32 xxxl:pt-48 ">
        <h1 className="text-2xl xs:text-3xl sm:text-4.5xl md:text-5xl xl:text-7xl xxxl:text-8xl">
          Führerscheinklassen
        </h1>
        <p>
          Bei uns findest du die Führerscheinklasse, die du suchst. Egal ob
          Motorrad- oder Autoführerschein. Wir bringen dich ans Ziel!
        </p>
      </div>
      <div className="bg-purple-50 mb-12 md:mb-16 xl:mb-40 xxxl:mb-48">
        <div className="container-fluid">
          <LicencesNavigation links={links} />
        </div>
      </div>
      <div className="container-fluid mb-12 md:mb-16 xl:mb-40 xxxl:mb-48 ">
        {nodes.map((node, index) => {
          return (
            <div key={`${node.frontmatter?.id}${index}`}>
              <div className="my-24 md:my-12 md:py-12 lg:my-24 lg:py-24   xl:my-28 xl:py-28 xll:my-32 xll:py-32 xxxl:py-48 xxxl:my-48  overflow-hidden">
                <LicenceTeaserComponent
                  title={node.frontmatter?.title}
                  category={node.frontmatter?.category}
                  description={node.frontmatter?.description}
                  categoryIcon={
                    node.frontmatter?.icon?.childImageSharp?.gatsbyImageData
                  }
                  teaserImage={
                    node.frontmatter?.image?.childImageSharp?.gatsbyImageData
                  }
                >
                  {/* Austauschen gegen ButtonComponent */}
                  <Link to={`/fuehrerscheinklassen/${node.frontmatter?.slug}`}>
                    <button className="bg-secondary text-white text-sm xll:text-lg px-6 py-3 text-bold rounded-full">
                      Mehr erfahren
                    </button>
                  </Link>
                </LicenceTeaserComponent>
              </div>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default DrivingLicenceCategories
