import React from "react"
import { classNameProps } from "../../types/classNameProps"

const AutoIcon = ({
  className,
  isWhite = false,
}: classNameProps): JSX.Element => {
  return (
    <svg
      className={className}
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 61 46"
    >
      <path
        d="M48.08,45.67c-1.62,0-2.94-1.32-2.95-2.94v-4.45H16.65v4.45c0,1.62-1.32,2.94-2.94,2.94h-5.53c-1.62,0-2.95-1.32-2.95-2.94v-7.31c-.93-1.24-1.44-2.76-1.44-4.32v-6.16c0-3.25,1.72-6.25,4.5-7.91L15.46,3.79c.7-1.3,2.06-2.11,3.54-2.11h23.78c1.48,0,2.84,.81,3.55,2.12l7.17,13.24c2.78,1.65,4.5,4.66,4.5,7.91v6.16c0,1.56-.51,3.08-1.44,4.32v7.31c0,1.62-1.32,2.94-2.95,2.94h-5.53ZM15.09,36.72h31.61v6.01c0,.76,.62,1.38,1.38,1.38h5.53c.76,0,1.38-.62,1.38-1.38v-7.86l.18-.22c.82-1,1.27-2.26,1.27-3.55v-6.16c0-2.76-1.49-5.31-3.9-6.66l-.2-.11-7.39-13.63c-.43-.8-1.26-1.3-2.17-1.3H19.01c-.91,0-1.74,.5-2.17,1.3l-7.39,13.63-.2,.11c-2.41,1.35-3.9,3.9-3.9,6.66v6.16c0,1.29,.45,2.55,1.26,3.55l.18,.22v7.86c0,.76,.62,1.38,1.39,1.38h5.53c.76,0,1.38-.62,1.38-1.38v-6.01Zm31.85-6.92c-1.92,0-3.48-1.56-3.48-3.48,0-1.92,1.56-3.48,3.48-3.48h3.5c.93,0,1.8,.36,2.46,1.02,.66,.66,1.02,1.53,1.02,2.46h0c0,1.92-1.56,3.49-3.48,3.49h-3.5Zm0-5.4c-1.06,0-1.92,.86-1.92,1.92,0,1.06,.86,1.92,1.92,1.92h3.49c1.06,0,1.92-.86,1.92-1.92h0c0-.52-.2-1-.56-1.36-.36-.36-.84-.56-1.35-.56h-3.5Zm-6.05,5.4H20.9l-.76-2.95,1.21-2.82h19.08l1.21,2.82-.76,2.94Zm-18.77-1.56h17.56l.33-1.26-.59-1.38H22.38l-.59,1.38,.32,1.26Zm-7.26,1.56h-3.49c-1.92,0-3.48-1.56-3.48-3.48,0-.93,.36-1.8,1.02-2.46,.66-.66,1.53-1.02,2.46-1.02h3.49c1.92,0,3.48,1.56,3.48,3.48h0c0,1.92-1.56,3.48-3.48,3.48Zm-3.49-5.4c-.51,0-.99,.2-1.36,.56-.36,.36-.56,.84-.56,1.36,0,1.06,.86,1.92,1.92,1.92h3.49c1.06,0,1.92-.86,1.92-1.92,0-1.06-.86-1.92-1.92-1.92h-3.49Zm39.83-7.33H10.59l6.66-12.3c.35-.64,1.02-1.04,1.75-1.04h23.78c.73,0,1.4,.4,1.75,1.05l6.66,12.29Zm-37.97-1.56H48.57l-5.41-9.99c-.08-.14-.22-.23-.38-.23H19.01c-.16,0-.3,.09-.38,.23h0l-5.41,9.99Z"
        fill={isWhite ? "white" : "#554b6d"}
      />
    </svg>
  )
}

const MofaIcon = ({
  className,
  isWhite = false,
}: classNameProps): JSX.Element => {
  return (
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 67 46"
      className={className}
    >
      <g id="Gruppe_2450" transform="translate(186.98 253.683)" opacity="0.997">
        <g id="Gruppe_54" transform="translate(0 0)">
          <path
            fill={isWhite ? "white" : "#554b6d"}
            d="M-176.3-208.8c-1.5,0-2.9-0.4-4.2-1.2c-1.9-1.1-3.3-3-3.8-5.1c-1-3.9,1-7.9,4.5-9.6l-1.8-1.4l1.3-0.6
			c1.3-0.6,2.7-1,4.1-1.1l-1-1.1l8.4-11l7.5-0.7l-0.7,1.4c-2.2,4.5-2.9,9.7-1.9,14.6l0.6,3c0.2,0.8,0.8,1.3,1.6,1.3h7.2l2.6-3.4
			c1.8-2.3,1.9-5.4,0.4-7.9l-0.3-0.5l0.8-1.1c1.3-1.8,3.5-3,5.8-3c0,0,0,0,0,0h9.9l0.9-1.4h11.5l2.2,2.2l-4.6,2.6h0.2l-2.2,5.9
			l2.4,5.9l-6.5-4.8l-1.4,0.3c3.7,0.7,6.6,3.9,6.7,7.9c0.2,4.6-3.4,8.5-8,8.7c-4.4,0.2-8.1-3.1-8.6-7.4l-1.4-0.4l-0.5,0.8h-20.7
			l-2.4-2.7c0.3,1.9-0.1,3.9-1.1,5.6c-1.1,1.9-3,3.3-5.1,3.8C-174.9-208.9-175.6-208.8-176.3-208.8z M-178.3-225.7l2.1,1.7l-1.7,0.4
			c-3.5,0.9-5.7,4.5-4.8,8.1c0.4,1.7,1.5,3.2,3,4.1c1.5,0.9,3.3,1.2,5,0.7c1.7-0.4,3.2-1.5,4.1-3c0.9-1.5,1.2-3.3,0.7-5
			c-0.4-1.5-1.3-2.8-2.6-3.7l-1.3-0.9l2.6-1l6.3,7h18.9l0.7-1.1l3.8,1.1l0,0.6c0.1,3.6,3.2,6.5,6.9,6.3c3.6-0.2,6.5-3.2,6.3-6.9
			c-0.1-3.6-3.3-6.5-6.9-6.3c-2.1,0.1-4.1,1.2-5.3,3l-0.3,0.5l-3-0.8l2.8-4.3l9.5-2.1l2.3,1.7l-0.8-2l0.9-0.2l1.1-2.8h-4.2l8.3-4.7
			l-0.1-0.1h-9.8l-0.9,1.4h-10.9c0,0,0,0,0,0c-1.7,0-3.3,0.8-4.3,2.2l-0.1,0.1c1.6,3,1.3,6.7-0.8,9.3l-3.1,4h-8
			c-1.6,0-3-1.1-3.3-2.7l-0.6-3c-0.9-4.8-0.4-9.8,1.4-14.3l-3.7,0.3l-7.1,9.3l2.9,3.2l-2.3-0.3C-175.8-226.2-177.1-226-178.3-225.7z
			 M-139.8-223.9l-0.1,0.1c0.1,0,0.1-0.1,0.2-0.1L-139.8-223.9z M-134.7-211.7c-2.5,0-4.6-1.7-5.3-4.1l-0.4-1.5l5.3,1.5
			c0.4,0.1,0.7,0.1,1.1-0.1c0.3-0.2,0.6-0.5,0.7-0.9c0.2-0.8-0.2-1.5-1-1.7l-5.3-1.5l1.1-1.1c1-1,2.4-1.6,3.8-1.6c0,0,0,0,0,0
			c1.5,0,2.8,0.6,3.8,1.6c1,1,1.6,2.4,1.6,3.8C-129.3-214.2-131.7-211.7-134.7-211.7C-134.7-211.7-134.7-211.7-134.7-211.7z
			 M-137.4-214.6c0.7,0.7,1.6,1.2,2.7,1.2c0,0,0,0,0,0c2,0,3.7-1.7,3.7-3.7c0-1-0.4-1.9-1.1-2.6c-0.7-0.7-1.6-1.1-2.6-1.1
			c0,0,0,0,0,0h0c-0.4,0-0.7,0-1,0.1l1.9,0.5c1.7,0.5,2.7,2.2,2.2,3.9c-0.2,0.8-0.8,1.5-1.5,1.9c-0.7,0.4-1.6,0.5-2.4,0.3
			L-137.4-214.6z M-176.2-211.7c-3,0-5.4-2.4-5.4-5.4c0-3,2.4-5.4,5.4-5.4s5.4,2.4,5.4,5.4C-170.8-214.1-173.2-211.7-176.2-211.7z
			 M-176.2-220.8c-2,0-3.7,1.7-3.7,3.7c0,2,1.7,3.7,3.7,3.7c2,0,3.7-1.7,3.7-3.7C-172.5-219.2-174.2-220.8-176.2-220.8z"
          />
          <path
            fill={isWhite ? "white" : "#554b6d"}
            d="M-168.1-239.6l-3.2-2.1l3.4-4.4l5.1-1.1l4.5-5.6l4.1-0.2l0.2,0.7c0,0.1,0.3,1.1-0.5,2.2
			c-0.8,1.2-2.4,2-4.7,2.4l-1.1,1.4l1.5,1l-0.3,0.5h1.8c1,0,1.8,0.8,1.8,1.8c0,1-0.8,1.8-1.8,1.8h-4.2l-0.9,1.2L-168.1-239.6z
			 M-168.8-242.1l1.1,0.7l4.3-0.3l0.9-1.3h5.2l-5.1,0l1.3-1.8l-1-0.7l-4.7,1L-168.8-242.1z M-157.4-251.1l-1.2,1.5
			c1.4-0.3,2.3-0.8,2.8-1.4c0-0.1,0.1-0.1,0.1-0.2L-157.4-251.1z"
          />
        </g>
      </g>
    </svg>
  )
}

const MotoIcon = ({
  className,
  isWhite = false,
}: classNameProps): JSX.Element => {
  return (
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 61 46"
      className={className}
    >
      <g id="Gruppe_2449" transform="translate(461.421 1.019)">
        <g id="Gruppe_1958" transform="translate(0 0)">
          <path
            fill={isWhite ? "white" : "#554b6d"}
            d="M-413.3,39c-0.7,0-1.3-0.2-1.9-0.6c-0.7-0.5-1.1-1.2-1.3-2.1c-0.3-1.7,0.9-3.4,2.6-3.6l0,0
			c1.7-0.3,3.4,0.9,3.6,2.6c0.3,1.7-0.9,3.4-2.6,3.6C-413,39-413.2,39-413.3,39z M-413.6,34c-1,0.2-1.7,1.1-1.5,2.1
			c0.1,0.5,0.3,0.9,0.7,1.2c0.4,0.3,0.9,0.4,1.4,0.3c1-0.2,1.7-1.1,1.5-2.1C-411.7,34.5-412.6,33.9-413.6,34L-413.6,34z"
          />
          <path
            fill={isWhite ? "white" : "#554b6d"}
            d="M-451,44.4c-0.9,0-1.8-0.1-2.7-0.4c-2.2-0.7-4-2.2-5-4.3c-1-2.1-1.2-4.4-0.5-6.6s2.2-4,4.3-5
			c1.9-0.9,4-1.2,6-0.7l0.7-1.8l-0.4-0.2l1.1-2.7l0.4,0.2l0.3-0.7c-0.1,0-0.2,0-0.4,0l-9.6,1.9l2.8-3.1l0.1-0.1
			c0.9-0.4,1.8-0.9,2.8-1.3c-0.2-0.8-0.4-2.2,0.2-3.5c1.9-3.6,5.5-8,5.7-8.2l0.6-0.8l2.4,4.2l-0.7,0.3c-3.1,1.3-2.9,3-2.1,5.6
			c0.1,0,0.1,0,0.2,0l0.6-1.6c0.2-0.4,0.5-0.7,0.8-0.9l0.1,0l1.7-0.8c0.3-1,1.2-1.8,2.3-1.8c0,0,0,0,0,0l5.2,0l4.4-2.2
			c0.5-0.9,1.3-1.6,2.2-2c2.1-0.8,4.5,0.3,5.3,2.5l2.7,9.5l0,0c0,0.1,0,0.3,0,0.4l9.4-1.2l0.4,0.2l6.1-1.9l-0.7,2.3l0.6,1.1
			l-2.1,1.4l-3.4,0.8l0.6,0.8l1.2-0.9l1.7,2.4l-1.3,0.9l0.9,1.3l-2,1.4c2,1.6,3.2,4.1,3.2,6.7c0,4.7-3.9,8.6-8.6,8.6c0,0,0,0,0,0
			c-4.3,0-7.9-3.2-8.5-7.4l-1.3,0.9l0,0l-3.2,1.8c-1.4,0.8-2.9,1.2-4.5,1.2c-1.9,0-4.4-0.1-5.5-0.1c-1.2-0.1-2.2-0.7-2.9-1.7
			c-1.2-0.5-2.2-1.5-2.7-2.7l-0.4-1.2c-0.6-1.8,0.1-3.8,1.8-4.8l0.4-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0-0.5l-5.8-3.1l-0.7,1.8
			c3.5,2.1,5,6.2,3.8,10.1C-444,42.1-447.4,44.4-451,44.4z M-451,28.6c-1.1,0-2.2,0.3-3.3,0.8c-1.7,0.9-3,2.4-3.6,4.2
			c-0.6,1.8-0.5,3.8,0.4,5.5c0.9,1.7,2.4,3,4.2,3.6c3.8,1.2,7.9-0.8,9.2-4.7c1.1-3.4-0.4-7.1-3.6-8.7l-0.5-0.2l-0.5-0.2
			C-449.5,28.7-450.3,28.6-451,28.6z M-420.6,34.5v1.3c0,4,3.3,7.3,7.3,7.3c0,0,0,0,0,0c4,0,7.3-3.3,7.3-7.3c0-2.5-1.3-4.8-3.4-6.1
			l-0.9-0.5l2.5-1.7l-0.9-1.3l1.2-0.9l-0.1-0.2l-1.2,0.9l-0.8-1.2l-5.9,4.1c-1.4,0.4-2.6,1.3-3.5,2.4l-0.1,0.1l-0.9,0.6l0.3,0.4
			l-0.2,0.3c-0.2,0.3-0.3,0.7-0.4,1l-0.1,0.2l-4.1,2.9l-1.2-5.2l12.5-8.9l5.3-1.3l-0.2-1.2l-1.5,0.5l-0.5-0.2l-8.2,1
			c-0.2,0.8-0.8,1.6-1.6,1.9c-0.4,0.2-0.8,0.3-1.2,0.4l-0.5-1.2c0.4-0.3,0.7-0.7,0.8-1.2l0-0.1c0.1-0.5,0.1-0.9,0-1.4l0-0.1
			l-2.6-8.9c-0.5-1.4-2.1-2.1-3.5-1.6c-0.7,0.3-1.3,0.8-1.6,1.5l-0.1,0.2l-5.1,2.6l-5.5,0c0,0,0,0,0,0c-0.5,0-1,0.4-1,1l-0.1,0.4
			l0.3,0.3c0.2,0.3,0.5,0.4,0.8,0.4c2.2,0,5.5,0.1,5.7,0.1c0.2,0,0.3,0,0.4-0.1l4.6-2.4l0.5,0.6l0.1,0.1l2.1,7.4l-3.6,1.9l-0.3-0.1
			c-1-0.3-1.6-0.6-1.6-0.6l-0.1,0l-0.1-0.1c-2-1.9-5.5-4.7-6.6-4.7c-1.6-0.1-3.1,0-4.6,0.4l-1.4,0.3c-0.3,0.1-0.7,0.2-1.1,0.3
			l-0.6,0.2l-0.2-0.6c-0.9-2.5-1.9-5.6,2-7.6l-0.7-1.2c-1.1,1.4-3.6,4.6-4.9,7.2c-0.6,1.2-0.1,2.7,0,3l0.2,0.6l-0.6,0.2
			c-0.9,0.4-1.9,0.8-2.8,1.3l5.5-1.1l0.1,0c0.5,0,1,0.1,1.5,0.2l0.8,0.2l0,0l0.5,0.2c0.6,0.3,1.1,0.7,1.4,1.3l0.1,0.3l-1.2,3l5.6,3
			l-0.2,0.5c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.3,0,0.4l0.1,0.5l-1.2,0.6c-1.1,0.6-1.6,2-1.2,3.1l0.4,1.1c0.4,0.9,1.1,1.6,2.1,2
			l-1-0.8c-0.4-0.3-0.6-0.7-0.8-1.1l0,0l-0.4-1.3c-0.4-1.1,0-2.3,1-2.9l1.4-0.7v6.8l0.1,0l0.1,0.2c0.4,0.7,1.1,1.1,1.9,1.2
			c1,0,3.5,0,5.4,0.1c1.3,0,2.6-0.3,3.8-1l3-1.8L-420.6,34.5z M-424.2,32.4l0.5,2.4l2.1-1.5c0.1-0.2,0.1-0.4,0.2-0.6l-0.6-0.9
			l1.9-1.3c1-1.3,2.4-2.3,4-2.8l6-4.1l-2.5,0.6L-424.2,32.4z M-446.5,24.9l0.2,0.1l0.1-0.2l-0.2-0.1l0,0l-0.4-0.2l-0.1,0.2
			L-446.5,24.9L-446.5,24.9z M-444.7,23.9L-444.7,23.9l0.3-0.7c0,0,0,0,0,0L-444.7,23.9z M-430.6,21.5c0.2,0.1,0.6,0.2,1.1,0.4
			l2.1-1.1l-1.6-5.6l-3.4,1.8c-0.3,0.2-0.7,0.3-1,0.3c-0.1,0-1.4,0-2.7,0C-434.1,18.3-431.4,20.7-430.6,21.5z M-406.5,21.2l-0.8,0.2
			l1.1-0.3l0.2-0.2L-406.5,21.2z M-440.7,16.6L-440.7,16.6C-440.7,16.6-440.7,16.6-440.7,16.6C-440.7,16.6-440.7,16.6-440.7,16.6z
			 M-413.3,42.2c-3.5,0-6.4-2.9-6.4-6.4c0-0.4,0-0.7,0.1-1.1l0.3-1.6l1.1,1.6l7-4.9l0.3,0.1c2.4,1,3.9,3.3,3.9,5.9l0,2.2l-0.3-0.4
			C-408,40.3-410.5,42.2-413.3,42.2C-413.3,42.2-413.3,42.2-413.3,42.2z M-418.3,36.4c0.3,2.5,2.4,4.4,5,4.4c0,0,0,0,0,0
			c2.8,0,5-2.3,5-5v-0.1c0-1.9-1.1-3.5-2.7-4.4L-418.3,36.4z M-451,42.2C-451,42.2-451,42.2-451,42.2c-3.5,0-6.4-2.9-6.4-6.4
			c0-3.5,2.9-6.4,6.4-6.4c0,0,0,0,0,0c0.6,0,1.2,0.1,1.8,0.3l0.6,0.2l0,0l0.1,0.1l0,0l0.5,0.3c2.1,1.1,3.3,3.3,3.3,5.6l0,0
			c0,1.7-0.7,3.3-1.9,4.5C-447.7,41.5-449.3,42.2-451,42.2z M-451,30.8c-2.8,0-5,2.2-5,5c0,2.8,2.2,5,5,5c0,0,0,0,0,0
			c1.3,0,2.6-0.5,3.5-1.5c0.9-0.9,1.5-2.2,1.5-3.5c0-1.6-0.7-3.1-2-4l-0.1,0.1l0.4,0.2l-0.8,1.8c0.4,0.5,0.6,1.1,0.6,1.8
			c0,0.8-0.3,1.7-0.9,2.3c-0.6,0.6-1.4,1-2.2,1c-1.8,0.1-3.2-1.3-3.3-3.1c0-0.8,0.3-1.7,0.9-2.3c0.5-0.5,1.1-0.8,1.8-1l0.8-1.9
			C-450.9,30.8-451,30.8-451,30.8C-451,30.8-451,30.8-451,30.8z M-450.1,32.6l-0.6,1.4l-0.4,0c-1,0-1.8,0.9-1.8,1.9
			c0,1,0.9,1.8,1.9,1.8c0.5,0,0.9-0.2,1.3-0.6c0.3-0.4,0.5-0.8,0.5-1.3c0-0.5-0.2-0.9-0.5-1.2l-0.3-0.3l0.6-1.4l-0.3-0.1l0,0
			L-450.1,32.6z M-450.8,30.8l0.3,0.1l0-0.1C-450.5,30.8-450.6,30.8-450.8,30.8z"
          />
          <path
            fill={isWhite ? "white" : "#554b6d"}
            d="M-429.2,10.4l-0.5-0.1c-1.1-0.3-2-1-2.7-1.9l-0.5-0.7l2.3-1c0.5-0.2,0.9-0.7,0.9-1.3l0.1-1.4l-4,1.7
			l0.1-1.2c0.1-1,0.5-2,1.2-2.7c0.9-1.1,2.2-1.8,3.6-1.9c1.4-0.1,2.8,0.3,3.9,1.2c2.3,1.8,2.6,5.2,0.8,7.5l-0.3,0.4l-0.5-0.2
			c-0.4-0.2-0.8-0.2-1.3-0.2c0,0,0,0,0,0c-1.1,0-2.2,0.6-2.9,1.5L-429.2,10.4z M-430.6,8.2c0.3,0.3,0.6,0.5,1,0.6
			c0.9-1.1,2.3-1.7,3.7-1.7c0.4,0,0.8,0.1,1.2,0.2c1.1-1.7,0.7-3.9-0.8-5.2c-0.8-0.7-1.9-1-2.9-0.9c-1.1,0.1-2,0.6-2.7,1.4
			c-0.2,0.2-0.4,0.5-0.5,0.8l1.8-0.7c0.6-0.3,1.4,0,1.6,0.7c0.1,0.2,0.1,0.4,0.1,0.6l0,0l-0.1,1.6c-0.1,1.1-0.8,2-1.7,2.4
			L-430.6,8.2z"
          />
        </g>
      </g>
    </svg>
  )
}

const TraktorIcon = ({
  className,
  isWhite = false,
}: classNameProps): JSX.Element => {
  return (
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 77 46"
      className={className}
    >
      <g id="Gruppe_2451" transform="translate(1.034 420.022)">
        <path
          fill={isWhite ? "white" : "#554b6d"}
          d="M5.1-386.3l-1.6-1.1c-1-0.7-1.2-2.1-0.5-3c0.4-0.6,1.1-0.9,1.8-0.9c0,0,0,0,0,0h4.2l-1.4,1.3
     c-0.9,0.8-1.5,1.8-2,2.9L5.1-386.3z M4.7-389.9c-0.2,0-0.4,0.1-0.5,0.3c-0.2,0.3-0.1,0.7,0.2,0.9l0.1,0c0.2-0.4,0.5-0.8,0.8-1.2
     H4.7C4.7-389.9,4.7-389.9,4.7-389.9z"
        />
        <path
          fill={isWhite ? "white" : "#554b6d"}
          d="M45.8-408.5L45.8-408.5c-2,0-3.6-1.6-3.6-3.6c0-2,1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6
     C49.4-410.1,47.8-408.5,45.8-408.5z M45.8-414c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h0c1.1,0,2-0.9,2-2C47.8-413.1,46.9-414,45.8-414z"
        />
        <path
          fill={isWhite ? "white" : "#554b6d"}
          d="M13-382.3c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7c0,0,0,0,0,0c0.5,0,0.9,0.2,1.2,0.5
     c0.3,0.3,0.5,0.8,0.5,1.2v0C14.8-383.1,14-382.3,13-382.3z M13-384.2C13-384.2,13-384.2,13-384.2c-0.1,0-0.2,0.1-0.2,0.2
     c0,0.2,0.3,0.2,0.3,0c0-0.1,0-0.1,0-0.1C13.1-384.2,13.1-384.2,13-384.2z"
        />
        <path
          fill={isWhite ? "white" : "#554b6d"}
          d="M71-387c-0.4,0-0.8-0.1-1.2-0.4h-1.3l0.1-0.8c0-0.5,0.1-0.9,0.1-1.3l0-0.9h1.1c0.7-0.5,1.7-0.5,2.4,0.1
     c0.4,0.3,0.6,0.8,0.7,1.3c0,0.5-0.1,1-0.5,1.4c-0.3,0.4-0.8,0.6-1.3,0.7C71.1-387,71.1-387,71-387z M71-389.1L71-389.1
     c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2C71.3-389.1,71.2-389.1,71-389.1z M69.5-389.7h0.4H69.5z"
        />
        <path
          fill={isWhite ? "white" : "#554b6d"}
          d="M55.1-374.8c-0.2,0-0.5-0.1-0.7-0.2c-0.4-0.2-0.8-0.6-1-1c0,0,0,0-0.1,0c-0.3,0.3-0.6,0.5-1.1,0.6l-0.1,0
     l-1.2,0c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.6-0.8-0.6-1.3c0,0,0,0,0,0c-0.4,0.2-0.8,0.3-1.2,0.2l-0.1,0l-1.2-0.3
     c-0.4-0.1-0.7-0.4-0.9-0.7c-0.2-0.4-0.3-0.9-0.2-1.4c0,0,0,0,0,0c-0.4,0.1-0.8,0.1-1.2-0.1l-0.1,0l-1-0.7c-0.3-0.2-0.5-0.6-0.6-1
     c-0.1-0.5,0-1,0.3-1.4c0,0,0,0,0,0c-0.4,0-0.8-0.2-1.1-0.5l-0.1-0.1l-0.7-1c-0.3-0.4-0.4-0.9-0.2-1.5c0.1-0.3,0.3-0.7,0.6-0.9
     c0,0,0,0,0-0.1c-0.4-0.2-0.7-0.4-0.9-0.8l0-0.1l-0.4-1.1c-0.1-0.4-0.1-0.8,0.1-1.1c0.2-0.4,0.6-0.8,1-1c0,0,0,0,0-0.1
     c-0.3-0.3-0.5-0.6-0.6-1.1l0-0.1l0-1.2c0-0.9,0.7-1.5,1.7-1.7c0,0,0,0,0,0c-0.2-0.4-0.3-0.8-0.2-1.2l0-0.1l0.3-1.2
     c0.1-0.4,0.4-0.7,0.7-0.9c0.4-0.2,0.9-0.3,1.4-0.2c0,0,0,0,0,0c-0.1-0.4-0.1-0.8,0.1-1.2l0-0.1l0.7-1c0.2-0.3,0.6-0.5,1-0.6
     c0.5-0.1,1,0,1.4,0.3c0,0,0,0,0,0c0-0.4,0.2-0.8,0.5-1.1l0.1-0.1l1-0.7c0.3-0.2,0.7-0.3,1.1-0.3c0.5,0.1,0.9,0.3,1.3,0.7
     c0,0,0,0,0.1,0c0.2-0.4,0.4-0.7,0.8-0.9l0.1,0l1.1-0.4c0.8-0.3,1.7,0.2,2.1,1.1c0,0,0,0,0.1,0c0.3-0.3,0.6-0.5,1.1-0.6l0.1,0l1.2,0
     c0.9,0,1.5,0.7,1.7,1.7c0,0,0,0,0,0c0.4-0.2,0.8-0.3,1.2-0.2l0.1,0l1.2,0.3c0.5,0.1,0.8,0.5,1,1.1c0.1,0.3,0.1,0.7,0.1,1.1
     c0,0,0,0,0,0c0.4-0.1,0.8-0.1,1.2,0.1l0.1,0l1,0.7c0.4,0.3,0.6,0.8,0.6,1.3c0,0.4-0.1,0.7-0.3,1.1c0,0,0,0,0,0
     c0.4,0,0.8,0.2,1.1,0.5l0.1,0.1l0.7,1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.5-0.3,0.9-0.7,1.3c0,0,0,0,0,0.1c0.4,0.1,0.7,0.4,0.9,0.8
     l0,0.1l0.4,1.1c0.1,0.4,0.1,0.8-0.1,1.1c-0.2,0.4-0.6,0.8-1,1c0,0,0,0,0,0.1c0.3,0.3,0.5,0.6,0.6,1.1l0,0.1l0,1.2
     c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.8,0.6-1.3,0.6c0,0,0,0,0,0c0.2,0.4,0.3,0.8,0.2,1.2l0,0.1l-0.3,1.2c-0.2,0.8-1.2,1.2-2.2,1.1
     c0,0,0,0,0,0c0.1,0.4,0.1,0.8-0.1,1.2l0,0.1l-0.7,1c-0.5,0.7-1.5,0.8-2.4,0.3c0,0,0,0,0,0c0,0.4-0.2,0.8-0.5,1.1l-0.1,0.1l-1,0.7
     c-0.3,0.2-0.7,0.3-1.1,0.3c-0.5-0.1-0.9-0.3-1.3-0.7c0,0,0,0-0.1,0c-0.2,0.4-0.4,0.7-0.8,0.9l-0.1,0l-1.1,0.4
     C55.4-374.8,55.3-374.8,55.1-374.8z M52.4-377.7l0.7,0.1c0.3,0,0.6,0.1,1,0.1l0.5,0l0.2,0.6c0.1,0.3,0.3,0.5,0.4,0.5l1-0.4
     c0,0,0.1-0.1,0.1-0.1l0-0.7l0.7-0.1c0.3-0.1,0.6-0.1,0.9-0.2l0.5-0.1l0.4,0.5c0.2,0.3,0.4,0.4,0.5,0.3l0.9-0.7c0,0,0-0.1,0-0.1
     l-0.3-0.6l0.6-0.3c0.3-0.2,0.6-0.3,0.8-0.5l0.4-0.3l0.6,0.4c0.3,0.2,0.5,0.2,0.6,0.2l0.6-0.9c0,0,0-0.1,0-0.1l-0.5-0.5l0.5-0.5
     c0.2-0.2,0.4-0.5,0.6-0.7l0.3-0.4l0.7,0.2c0.3,0.1,0.6,0,0.6,0l0.3-1c0,0,0-0.1-0.1-0.1l-0.6-0.4l0.3-0.6c0.1-0.3,0.3-0.6,0.4-0.9
     l0.2-0.5H67c0.3,0,0.5-0.1,0.6-0.2l0-1.1c0,0,0-0.1-0.1-0.1l-0.7-0.2l0.1-0.7c0-0.3,0.1-0.6,0.1-1l0-0.5l0.6-0.2
     c0.3-0.1,0.5-0.3,0.5-0.4l-0.4-1c0,0-0.1-0.1-0.1-0.1l-0.7,0l-0.1-0.7c-0.1-0.3-0.1-0.6-0.2-1l-0.1-0.5l0.5-0.4
     c0.3-0.2,0.3-0.4,0.3-0.5l-0.7-0.9c0,0-0.1,0-0.1,0l-0.7,0.3l-0.3-0.6c-0.1-0.3-0.3-0.6-0.5-0.8l-0.3-0.4l0.4-0.6
     c0.2-0.3,0.2-0.5,0.2-0.6l-0.9-0.6c0,0-0.1,0-0.1,0l-0.5,0.5l-0.5-0.5c-0.2-0.2-0.5-0.4-0.7-0.7l-0.4-0.3l0.2-0.7
     c0.1-0.3,0-0.6,0-0.6l-1-0.3c-0.1,0-0.1,0-0.1,0.1l-0.4,0.6l-0.6-0.3c-0.3-0.1-0.6-0.3-0.9-0.4l-0.5-0.2v-0.7
     c0-0.3-0.2-0.5-0.2-0.6l-1.1,0c0,0-0.1,0-0.1,0.1l-0.2,0.7l-0.7-0.1c-0.3,0-0.6-0.1-1-0.1l-0.5,0l-0.2-0.6
     c-0.1-0.3-0.3-0.5-0.4-0.5l-1,0.4c0,0-0.1,0.1-0.1,0.1l0,0.7l-0.7,0.1c-0.3,0.1-0.6,0.1-0.9,0.2l-0.5,0.1l-0.4-0.5
     c-0.2-0.3-0.4-0.4-0.5-0.3l-0.9,0.7c0,0,0,0.1,0,0.1l0.3,0.7l-0.6,0.3c-0.3,0.1-0.6,0.3-0.8,0.5l-0.4,0.3l-0.6-0.4
     c-0.3-0.2-0.5-0.2-0.6-0.2l-0.6,0.9c0,0,0,0.1,0,0.1l0.5,0.5l-0.5,0.5c-0.2,0.2-0.4,0.5-0.7,0.7l-0.3,0.4l-0.7-0.2
     c-0.3-0.1-0.6,0-0.6,0l-0.3,1c0,0,0,0.1,0.1,0.1l0.6,0.4l-0.3,0.6c-0.1,0.3-0.3,0.6-0.4,0.9l-0.2,0.5h-0.7c-0.3,0-0.5,0.2-0.6,0.2
     l0,1.1c0,0,0,0.1,0.1,0.1L43-392l-0.1,0.7c0,0.3-0.1,0.6-0.1,1l0,0.5l-0.6,0.2c-0.3,0.1-0.5,0.3-0.5,0.4l0.4,1c0,0,0.1,0.1,0.1,0.1
     l0.7,0l0.1,0.7c0.1,0.3,0.1,0.6,0.2,0.9l0.1,0.5l-0.5,0.4c-0.3,0.2-0.3,0.4-0.3,0.5l0.7,0.9c0,0,0.1,0,0.1,0l0.7-0.3l0.3,0.6
     c0.2,0.3,0.3,0.6,0.5,0.8l0.3,0.4l-0.4,0.6c-0.2,0.3-0.2,0.5-0.2,0.6l0.9,0.6c0,0,0.1,0,0.1,0l0.5-0.5l0.5,0.5
     c0.2,0.2,0.5,0.4,0.7,0.7l0.4,0.3l-0.2,0.7c-0.1,0.3,0,0.6,0,0.6l1,0.3c0,0,0.1,0,0.1-0.1l0.4-0.6l0.6,0.3c0.3,0.1,0.6,0.3,0.9,0.4
     l0.5,0.2v0.7c0,0.3,0.1,0.5,0.2,0.6l1.1,0c0,0,0.1,0,0.1-0.1L52.4-377.7z M54.9-383.2l-0.7,0c-3.2-0.3-5.7-3-5.7-6.3
     c0-3.5,2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C61.2-386,58.4-383.2,54.9-383.2z M54.9-394.3c-2.6,0-4.8,2.1-4.8,4.8
     c0,2.6,2.2,4.8,4.8,4.8H55c2.6-0.1,4.7-2.2,4.7-4.8C59.7-392.2,57.5-394.3,54.9-394.3z"
        />
        <path
          fill={isWhite ? "white" : "#554b6d"}
          d="M52.5-385.6c-0.4,0-0.8-0.2-1.1-0.5c-0.6-0.6-0.6-1.6,0-2.3c0.6-0.6,1.6-0.6,2.3,0l0,0
     c0.6,0.6,0.6,1.6,0,2.3C53.3-385.7,52.9-385.6,52.5-385.6z M52.5-387.2C52.5-387.2,52.5-387.2,52.5-387.2c-0.1,0-0.1,0.1,0,0.1
     c0,0,0.1,0,0.1,0C52.6-387.2,52.6-387.2,52.5-387.2l0.6-0.5L52.5-387.2C52.5-387.2,52.5-387.2,52.5-387.2z"
        />
        <path
          fill={isWhite ? "white" : "#554b6d"}
          d="M52.5-390.3c-0.4,0-0.8-0.2-1.1-0.5c-0.6-0.6-0.6-1.6,0-2.3c0.6-0.6,1.6-0.6,2.3,0l0,0
     c0.3,0.3,0.5,0.7,0.5,1.1c0,0.4-0.2,0.8-0.5,1.1C53.3-390.4,52.9-390.3,52.5-390.3z M52.5-392C52.5-392,52.5-392,52.5-392
     c-0.1,0-0.1,0.1,0,0.1c0,0,0.1,0,0.1,0C52.6-391.9,52.6-391.9,52.5-392L52.5-392C52.5-392,52.5-392,52.5-392z"
        />
        <path
          fill={isWhite ? "white" : "#554b6d"}
          d="M57.2-390.3c-0.4,0-0.8-0.2-1.1-0.5l0,0c-0.6-0.6-0.6-1.6,0-2.3c0.3-0.3,0.7-0.5,1.1-0.5c0,0,0,0,0,0
     c0.4,0,0.8,0.2,1.1,0.5c0.6,0.6,0.6,1.6,0,2.3C58-390.4,57.6-390.3,57.2-390.3z M57.2-391.9C57.2-391.8,57.2-391.8,57.2-391.9
     C57.3-391.9,57.3-391.9,57.2-391.9C57.2-392,57.2-392,57.2-391.9C57.2-391.9,57.2-391.9,57.2-391.9z"
        />
        <path
          fill={isWhite ? "white" : "#554b6d"}
          d="M57.2-385.6c-0.4,0-0.8-0.2-1.1-0.5c-0.6-0.6-0.6-1.6,0-2.3c0.3-0.3,0.7-0.5,1.1-0.5c0,0,0,0,0,0
     c0.4,0,0.8,0.2,1.1,0.5l0,0c0.6,0.6,0.6,1.6,0,2.3C58-385.7,57.6-385.6,57.2-385.6z M57.2-387.2C57.2-387.2,57.2-387.2,57.2-387.2
     c-0.1,0-0.1,0.1,0,0.1c0,0,0.1,0,0.1,0C57.3-387.2,57.3-387.2,57.2-387.2C57.3-387.2,57.2-387.2,57.2-387.2z"
        />
        <path
          fill={isWhite ? "white" : "#554b6d"}
          d="M54.9-387.2c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3
     C57.2-388.3,56.1-387.2,54.9-387.2z M54.9-390.3c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8
     C55.6-390,55.3-390.3,54.9-390.3z"
        />
        <path
          fill={isWhite ? "white" : "#554b6d"}
          d="M41.8-384.7H29.3l-2.8-2.6h-1.2v2.6h-4l-0.1-0.7c-0.3-2.1-1.5-4-3.2-5.3c-1.2-0.8-2.5-1.4-4-1.6l-0.3,0
     c-0.1,0-0.2,0-0.3,0l-1.4,0.1c-0.6,0.1-1.3,0.3-2,0.5L9-391.3v-8.1c-0.6-0.6-1-1.4-1-2.3c0-1.8,1.5-3.3,3.3-3.3c0,0,0,0,0,0h9.5
     v-0.8h-0.6v-7.6h0.6v-1.8l1.4-3.1H25l-1.5,3.7v1.1h0.6v7.6h-0.6v0.8h10.4l0.7,0.9l0.4-0.4l-1.6-1.6c-0.4-0.4-0.5-1.1-0.2-1.6
     c0.2-0.3,0.5-0.5,0.8-0.5c0.3-0.1,0.7,0,0.9,0.2c0.1,0,0.1,0.1,0.2,0.1l1.8,1.8c0.2,0,0.5,0,0.7,0.1c0.4,0.2,0.7,0.6,0.7,1h2.9
     l1.3-1.7c0.1-1.6,1.3-2.8,2.9-2.9c0.8-0.1,1.6,0.2,2.2,0.8c0.6,0.5,1,1.3,1,2.1l0,1.5c6-2.4,12.7-1.2,17.5,3.1l0.5,0.5l-1.8,2.3
     l-0.6-0.6c-2.7-2.5-6.3-3.8-10-3.7c-3.7,0.2-7.1,1.7-9.6,4.5c-3.3,3.6-4.5,8.6-3.1,13.3L41.8-384.7z M29.9-386.2h9.9
     c-1-4.6,0.2-9.5,3.2-13.2l-3-0.2c-0.4,0-0.7,0.2-0.8,0.5l-2,7c-0.1,0.3,0.1,0.6,0.4,0.8l-0.4,1.5l-5.7,0l1.1-5.6h0.8l1.2-5.5
     l-0.5-0.7l0,0l-1.2-1.8H11.3c0,0,0,0,0,0c-1,0-1.7,0.8-1.8,1.7c0,0.5,0.3,1,0.7,1.3l0.3,0.2v6.6c0.4-0.1,0.9-0.2,1.4-0.3l1.1,0
     v-0.1l0.9,0.1c1.8,0.2,3.6,0.9,5.1,1.9l0,0c1.9,1.4,3.2,3.4,3.7,5.6h1.1v-2.6h3.4L29.9-386.2z M34-393.9l-0.5,2.5l1.1,0v-2.1
     c-0.2-0.1-0.3-0.3-0.4-0.4H34z M35.6-395.2c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.2,0.2,0.3l0.1,0l0.1,0
     c0.1,0,0.1-0.1,0.2-0.2C35.9-395,35.8-395.1,35.6-395.2C35.6-395.1,35.6-395.2,35.6-395.2z M35.6-396.7c0.1,0,0.3,0,0.4,0.1
     c0.3,0.1,0.6,0.2,0.8,0.4l0.9-3.3c0.1-0.5,0.5-1,0.9-1.3c-0.2-0.1-0.3-0.2-0.4-0.3h-0.8c0,0,0,0,0,0c-0.5,0-0.9-0.2-1.2-0.4
     l0.1,0.2l-1,4.6C35.5-396.7,35.5-396.7,35.6-396.7z M44.2-402l0,1.3c0.9-0.8,1.8-1.6,2.9-2.2v-3.6c0-0.8-0.8-1.4-1.6-1.4
     c-0.8,0-1.5,0.8-1.4,1.6l0,0.3l-2.1,2.7h-4.6l-0.6-0.6l-0.2,0.2l0.7,0.6c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.2,0.2,0.4,0.4,0.4
     c0,0,0,0,0,0h1.4l0.1,0.1l0-0.1h3.1c0.1,0,0.2-0.1,0.3-0.1l2.9-3.7l0.6,0.3c0.1,0,0.2,0.1,0.2,0.2c0.2,0.2,0.3,0.5,0.3,0.8
     c0,0.3-0.1,0.5-0.3,0.7C45.9-404.1,45-403,44.2-402z M41.7-401l0.9,0.1c0,0,0,0,0,0l0-0.1c-0.2,0.1-0.4,0.1-0.6,0.1H41.7z
      M34.4-407.1L34.4-407.1C34.4-407.1,34.4-407.1,34.4-407.1z M21.9-407.2h0.6v-4.5h-0.8v4.5H21.9z"
        />
        <path
          fill={isWhite ? "white" : "#554b6d"}
          d="M13-375.2c-4.9,0-8.8-4-8.8-8.8c0-4.9,4-8.8,8.8-8.8c4.9,0,8.8,4,8.8,8.8C21.9-379.2,17.9-375.2,13-375.2z
      M13-391.3c-4,0-7.3,3.3-7.3,7.3c0,4,3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3C20.3-388,17.1-391.3,13-391.3z M13-379.9
     c-2.3,0-4.1-1.9-4.1-4.1l0,0l0,0l0,0c0,0,0,0,0,0c0-2.3,1.9-4.1,4.1-4.1c2.3,0,4.1,1.9,4.1,4.1C17.2-381.8,15.3-379.9,13-379.9z
      M10.4-384c0,1.4,1.2,2.6,2.6,2.6c1.4,0,2.6-1.2,2.6-2.6c0-1.4-1.2-2.6-2.6-2.6C11.6-386.6,10.4-385.5,10.4-384z"
        />
      </g>
    </svg>
  )
}

const KreiselIcon = ({
  className,
  isWhite = false,
}: classNameProps): JSX.Element => {
  return (
    <svg
      version="1.1"
      id="kreisel-icon"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 187 188"
      className={className}
    >
      <g>
        <g
          id="Gruppe_2448_00000015349212762484461900000013294167373998106811_"
          transform="translate(0 0)"
        >
          <path
            fill={isWhite ? "white" : "#554b6d"}
            d="M93.8,186.8c-0.1,0-0.2,0-0.2,0l-0.5,0c-24.6-0.1-47.8-9.8-65.2-27.2C10.4,142,0.7,118.7,0.7,93.9
       c0-24.8,9.7-48.1,27.2-65.7c17.5-17.4,40.7-27,65.4-27c0.1,0,0.2,0,0.4,0c24.8,0.1,48.1,9.8,65.6,27.4c17.4,17.5,27,40.8,27,65.4
       s-9.6,47.9-27,65.4C142.1,176.9,118.2,186.8,93.8,186.8z M93.6,3.2c-24.2,0-47,9.4-64.1,26.6C12.4,46.9,2.9,69.7,2.9,93.9
       c0,24.2,9.4,47,26.6,64.1c35,35.7,92.6,36.2,128.2,1.1c17.3-17,26.9-39.7,27.1-63.9c0.2-24.2-9-47.1-26-64.4
       c-0.4-0.4-0.8-0.8-1.1-1.1C140.9,12.9,117.7,3.2,93.8,3.2C93.8,3.2,93.7,3.2,93.6,3.2L93.6,3.2z M81.8,2.9
       c-20.1,2.5-38.6,11.5-53.2,26C11.3,46.3,1.7,69.3,1.7,93.9c0,24.5,9.6,47.6,26.9,65c6.4,6.4,13.6,11.8,21.4,16
       c-7.6-4.2-14.8-9.6-21.2-16.1C11.5,141.4,1.9,118.4,1.9,93.9c0-24.5,9.5-47.5,26.8-64.8C43.3,14.6,61.8,5.5,81.8,2.9z"
          />
        </g>
        <g
          id="Gruppe_2447_00000000188677714469451410000011332284316403547583_"
          transform="translate(44.924 21.281)"
        >
          <path
            fill={isWhite ? "white" : "#554b6d"}
            d="M81.2,54.7l-0.9-1.4c-3.7-5.6-8.8-10.3-14.7-13.5c-5.4-2.8-11.3-4.6-17.3-5.3l2.2,5
       c1.4,3.1,2.7,6.2,4.1,9.3c0.3,0.7,0.5,2.1-0.6,2.7l-0.3,0.2c-0.6,0.4-1.4,0.8-2.4,0.7c-2-0.4-3.9-0.9-5.8-1.3l-1.7-0.4
       C29.8,47.2,15.9,43.8,2,40.5c-0.3-0.1-0.5-0.1-0.9-0.2l-2.3-0.6l0.9-1.7C0,37.6,0.2,37.3,0.5,37l0.1-0.1C12.9,25,25.2,13,37.4,1
       c1.8-1.7,3.3-0.9,3.8-0.6C42.1,1.1,42.6,2,42.7,3c0.3,3.4,0.6,6.8,0.8,10.3l0,0.5c0.1,0,0.1,0,0.2,0c10-0.1,18.6,1.5,26.2,4.8
       c7.1,3.3,13.5,7.8,18.9,13.5c1.6,1.6,3.1,3.3,4.6,5c0.8,0.8,1.5,1.7,2.3,2.5c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0.1,0.2,0.3,0.3,0.4
       l-1.2,1l0.9,1.2c-4.5,3.6-9,7.1-13.6,10.7L81.2,54.7z M44.5,31.2l1.7,0.1c7.3,0.5,14.2,2.5,20.7,5.9c5.9,3.2,11,7.7,14.9,13.1
       c3.9-3,7.7-6,11.5-9c-0.7-0.7-1.4-1.5-2-2.2c-1.5-1.6-3-3.3-4.6-4.9c-5.1-5.4-11.2-9.7-17.9-12.8c-7.2-3.2-15.4-4.7-24.9-4.6
       c-0.3,0-0.7,0-1,0l-2,0l-0.3-3.3c-0.3-3.5-0.5-6.8-0.8-10.2c0-0.1-0.1-0.2-0.1-0.3c0,0-0.1,0.1-0.1,0.1
       C27.7,14.7,15.8,26.3,3.9,37.8c13.5,3.3,27,6.6,40.5,9.8l1.7,0.4c1.7,0.4,3.5,0.9,5.3,1.2c-1.3-2.9-2.5-5.7-3.8-8.6L45,34.6
       c-0.2-0.5-0.3-1.1-0.3-1.6L44.5,31.2z M51.8,50.1C51.8,50.1,51.8,50.1,51.8,50.1C51.8,50.1,51.8,50.1,51.8,50.1z"
          />
        </g>
        <g
          id="Gruppe_2446_00000150090781237704867770000005663253628264831671_"
          transform="translate(31.524 69.353)"
        >
          <path
            fill={isWhite ? "white" : "#554b6d"}
            d="M52.7,86.1c-2.3-0.6-4.4-1.2-6.4-1.8C31.6,80.3,17,76.2,2.3,72.1c-0.8-0.2-2.4-0.7-2.6-2.6
       c-0.2-1.4,0.5-2.9,1.8-3.5c2-1.3,4.1-2.7,6.1-4.1l2.4-1.6c-4.1-6.7-7-14.1-8.4-21.9c-1.1-6.8-1-13.7,0.5-20.4
       C3,14.2,4,10.4,5.1,6.7C5.5,5.1,5.9,3.6,6.4,2c0.1-0.3,0.2-0.5,0.3-0.8c0-0.1,0.1-0.2,0.2-0.4l0.5-1.4L26.8,7l-0.7,1.5
       c-5.8,12.1-5.8,24.3-0.2,37.3c2.7-3.9,5.4-7.7,8.1-11.6c0,0,0.1-0.1,0.1-0.1c0.2-0.3,0.4-0.7,0.9-1l0.2-0.1
       c0.6-0.4,1.4-0.9,2.3-0.7l0.3,0.1c0.9,0.4,1.6,1.2,1.9,2.1c0.9,3,1.8,6.1,2.7,9l11.7,39.8c0.1,0.3,0.2,0.7,0.3,1.1l-1.4,0.4
       L52.7,86.1z M2.7,69c0.1,0.1,0.4,0.1,0.5,0.2c14.6,4.1,29.2,8.2,43.9,12.3c1.3,0.4,2.5,0.7,3.9,1.1L39.6,44.2
       c-0.9-2.9-1.7-5.9-2.7-8.9c0,0-0.1,0-0.1,0.1l-0.1,0.1c0,0,0,0,0,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c-2.9,4.2-5.8,8.4-8.7,12.6
       c-0.2,0.3-0.4,0.5-0.7,0.9l-1.7,2.2l-1-2.2c-6.8-14.2-7.4-27.5-1.5-40.7L9.1,3.3C8.7,4.7,8.3,6.1,7.9,7.5c-1,3.6-2.1,7.4-2.9,11.1
       C3.7,25,3.5,31.5,4.6,37.9c1.3,7.5,4,14.5,8,21c0.2,0.3,0.3,0.5,0.5,0.8l0.9,1.6l-4.7,3.1c-2.1,1.4-4.1,2.7-6.2,4.1l-0.1,0.1
       C2.8,68.7,2.7,68.8,2.7,69z M36.6,35.5L36.6,35.5C36.6,35.5,36.6,35.5,36.6,35.5z M37.3,35.1L37.3,35.1L37.3,35.1z"
          />
        </g>
        <g
          id="Gruppe_2445_00000165957504906582442650000014937564848682439351_"
          transform="translate(94.575 71.718)"
        >
          <path
            fill={isWhite ? "white" : "#554b6d"}
            d="M2.3,83.3l-0.2-1.6C1.5,77.8,0.9,74,0.3,70.1l-1-7.1l1.6-0.2c13.3-1.4,23.8-7.4,31.9-18.4
       c-1-0.1-2-0.2-2.9-0.3c-1.7-0.2-3.4-0.4-5.1-0.6c-0.6-0.1-1.3-0.1-1.9-0.2c-1.6-0.2-3.3-0.3-5-0.6l-0.1,0c-1.1-0.3-2-1-2.5-1.9
       c-0.7-1.6,0.9-3.2,1-3.3c11-11.5,22.2-23.3,33.4-35c0.4-0.5,0.9-0.9,1.4-1.4c0.3-0.3,0.6-0.6,1-0.9l1.8-1.8l0.7,2.5
       c0.9,3.5,1.8,6.8,2.7,10.1l10.5,40c0.1,0.2,0.1,0.5,0.1,0.8l0,0.1l0,0.1c0,0.2,0,0.3-0.1,0.5c-0.1,0.7-0.2,1.7-0.9,2.3l-0.2,0.2
       l-0.2,0.1c-1,0.5-2.2,0.5-3.2,0c-2-0.9-4.1-1.9-6.1-2.9l-3.3-1.6c-0.1,0-0.1-0.1-0.2-0.1c-0.6,1-1.2,2-1.8,3
       c-8.5,13.7-21,22.8-37.4,26.8c-2.2,0.5-4.4,1-6.6,1.6c-1.3,0.3-2.6,0.6-3.9,0.9L2.3,83.3z M2.7,65.6l0.6,4c0.5,3.3,1,6.6,1.5,10
       c0.8-0.2,1.6-0.4,2.4-0.5c2.2-0.5,4.4-1,6.6-1.5c15.6-3.9,27.6-12.5,35.6-25.5c0.6-1,1.2-2,1.8-3l1.4-2.3l1.2,0.6
       c0.5,0.2,0.9,0.4,1.4,0.7l3.3,1.6c2,1,4.1,2,6.1,2.9c0.1,0,0.2,0.1,0.2,0.1c0-0.1,0-0.2,0-0.2c0-0.2,0-0.3,0-0.5l0,0L54.3,11.8
       c-0.7-2.5-1.3-5-2-7.6c-0.2,0.1-0.3,0.3-0.4,0.5c-11.2,11.7-22.4,23.5-33.4,35c0,0-0.1,0.1-0.1,0.1c0.1,0,0.1,0.1,0.2,0.1
       c1.6,0.2,3.2,0.4,4.7,0.6c0.6,0.1,1.3,0.1,1.9,0.2c1.7,0.2,3.4,0.4,5.1,0.6c1.8,0.2,3.6,0.4,5.4,0.6l2.5,0.3l-1.4,2.1
       C28.2,56.7,17,63.8,2.7,65.6z M64.6,53L64.6,53L64.6,53z"
          />
        </g>
      </g>
    </svg>
  )
}

const EmailIcon = ({
  className,
  isWhite = false,
}: classNameProps): JSX.Element => {
  return (
    <svg
      className={className}
      id="email"
      xmlns="http://www.w3.org/2000/svg"
      width="23.174"
      height="15.688"
      viewBox="0 0 23.174 15.688"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_347"
            data-name="Rechteck 347"
            width="23.174"
            height="15.688"
            fill={isWhite ? "white" : "#554b6d"}
          />
        </clipPath>
      </defs>
      <g id="Gruppe_2581" data-name="Gruppe 2581">
        <path
          id="Pfad_7984"
          data-name="Pfad 7984"
          d="M22.819.44c-.02-.023-.036-.048-.058-.069l0,0L22.753.363A1.342,1.342,0,0,0,21.831,0H1.343A1.342,1.342,0,0,0,.422.363L.417.369l0,0C.391.392.375.418.355.44L.34.457A1.34,1.34,0,0,0,0,1.342v13a1.341,1.341,0,0,0,.338.878.583.583,0,0,0,.1.116,1.172,1.172,0,0,0,.179.134.968.968,0,0,0,.226.119l.014.005a1.159,1.159,0,0,0,.236.067l.015,0a1.214,1.214,0,0,0,.236.022H21.831a1.238,1.238,0,0,0,.234-.021l.016,0a1.159,1.159,0,0,0,.236-.067l.014-.005a.912.912,0,0,0,.21-.108l.023-.015a1.172,1.172,0,0,0,.173-.131l.006-.007.018-.016c.014-.014.024-.031.038-.045s.019-.021.028-.033a1.341,1.341,0,0,0,.347-.893v-13a1.34,1.34,0,0,0-.34-.885L22.819.44m-1.809.737L11.9,9.109a.484.484,0,0,1-.627,0L2.164,1.177ZM1.178,13.8V1.882L8.023,7.842Zm10.417.705H2.162L8.92,8.624,10.5,10a1.639,1.639,0,0,0,.245.175c.011.006.023.01.035.016a1.311,1.311,0,0,0,.433.162c.027.006.054.017.081.022A1.666,1.666,0,0,0,12.673,10l1.581-1.376,6.756,5.884ZM22,13.8,15.151,7.842,22,1.882Z"
          transform="translate(0 0.001)"
          fill={isWhite ? "white" : "#554b6d"}
        />
      </g>
    </svg>
  )
}

const PhoneIcon = ({
  className,
  isWhite = false,
}: classNameProps): JSX.Element => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="21.129"
      height="21.533"
      viewBox="0 0 21.129 21.533"
    >
      <path
        id="phone"
        d="M20.738,15.576l-.034-.047c-.146-.185-.534-.676-5.382-1.8-.692-.122-1.38.244-2.1,1.122a2.76,2.76,0,0,1-.286.309c-.5.461-.694.486-1.213.21-1.77-.942-4.884-4.829-5.641-6.062-.175-.418-.036-.771.417-1.1a2.64,2.64,0,0,0,1.4-2.676L7.188,2.911l-.55-2C6.543.572,6.2,0,4.974,0a5.381,5.381,0,0,0-2.682.76c-3.861,2.7-2.225,8.153.368,12.034,4.022,5.346,8.04,8.248,11.944,8.625a7.81,7.81,0,0,0,1.33.114,5.658,5.658,0,0,0,4.233-1.607A3.88,3.88,0,0,0,21.1,16.8a2.791,2.791,0,0,0-.364-1.225m-1.459,3.548a4.483,4.483,0,0,1-3.346,1.213,6.611,6.611,0,0,1-1.146-.1l-.048-.007c-3.562-.334-7.3-3.078-11.1-8.128C1.362,8.7-.175,3.94,2.977,1.739a4.216,4.216,0,0,1,2-.545,1.264,1.264,0,0,1,.522.078L6.732,5.745c.051.382-.018.889-.907,1.479A2.055,2.055,0,0,0,5,9.811l.053.1c.715,1.192,4.02,5.4,6.111,6.513a2.269,2.269,0,0,0,1.084.307,2.189,2.189,0,0,0,1.5-.692,3.919,3.919,0,0,0,.407-.439c.529-.649.831-.7.911-.7.014,0,.029,0,.014,0a20.521,20.521,0,0,1,4.684,1.389,2.03,2.03,0,0,1,.144.633,2.7,2.7,0,0,1-.633,2.2"
        transform="translate(0 0.001)"
        fill={isWhite ? "white" : "#554b6d"}
      />
    </svg>
  )
}

const WhatsappIcon = ({
  className,
  isWhite = false,
}: classNameProps): JSX.Element => {
  return (
    <svg
      className={className}
      id="whatsapp"
      xmlns="http://www.w3.org/2000/svg"
      width="22.644"
      height="22.758"
      viewBox="0 0 22.644 22.758"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_345"
            data-name="Rechteck 345"
            width="22.644"
            height="22.758"
            fill={isWhite ? "white" : "#554b6d"}
          />
        </clipPath>
      </defs>
      <g id="Gruppe_2559" data-name="Gruppe 2559">
        <path
          id="Pfad_7675"
          data-name="Pfad 7675"
          d="M19.344,3.311A11.169,11.169,0,0,0,11.367,0,11.284,11.284,0,0,0,1.593,16.909L0,22.758l5.974-1.57A11.278,11.278,0,0,0,19.344,3.311M11.367,20.652h0a9.4,9.4,0,0,1-4.779-1.309l-.341-.2-3.55.933.944-3.459-.228-.353A9.377,9.377,0,0,1,11.356,1.9a9.382,9.382,0,0,1,9.365,9.387,9.348,9.348,0,0,1-9.353,9.365m5.143-7.009c-.284-.137-1.673-.819-1.923-.922s-.444-.137-.637.137-.728.922-.888,1.1-.33.216-.614.068a7.582,7.582,0,0,1-2.264-1.4,8.61,8.61,0,0,1-1.57-1.957c-.159-.284-.023-.432.125-.58.125-.125.284-.33.421-.489a1.972,1.972,0,0,0,.284-.467.522.522,0,0,0-.023-.489c-.068-.137-.637-1.525-.865-2.094-.228-.546-.467-.478-.637-.489H7.373a1.022,1.022,0,0,0-.751.353,3.21,3.21,0,0,0-.979,2.355,5.481,5.481,0,0,0,1.149,2.913,12.491,12.491,0,0,0,4.813,4.256,13.771,13.771,0,0,0,1.6.592,3.9,3.9,0,0,0,1.775.114,2.865,2.865,0,0,0,1.9-1.343,2.224,2.224,0,0,0,.159-1.343c-.08-.125-.25-.182-.535-.319"
          fill={isWhite ? "white" : "#554b6d"}
        />
      </g>
    </svg>
  )
}

const CheckListIcon = ({
  className,
  isWhite = false,
}: classNameProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.333"
      height="28.706"
      viewBox="0 0 21.333 28.706"
      className={className}
      id="checklist-icon"
    >
      <path
        id="Pfad_7977"
        data-name="Pfad 7977"
        d="M405.876,225.067v19.869a3.183,3.183,0,0,1-.982,2.294,3.357,3.357,0,0,1-2.335.936h-14.7a3.358,3.358,0,0,1-2.337-.936,3.184,3.184,0,0,1-.981-2.294V225.067a3.183,3.183,0,0,1,.981-2.294,3.358,3.358,0,0,1,2.337-.937h2.176v1.542h-2.176a1.813,1.813,0,0,0-1.266.5,1.639,1.639,0,0,0-.511,1.185v19.869a1.639,1.639,0,0,0,.511,1.185,1.813,1.813,0,0,0,1.266.5h14.7a1.813,1.813,0,0,0,1.266-.5,1.638,1.638,0,0,0,.511-1.185V225.067a1.638,1.638,0,0,0-.511-1.185,1.813,1.813,0,0,0-1.266-.5h-2.176v-1.542h2.176a3.356,3.356,0,0,1,2.336.937A3.182,3.182,0,0,1,405.876,225.067Zm-6.495-3.253v2.871a.326.326,0,0,1-.325.325h-7.694a.326.326,0,0,1-.325-.325v-2.871a.326.326,0,0,1,.325-.325h1.9c0-.026,0-.051,0-.077a1.951,1.951,0,0,1,3.9,0c0,.026,0,.052,0,.077h1.9A.326.326,0,0,1,399.381,221.814Zm-3.348-.4a.823.823,0,1,0-.823.823A.824.824,0,0,0,396.033,221.411Zm-7.666,19.838a.149.149,0,0,1-.009-.21l.667-.723a.149.149,0,0,1,.21-.009l1.158,1.069a.139.139,0,0,0,.2-.014l2.242-2.738a.149.149,0,0,1,.209-.021l.761.624a.149.149,0,0,1,.021.209l-3.1,3.79a.139.139,0,0,1-.2.014Zm13.733-.544h-7.777v1.17H402.1Zm-13.733-5.176a.15.15,0,0,1-.009-.21l.667-.723a.149.149,0,0,1,.21-.008l1.158,1.069a.139.139,0,0,0,.2-.014l2.242-2.738a.149.149,0,0,1,.209-.021l.761.624a.149.149,0,0,1,.021.209l-3.1,3.79a.139.139,0,0,1-.2.014Zm13.733-.544h-7.777v1.17H402.1Zm-13.733-5.176a.149.149,0,0,1-.009-.21l.667-.723a.149.149,0,0,1,.21-.008l1.158,1.069a.139.139,0,0,0,.2-.014l2.242-2.738a.149.149,0,0,1,.209-.021l.761.624a.149.149,0,0,1,.021.209l-3.1,3.79a.139.139,0,0,1-.2.014Zm13.733-.544h-7.777v1.17H402.1Z"
        transform="translate(-384.543 -219.46)"
        fill={isWhite ? "white" : "#554b6d"}
      />
    </svg>
  )
}

const HutIcon = ({
  className,
  isWhite = false,
}: classNameProps): JSX.Element => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="21.8"
      viewBox="0 0 32 21.8"
    >
      <g
        id="Gruppe_2575"
        data-name="Gruppe 2575"
        transform="translate(-24 -3079.225)"
      >
        <g
          id="Gruppe_2574"
          data-name="Gruppe 2574"
          transform="translate(24 3079.225)"
        >
          <g
            id="Gruppe_1970"
            data-name="Gruppe 1970"
            transform="translate(6.418 9.988)"
          >
            <g id="Gruppe_1968" data-name="Gruppe 1968">
              <path
                id="Pfad_7286"
                data-name="Pfad 7286"
                d="M727.82,297.071a14.251,14.251,0,0,1-9.43-3.554.451.451,0,0,1-.152-.337v-5.84c.326.158.592.337.872.491l.026,5.167a13.423,13.423,0,0,0,17.383-.039L736.5,287.9c.312-.126.579-.215.827-.327l.072,5.612a.448.448,0,0,1-.152.337A14.247,14.247,0,0,1,727.82,297.071Z"
                transform="translate(-718.238 -287.34)"
                fill={isWhite ? "white" : "#554b6d"}
              />
            </g>
            <g
              id="Gruppe_1969"
              data-name="Gruppe 1969"
              transform="translate(18.714 5.789)"
            >
              <path
                id="Pfad_7287"
                data-name="Pfad 7287"
                d="M915.783,348.554l0-.1Z"
                transform="translate(-915.783 -348.453)"
                fill={isWhite ? "white" : "#554b6d"}
              />
            </g>
          </g>
          <g
            id="Gruppe_1971"
            data-name="Gruppe 1971"
            transform="translate(0 0)"
          >
            <path
              id="Pfad_7288"
              data-name="Pfad 7288"
              d="M666.484,196.753a.459.459,0,0,1-.184-.039l-15.551-6.978a.45.45,0,0,1,0-.82l15.551-6.977a.446.446,0,0,1,.368,0l15.55,6.977a.449.449,0,0,1,0,.82l-15.55,6.977A.453.453,0,0,1,666.484,196.753Zm-14.556-7.381,14.577,6.448,14.535-6.541-14.556-6.438Z"
              transform="translate(-650.484 -181.899)"
              fill={isWhite ? "white" : "#554b6d"}
            />
          </g>
          <g
            id="Gruppe_1972"
            data-name="Gruppe 1972"
            transform="translate(1.382 7.615)"
          >
            <path
              id="Pfad_7289"
              data-name="Pfad 7289"
              d="M665.523,269.956a.45.45,0,0,1-.449-.45v-6.771a.449.449,0,1,1,.9,0v6.771A.45.45,0,0,1,665.523,269.956Z"
              transform="translate(-665.074 -262.286)"
              fill={isWhite ? "white" : "#554b6d"}
            />
          </g>
          <g
            id="Gruppe_1973"
            data-name="Gruppe 1973"
            transform="translate(0.039 14.387)"
          >
            <path
              id="Pfad_7290"
              data-name="Pfad 7290"
              d="M652.685,337.353a1.793,1.793,0,1,1,1.793-1.793A1.795,1.795,0,0,1,652.685,337.353Zm0-2.687a.894.894,0,1,0,.894.894A.895.895,0,0,0,652.685,334.667Z"
              transform="translate(-650.893 -333.768)"
              fill={isWhite ? "white" : "#554b6d"}
            />
          </g>
          <g
            id="Gruppe_1974"
            data-name="Gruppe 1974"
            transform="translate(0.038 17.073)"
          >
            <path
              id="Pfad_7291"
              data-name="Pfad 7291"
              d="M651.338,366.856a.449.449,0,0,1-.441-.535l.745-3.828a.45.45,0,0,1,.441-.363.49.49,0,0,1,.374.2.451.451,0,0,1,.069.337l-.653,3.354,1.669-.061-.7-3.293a.452.452,0,0,1,.069-.337.447.447,0,0,1,.288-.19h.023a.463.463,0,0,1,.5.355l.746,3.828a.449.449,0,0,1-.441.535Z"
              transform="translate(-650.888 -362.13)"
              fill={isWhite ? "white" : "#554b6d"}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

const BfIcon = ({
  className,
  isWhite = false,
}: classNameProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="26"
      viewBox="0 0 65 26"
      className={className}
    >
      <text
        id="BF17"
        transform="translate(1 20)"
        fill="none"
        stroke="#28044a"
        strokeWidth="1"
        fontSize="19"
        fontFamily="GillSans-UltraBold, Gill Sans"
        fontWeight="700"
      >
        <tspan x="0" y="0">
          BF17
        </tspan>
      </text>
    </svg>
  )
}

const StopIcon = ({
  className,
  isWhite = false,
}: classNameProps): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.51 87.51">
      <g id="a" />
      <g id="b">
        <g id="c">
          <g>
            <path
              d="M28.46,87.51c-.79,0-1.54-.14-2.22-.42-.69-.28-1.34-.71-1.92-1.28L2.05,63.92c-.58-.57-1.03-1.21-1.32-1.9-.29-.69-.45-1.45-.45-2.26L0,28.53c0-.82,.13-1.58,.42-2.28,.28-.69,.71-1.33,1.28-1.92L23.59,2.05c.57-.58,1.21-1.02,1.9-1.32,.69-.29,1.46-.45,2.27-.45L58.99,0c.82-.03,1.58,.13,2.28,.42,.69,.28,1.33,.71,1.92,1.28l22.28,21.89c.58,.57,1.03,1.21,1.32,1.9,.29,.7,.45,1.46,.45,2.27l.27,31.23c0,.81-.13,1.58-.42,2.28-.28,.69-.71,1.34-1.28,1.92l-21.89,22.28c-.57,.58-1.21,1.02-1.9,1.32-.69,.29-1.46,.45-2.26,.45l-31.23,.27s-.04,0-.06,0ZM59.05,2.13h-.04l-31.23,.27c-.53,0-1.02,.1-1.45,.29-.43,.18-.84,.47-1.22,.85L3.21,25.82c-.38,.38-.65,.79-.83,1.22-.18,.44-.27,.94-.26,1.47l.27,31.23c0,.53,.1,1.02,.28,1.45,.18,.43,.47,.84,.85,1.22l22.28,21.89c.38,.38,.79,.65,1.22,.83,.44,.18,.94,.27,1.47,.26l31.23-.27c.53,0,1.02-.1,1.45-.29,.43-.18,.84-.47,1.22-.85l21.89-22.28c.38-.38,.65-.79,.83-1.22,.18-.44,.27-.94,.26-1.47l-.27-31.23c0-.53-.1-1.02-.29-1.45-.18-.43-.47-.84-.85-1.22L61.7,3.22c-.38-.38-.79-.65-1.22-.83-.43-.17-.91-.26-1.43-.26Zm-.05-1.06h0Z"
              fill="#554b6d"
            />
            <path
              d="M28.47,82.66c-.28,0-.55-.11-.75-.31L5.45,60.46c-.2-.2-.32-.47-.32-.75l-.27-31.23c0-.28,.11-.55,.3-.75L27.05,5.45c.2-.2,.47-.32,.75-.32l31.23-.27c.31,0,.55,.11,.75,.3l22.28,21.89c.2,.2,.32,.47,.32,.75l.27,31.23c0,.28-.11,.55-.3,.75l-21.89,22.28c-.2,.2-.47,.32-.75,.32l-31.23,.27h0ZM7.25,59.25l21.65,21.27,30.35-.27,21.27-21.65-.27-30.35L58.61,6.99l-30.35,.27L6.99,28.9l.27,30.35Z"
              fill="#554b6d"
            />
            <path
              d="M19.04,57h-3.04c-3.94,0-7.15-3.21-7.15-7.15,0-.59,.48-1.06,1.06-1.06s1.06,.48,1.06,1.06c0,2.77,2.25,5.03,5.03,5.03h3.04c2.77,0,5.03-2.25,5.03-5.03s-2.25-5.03-5.03-5.03h-3.04c-.59,0-1.06-.48-1.06-1.06s.48-1.06,1.06-1.06h3.04c3.94,0,7.15,3.21,7.15,7.15s-3.21,7.15-7.15,7.15Z"
              fill="#554b6d"
            />
            <path
              d="M19.04,44.82h-3.04c-3.94,0-7.15-3.21-7.15-7.15s3.21-7.15,7.15-7.15h3.04c3.94,0,7.15,3.21,7.15,7.15,0,.59-.48,1.06-1.06,1.06s-1.06-.48-1.06-1.06c0-2.77-2.25-5.03-5.03-5.03h-3.04c-2.77,0-5.03,2.25-5.03,5.03s2.25,5.03,5.03,5.03h3.04c.59,0,1.06,.48,1.06,1.06s-.48,1.06-1.06,1.06Z"
              fill="#554b6d"
            />
            <path
              d="M33.9,57c-.59,0-1.06-.48-1.06-1.06V31.58c0-.59,.48-1.06,1.06-1.06s1.06,.48,1.06,1.06v24.36c0,.59-.48,1.06-1.06,1.06Z"
              fill="#554b6d"
            />
            <path
              d="M41.51,32.64h-15.22c-.59,0-1.06-.48-1.06-1.06s.48-1.06,1.06-1.06h15.22c.59,0,1.06,.48,1.06,1.06s-.48,1.06-1.06,1.06Z"
              fill="#554b6d"
            />
            <path
              d="M50.97,57c-4.78,0-8.67-3.89-8.67-8.67v-9.13c0-4.78,3.89-8.67,8.67-8.67s8.67,3.89,8.67,8.67v9.13c0,4.78-3.89,8.67-8.67,8.67Zm0-24.36c-3.61,0-6.55,2.94-6.55,6.55v9.13c0,3.61,2.94,6.55,6.55,6.55s6.55-2.94,6.55-6.55v-9.13c0-3.61-2.94-6.55-6.55-6.55Z"
              fill="#554b6d"
            />
            <path
              d="M62.38,57c-.59,0-1.06-.48-1.06-1.06V31.58c0-.59,.48-1.06,1.06-1.06h9.13c3.94,0,7.15,3.21,7.15,7.15s-3.21,7.15-7.15,7.15h-8.07v11.12c0,.59-.48,1.06-1.06,1.06Zm1.06-14.31h8.07c2.77,0,5.03-2.25,5.03-5.03s-2.25-5.03-5.03-5.03h-8.07v10.05Z"
              fill="#554b6d"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

const VorsichtIcon = ({
  className,
  isWhite = false,
}: classNameProps): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93.55 86.43">
      <g id="a" />
      <g id="b">
        <g id="c">
          <g>
            <path
              d="M50.65,74.97h-7.68c-.58,0-1.06-.47-1.06-1.05l-.07-9.31c0-.28,.11-.56,.31-.76s.47-.31,.75-.31h7.81c.28,0,.55,.11,.75,.31,.2,.2,.31,.47,.31,.76l-.06,9.31c0,.58-.48,1.06-1.06,1.06Zm-6.62-2.13h5.57l.04-7.18h-5.67l.05,7.18Z"
              fill="#554b6d"
            />
            <path
              d="M48.88,62.47h-4.2c-.55,0-1.02-.43-1.06-.98l-1.97-24.73-.08-11.51c0-.28,.11-.56,.31-.76,.2-.2,.47-.31,.75-.31h8.29c.28,0,.55,.11,.75,.31,.2,.2,.31,.47,.31,.75l-.07,11.43-1.97,24.81c-.04,.55-.51,.98-1.06,.98Zm-3.22-2.13h2.24l1.89-23.75,.06-10.28h-6.15l.07,10.36,1.89,23.67Z"
              fill="#554b6d"
            />
            <path
              d="M85.22,80.81H8.33c-1.04,0-1.77-.42-2.3-1.31-.53-.89-.54-1.73-.04-2.64L44.44,6.98c.51-.93,1.27-1.38,2.34-1.38s1.83,.45,2.34,1.38l38.44,69.88c.5,.91,.49,1.75-.04,2.64-.53,.89-1.26,1.31-2.3,1.31ZM46.78,7.72c-.29,0-.33,.03-.47,.28L7.86,77.88h0c-.14,.25-.14,.29,0,.54,.15,.25,.18,.27,.47,.27H85.22c.29,0,.32-.02,.47-.27,.15-.25,.15-.29,0-.54L47.25,8.01c-.14-.26-.18-.28-.48-.28ZM6.93,77.37h0Z"
              fill="#554b6d"
            />
            <path
              d="M85.22,86.43H8.33c-1.51,0-2.9-.36-4.13-1.05-1.23-.7-2.24-1.71-3.01-3.01C.42,81.06,.02,79.69,0,78.28c-.02-1.42,.34-2.81,1.07-4.14L39.51,4.27h0c.74-1.34,1.78-2.43,3.02-3.17,2.48-1.46,6-1.46,8.48,0,1.24,.73,2.29,1.83,3.03,3.17l38.45,69.88c.73,1.33,1.09,2.72,1.07,4.14-.02,1.41-.42,2.78-1.19,4.08-.77,1.3-1.78,2.31-3.01,3.01-1.23,.7-2.62,1.06-4.13,1.06ZM41.38,5.29L2.93,75.17c-.55,1-.82,2.03-.8,3.08,.02,1.03,.32,2.05,.9,3.03,.58,.98,1.33,1.73,2.23,2.25,.9,.51,1.94,.77,3.08,.77H85.22c1.14,0,2.18-.26,3.08-.77,.9-.51,1.65-1.27,2.23-2.25,.58-.98,.88-2,.9-3.03,.02-1.04-.25-2.08-.8-3.08L52.18,5.29c-.56-1.02-1.32-1.82-2.24-2.36-.92-.55-1.99-.82-3.16-.82s-2.23,.28-3.16,.82c-.93,.55-1.68,1.34-2.24,2.36h0Z"
              fill="#554b6d"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

const UturnIcon = ({
  className,
  isWhite = false,
}: classNameProps): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.37 97.37">
      <g id="a" />
      <g id="b">
        <g id="c">
          <g>
            <path
              d="M36.74,74.35c-.44,0-.85-.17-1.15-.48l-15.88-15.88c-.63-.64-.63-1.67,0-2.31l6.41-6.41c.62-.62,1.69-.61,2.31,0l2.15,2.15v-9.58c0-10.38,8.44-18.82,18.82-18.82h.58c10.38,0,18.82,8.44,18.82,18.82v29c0,.9-.73,1.63-1.63,1.63h-9.07c-.9,0-1.63-.73-1.63-1.63v-29c0-3.58-2.91-6.49-6.49-6.49h-.58c-3.58,0-6.49,2.91-6.49,6.49v9.58l2.15-2.15c.64-.64,1.67-.63,2.31,0l6.41,6.41c.63,.64,.63,1.67,0,2.31l-15.88,15.88c-.31,.31-.72,.48-1.15,.48Zm-.35-1.98h0Zm.7,0h0Zm-15.53-15.53l15.18,15.18,15.18-15.18-5.71-5.71-3.61,3.61c-.31,.3-.76,.39-1.16,.23-.4-.17-.66-.55-.66-.98v-12.14c0-4.75,3.86-8.61,8.61-8.61h.58c4.75,0,8.61,3.86,8.61,8.61v28.5h8.08v-28.5c0-9.2-7.49-16.69-16.69-16.69h-.58c-9.2,0-16.69,7.49-16.69,16.69v12.14c0,.43-.26,.82-.66,.98-.4,.16-.85,.07-1.16-.23l-3.61-3.61-5.71,5.71Z"
              fill="#554b6d"
            />
            <path
              d="M48.68,97.37C21.84,97.37,0,75.53,0,48.68S21.84,0,48.68,0s48.68,21.84,48.68,48.68-21.84,48.68-48.68,48.68Zm0-95.24C23.01,2.13,2.13,23.01,2.13,48.68s20.89,46.56,46.56,46.56,46.56-20.89,46.56-46.56S74.36,2.13,48.68,2.13Z"
              fill="#554b6d"
            />
            <path
              d="M48.68,87.17c-21.22,0-38.48-17.26-38.48-38.48S27.47,10.2,48.68,10.2s38.48,17.26,38.48,38.48-17.26,38.48-38.48,38.48Zm0-74.83C28.64,12.33,12.33,28.64,12.33,48.68s16.31,36.35,36.35,36.35,36.35-16.31,36.35-36.35S68.73,12.33,48.68,12.33Z"
              fill="#554b6d"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

const ClockIcon = ({ className }: classNameProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.175"
      height="23.175"
      viewBox="0 0 23.175 23.175"
      className={className}
    >
      <g transform="translate(-3372.253 -585.75)">
        <path
          d="M3383.84,608.925a11.554,11.554,0,1,1,4.51-.911A11.515,11.515,0,0,1,3383.84,608.925Zm0-22.186a10.566,10.566,0,1,0,4.125.833A10.53,10.53,0,0,0,3383.84,586.738Z"
          transform="translate(0)"
        ></path>{" "}
        <circle
          cx="1.047"
          cy="1.047"
          r="1.047"
          transform="translate(3382.494 596.374) rotate(-4.421)"
        ></circle>{" "}
        <path
          d="M3864.3,1082.516h-5.7a.494.494,0,0,1,0-.988h5.7a.494.494,0,0,1,0,.988Z"
          transform="translate(-474.988 -484.685)"
        ></path>{" "}
        <path
          d="M3858.606,796.8a.494.494,0,0,1-.494-.494v-6.539a.494.494,0,1,1,.988,0V796.3A.494.494,0,0,1,3858.606,796.8Z"
          transform="translate(-474.988 -198.964)"
        ></path>
      </g>
    </svg>
  )
}

export {
  MofaIcon,
  AutoIcon,
  MotoIcon,
  TraktorIcon,
  KreiselIcon,
  EmailIcon,
  PhoneIcon,
  WhatsappIcon,
  CheckListIcon,
  HutIcon,
  BfIcon,
  StopIcon,
  VorsichtIcon,
  UturnIcon,
  ClockIcon,
}
