import React from "react"
import { PhoneIcon, EmailIcon, ClockIcon, WhatsappIcon } from "../shared/icons"
import { Link } from "gatsby"
import useSiteContactData from "../../helper/contactData"

const OpeningHours = (): JSX.Element => {
  const contactData = useSiteContactData()

  return (
    <div
      id="opening-hours"
      className="hidden w-full xl:flex flex-no-wrap py-4 justify-end mx-4 entered "
    >
      <div className="vl px-3 lg:px-6 xll:px-8">
        <span className="text-bold block m-bold uppercase text-xs">
          Kontakt
        </span>{" "}
        <ul className="block mt-2">
          <li className="flex items-center">
            <PhoneIcon className="w-4" />
            <span className="ml-4">
              {" "}
              <a href={`tel:${contactData.telefon}`}>{contactData.telefon}</a>
            </span>
          </li>{" "}
          <li className="flex items-center relative">
            <EmailIcon className="w-4" />
            <span className="ml-4">
              <a href={`mailto:${contactData.email}`}>E-Mail</a>
            </span>
          </li>
          <li className="flex items-center relative">
            <WhatsappIcon className="w-4" />
            <span className="ml-4">
              <a href={`https://wa.me/${contactData.whatsapp}`}>WhatsApp</a>
            </span>
          </li>
        </ul>
      </div>{" "}
      <div className="vl px-3 lg:px-6 xll:px-8">
        <span className="text-bold block text-xs uppercase">
          {contactData.ORT1}
        </span>{" "}
        <ul className="block mt-2">
          <li className="flex items-center">
            <ClockIcon className="w-4 fill-secondary" />
            <span className="ml-4"> Öffnungszeiten: </span>
          </li>{" "}
          <li className="flex items-center relative">
            <div className="w-4"></div> <span className="ml-4">Di und Do</span>
          </li>{" "}
          <li className="flex items-center relative">
            <div className="w-4"></div>{" "}
            <span className="ml-4">17:00 Uhr bis 20:30 Uhr</span>
          </li>{" "}
          {/* <li className="flex items-center relative">
            <div className="w-4"></div>{" "}
            <span className="ml-4">{contactData.OPENING1B}</span>
          </li>{" "} */}
          {/* <li className="flex items-center relative">
            <div className="w-4"></div>{" "}
            <span className="ml-4">{contactData.STRASSE1}</span>
          </li>{" "} */}
          {/* <li className="flex items-center relative sm-mt">
            <div className="w-4"></div>{" "}
            <span className="ml-4">
              {contactData.PLZ1} {contactData.ORT1}
            </span>
          </li> */}
        </ul>
      </div>{" "}
      <div className="vl px-3 lg:px-6 xll:px-8">
        <span className="text-bold block m-bold uppercase text-xs">
          {contactData.ORT2}
        </span>{" "}
        <ul className="block mt-2">
          <li className="flex items-center">
            <ClockIcon className="w-4 fill-secondary" />
            <span className="ml-4"> Öffnungszeiten: </span>
          </li>{" "}
          <li className="flex items-center relative">
            <div className="w-4"></div> <span className="ml-4">Mo und Mi</span>
          </li>{" "}
          <li className="flex items-center relative">
            <div className="w-4"></div>{" "}
            <span className="ml-4">17:00 Uhr bis 20:30 Uhr</span>
          </li>{" "}
          {/* <li className="flex items-center relative">
            <div className="w-4"></div>{" "}
            <span className="ml-4"> {contactData.STRASSE2}</span>
          </li>{" "} */}
          {/* <li className="flex items-center relative sm-mt">
            <div className="w-4"></div>{" "}
            <span className="ml-4">
              {contactData.PLZ2} {contactData.ORT2}
            </span>
          </li> */}
        </ul>
      </div>
    </div>
  )
}

export default OpeningHours
