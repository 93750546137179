import React, { Children, useState } from "react"
import Checkbox from "./Checkbox"
import DropArrow from "./DropArrow"
import ListArrow from "./ListArrow"
import Thema from "./Thema"

type DropDownProps = {
  isList: boolean
  setIsList: (isList: boolean) => void
  isSubList: {
    [key: string]: boolean
  }
  setIsSubList: (isSubList: { [key: string]: boolean }) => void
  checkedBoxes: {
    [key: string]: {
      [key: string]: boolean
    }
  }
  handleClick: (
    status: boolean,
    kategorie: string,
    thema: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void
  isCheckedAllgemein: boolean
  isCheckedB: boolean
  isCheckedA: boolean
}

const DropDown = ({
  isList,
  setIsList,
  isSubList,
  setIsSubList,
  checkedBoxes,
  handleClick,
  isCheckedAllgemein,
  isCheckedB,
  isCheckedA,
}: DropDownProps): JSX.Element => {
  // Topics Object for Loop Rendering the Dropdpwn fields
  interface TopicsTypes {
    [key: string]: {
      [key: string]: string
    }
  }

  const topics: TopicsTypes = {
    ["allgemeine"]: {
      "Thema 1": "1. Persönliche Voraussetzungen",
      "Thema 2": "2. Risikofaktor Mensch",
      "Thema 3": "3. Rechtliche Rahmenbedingungen",
      "Thema 4": "4. Straßenverkehrssystem",
      "Thema 5": "5. Grundregel, Vorfahrt/Verkehrsregelungen",
      "Thema 6": "6. Verkehrszeichen und Verkehrseinrichtungen",
      "Thema 7":
        "7. Teilnehmer am Straßenverkehr, Besonderheiten und Verhalten",
      "Thema 8": "8. Geschwindigkeit, Abstand und Bahnübergang",
      "Thema 9":
        "9. Verkehrsbeobachtung und Verkehrsverhalten bei Fahrmanövern",
      "Thema 10": "10. Ruhender Verkehr",
      "Thema 11":
        "11. Verhalten in besonderen Situationen, Folgen von Verstößen gegen",
      "Thema 12": "12. Sicherheit durch Weiterlernen",
    },
    ["klasseB"]: {
      "Thema 13":
        "13. Technische Bedingungen, Umweltbewusster Umgang mit Kraftfahrzeugen",
      "Thema 14": "14. Fahren mit Solokraftfahrzeugen und Zügen",
    },
    ["klasseA"]: {
      "Thema 15": "15. Fahrer/Beifahrer, Fahrzeug",
      "Thema 16": "16. Besonderes Verhalten beim Motorradfahren",
      "Thema 17": "17. Besondere Schwierigkeiten und Gefahren",
      "Thema 18": "18. Fahrtechnik und Fahrphysik",
    },
  }

  // Loop für creating the Dropdown Sublist Fields
  const getTopics = (kategorie: string): React.ReactNode => {
    // Store all Fields
    const themes = []
    // Modifier for different Categories of Topics
    const modifier = {
      startNumber: 0,
    }

    if (kategorie == "allgemeine") {
      modifier.startNumber = 1
    }

    if (kategorie == "klasseB") {
      modifier.startNumber = 13
    }

    if (kategorie == "klasseA") {
      modifier.startNumber = 15
    }

    // Loop
    for (let i = 0; i < Object.entries(topics[kategorie]).length; i++) {
      themes.push(
        <Thema
          thema={
            topics[kategorie][`Thema ${(modifier.startNumber + i).toString()}`]
          }
        >
          <Checkbox>
            <input
              key={Math.random()}
              type="checkbox"
              className={`checkbox opacity-0 absolute cursor-pointer w-full h-full checked`}
              checked={
                checkedBoxes[kategorie][`Thema ${i + modifier.startNumber}`]
              }
              onChange={(e) => {
                handleClick(
                  !checkedBoxes[kategorie][`Thema ${i + modifier.startNumber}`],
                  kategorie,
                  `Thema ${i + modifier.startNumber}`,
                  e
                )
              }}
            />
          </Checkbox>
        </Thema>
      )
    }

    return themes
  }

  return (
    <div className="af-dropdown w-full xs:w-fit mt-6 xs:mt-0">
      <div
        onClick={() => setIsList(!isList)}
        className=" w-full xs:w-64 p-4 shadow rounded bg-white  font-medium leading-none text-gray-800 flex items-center justify-between cursor-pointer"
      >
        Themen
        <DropArrow isList={isList} />
      </div>
      {/* Dropdown Liste */}
      {isList && (
        <div className="w-fit mt-2 p-6 bg-white shadow rounded absolute z-10 h-fit ">
          {/* Thema */}
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              {/* Pfeil  */}
              <ListArrow
                isOpened={isSubList.sublist1}
                onClick={() =>
                  setIsSubList({ ...isSubList, sublist1: !isSubList.sublist1 })
                }
              />
              <div className="pl-4 flex items-center">
                <Checkbox>
                  <input
                    checked={isCheckedAllgemein}
                    id="check-allgemein"
                    type="checkbox"
                    className="checkbox opacity-0 absolute cursor-pointer w-full h-full"
                    onChange={(e) => {
                      handleClick(!isCheckedAllgemein, "", "", e)
                    }}
                  />
                </Checkbox>
                <p
                  className=" leading-normal ml-4 text-gray-800 cursor-pointer"
                  onClick={() =>
                    setIsSubList({
                      ...isSubList,
                      sublist1: !isSubList.sublist1,
                    })
                  }
                >
                  Allgemeine Grundthemen
                </p>
              </div>
            </div>
          </div>
          {/* Subliste Allgemeine Themen*/}
          {isSubList.sublist1 && (
            <div className="pl-8">
              <div className="pl-8">{getTopics("allgemeine")}</div>
            </div>
          )}
          {/* Klasse B */}
          <div>
            <div className="flex items-center justify-between mt-6">
              <div className="flex items-center">
                {/* Pfeil  */}
                <ListArrow
                  isOpened={isSubList.sublist2}
                  onClick={() =>
                    setIsSubList({
                      ...isSubList,
                      sublist2: !isSubList.sublist2,
                    })
                  }
                />
                <div className="pl-4 flex items-center">
                  <Checkbox>
                    <input
                      checked={isCheckedB}
                      id="check-b"
                      type="checkbox"
                      className="checkbox opacity-0 absolute cursor-pointer w-full h-full"
                      onChange={(e) => {
                        handleClick(!isCheckedB, "", "", e)
                      }}
                    />
                  </Checkbox>
                  <p
                    className=" leading-normal ml-4 text-gray-800  cursor-pointer"
                    onClick={() =>
                      setIsSubList({
                        ...isSubList,
                        sublist2: !isSubList.sublist2,
                      })
                    }
                  >
                    Klasse B
                  </p>
                </div>
              </div>
            </div>
            {/* Sublsite Klasse B */}
            {isSubList.sublist2 && (
              <div className="pl-8">{getTopics("klasseB")}</div>
            )}
          </div>
          {/* Klasse A */}
          <div>
            <div className="flex items-center justify-between mt-6">
              <div className="flex items-center">
                {/* Pfeil  */}
                <ListArrow
                  isOpened={isSubList.sublist3}
                  onClick={() =>
                    setIsSubList({
                      ...isSubList,
                      sublist3: !isSubList.sublist3,
                    })
                  }
                />
                <div className="pl-4 flex items-center">
                  <Checkbox>
                    <input
                      checked={isCheckedA}
                      id="check-a"
                      type="checkbox"
                      className="checkbox opacity-0 absolute cursor-pointer w-full h-full"
                      onChange={(e) => {
                        handleClick(!isCheckedA, "", "", e)
                      }}
                    />
                  </Checkbox>
                  <p
                    onClick={() =>
                      setIsSubList({
                        ...isSubList,
                        sublist3: !isSubList.sublist3,
                      })
                    }
                    className=" leading-normal ml-4 text-gray-800  cursor-pointer"
                  >
                    Klasse A
                  </p>
                </div>
              </div>
            </div>
            {/* Sublsite Klasse A */}
            {isSubList.sublist3 && (
              <div className="pl-8">
                <div className="pl-8">{getTopics("klasseA")}</div>
              </div>
            )}{" "}
          </div>
        </div>
      )}
      <style>{` `}</style>
    </div>
  )
}
export default DropDown
