import React from "react"

type ListArrowProps = {
  onClick: () => void
  isOpened: boolean
}

const ListArrow = ({ onClick, isOpened }: ListArrowProps): JSX.Element => {
  return (
    <svg
      className={` cursor-pointer ${
        isOpened ? "rotate-90 -translate-x-2" : ""
      }`}
      onClick={onClick}
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="scale-150 "
        d="M4.5 3L7.5 6L4.5 9"
        stroke="#4B5563"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default ListArrow
