import React, { useRef, useEffect, useState } from "react"

// CSS Imports für FullCalendar
// import "@fullcalendar/common/main.css"
// import "@fullcalendar/daygrid/main.css"
// import "@fullcalendar/list/main.css"

import FullCalendar, {
  CalendarApi,
  CalendarDataProvider,
  EventContentArg,
} from "@fullcalendar/react" // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid" // a plugin!
import deLocale from "@fullcalendar/core/locales/de"
import listPlugin from "@fullcalendar/list"
import googleCalendarPlugin from "@fullcalendar/google-calendar"
import ThemenStyles from "./themenStyles"

import "./calendar.css"
import DropDown from "./dropDown"

const CalendarComponent = (): JSX.Element => {
  // Google Kalender
  const events = {
    googleCalendarId: "c_ijsd96qk9lgb3v0hptmthnrnv8@group.calendar.google.com",
    className: "gcal-event", // an option!
  }

  // Kalender Ref
  const calendar = React.useRef<any>(null)

  // Check für erstmaligen Kalender View Render
  const [init, setInit] = useState(true)

  //Custom Event View
  function renderEventContent(eventContent: EventContentArg): any {
    return (
      <>
        <div className="fc-event-time">{eventContent.timeText}</div>
        <div className="af-location">
          {eventContent.event.extendedProps.location}
        </div>
        <div className="fc-event-title">{eventContent.event.title}</div>
      </>
    )
  }

  // Handle Kalendar Filter
  const [activeTarup, toggleTarup] = useState<boolean>(true)
  const [activeSatrup, toggleSatrup] = useState<boolean>(true)

  // State Dropdown
  const [isList, setIsList] = useState(false)

  // State Dropdown Kategoire Allgemein
  const [isCheckedAllgemein, SetIsCheckedAllgemein] = useState(true)

  // State Dropdown A
  const [isCheckedA, SetIsCheckedA] = useState(true)

  // State Dropdown Kategoire B
  const [isCheckedB, SetIsCheckedB] = useState(true)

  // State SubList Type
  interface SublistObjectLayout {
    [key: string]: boolean
  }

  // State Dropdown Sublist
  const [isSubList, setIsSubList] = useState<SublistObjectLayout>({
    sublist1: false,
    sublist2: false,
    sublist3: false,
  })

  // State Checkbox Type
  interface StatusObjectLayout {
    [key: string]: {
      [key: string]: boolean
    }
  }

  // State Checkbox
  const [checkedBoxes, setCheckboxes] = useState<StatusObjectLayout>({
    ["allgemeine"]: {
      "Thema 1": true,
      "Thema 2": true,
      "Thema 3": true,
      "Thema 4": true,
      "Thema 5": true,
      "Thema 6": true,
      "Thema 7": true,
      "Thema 8": true,
      "Thema 9": true,
      "Thema 10": true,
      "Thema 11": true,
      "Thema 12": true,
    },
    ["klasseB"]: {
      "Thema 13": true,
      "Thema 14": true,
    },
    ["klasseA"]: {
      "Thema 15": true,
      "Thema 16": true,
      "Thema 17": true,
      "Thema 18": true,
    },
  })

  // Handle Checkbox Click
  function handleClick(
    status: boolean,
    kategorie: string,
    thema: string,
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const inputId = event.target.id

    const newBoxes = { ...checkedBoxes }

    if (
      inputId == "check-a" ||
      inputId == "check-b" ||
      inputId == "check-allgemein"
    ) {
      switch (inputId) {
        case "check-a": {
          SetIsCheckedA(status)
          const thema = { nr: 14 }
          Object.entries(newBoxes["klasseA"]).forEach(() => {
            thema.nr++
            newBoxes["klasseA"][`Thema ${thema.nr}`] = status
          })
          setCheckboxes(newBoxes)

          break
        }
        case "check-b": {
          SetIsCheckedB(status)
          const thema = { nr: 12 }
          Object.entries(newBoxes["klasseB"]).forEach(() => {
            thema.nr++
            newBoxes["klasseB"][`Thema ${thema.nr}`] = status
          })
          setCheckboxes(newBoxes)
          break
        }
        case "check-allgemein": {
          SetIsCheckedAllgemein(status)
          const thema = { nr: 0 }
          Object.entries(newBoxes["allgemeine"]).forEach(() => {
            thema.nr++
            newBoxes["allgemeine"][`Thema ${thema.nr}`] = status
          })
          setCheckboxes(newBoxes)
          break
        }
        default: {
        }
      }
    } else {
      // Set new Status
      newBoxes[kategorie][thema] = status

      // Update State with New Statuses
      setCheckboxes(newBoxes)
    }
    console.log("Neue Boxes")

    console.log(checkedBoxes)
  }

  // Handle Location Filter Click
  const onHandleClick = (button: number): void => {
    if (button == 1) {
      toggleTarup(!activeTarup)
    }
    if (button == 2) toggleSatrup(!activeSatrup)
  }

  // Use Effect Hook
  useEffect(() => {
    // Referenz auf Kalender API
    const calendarApi: CalendarApi = calendar.current.getApi()

    // Init Calendar View
    if (init) {
      handleResize()
      setInit(false)
    }

    function getWindowWidth(): number {
      const { innerWidth: width } = window
      return width
    }

    // Change Calendar View to mobile
    function handleResize(): void {
      if (getWindowWidth() < 1024) {
        console.log("View Mobile")
        calendarApi.changeView("listMonth")
        calendarApi.setOption("contentHeight", "auto")
      } else {
        console.log("View DSKTP")
        calendarApi.changeView("dayGridMonth")
        calendarApi.setOption("contentHeight", "auto")
      }
    }
    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <>
      {!activeTarup ? (
        <style>
          {`
    .tarup {
        display:none !important;
        
    }
  }
  `}{" "}
        </style>
      ) : (
        ""
      )}
      {!activeSatrup ? (
        <style>
          {`
  .satrup {
      display:none !important;
  }
`}{" "}
        </style>
      ) : (
        ""
      )}
      <ThemenStyles checkedBoxes={checkedBoxes}></ThemenStyles>
      <div className="filter-bar mb-8 xl:mb-16">
        <div className="text-secondary text-bold text-lg mb-6 xl:mb-6">
          Filter
        </div>
        {/* Filter */}
        <div className="flex flex-wrap xs:space-x-4 items-center">
          <span
            id="tarupFilter"
            onClick={() => onHandleClick(1)}
            className={`px-4 py-2 rounded-full font-semibold flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease h-fit mr-4  xs:mr-0 ${
              activeTarup == true ? "bg-primary text-white" : "bg-gray-200 "
            }`}
          >
            Tarup
          </span>
          <span
            onClick={() => onHandleClick(2)}
            className={`px-4 py-2 rounded-full font-semibold  flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease h-fit ${
              activeSatrup == true
                ? "bg-secondary text-white"
                : "bg-gray-200 text-gray-500 "
            }`}
          >
            Satrup
          </span>
          <DropDown
            isList={isList}
            setIsList={setIsList}
            checkedBoxes={checkedBoxes}
            handleClick={handleClick}
            isSubList={isSubList}
            setIsSubList={setIsSubList}
            isCheckedAllgemein={isCheckedAllgemein}
            isCheckedB={isCheckedB}
            isCheckedA={isCheckedA}
          ></DropDown>
        </div>
      </div>
      <div id="af-calendar" className="">
        {typeof window !== "undefined" && (
          <FullCalendar
            ref={calendar}
            locale={deLocale}
            listDayFormat={{
              day: "2-digit",
            }}
            buttonText={{
              today: "heute",
            }}
            weekends={false}
            plugins={[dayGridPlugin, listPlugin, googleCalendarPlugin]}
            initialView="dayGridMonth"
            googleCalendarApiKey="AIzaSyB6SvyTQSRXlW9-1ThhBAem2tnsKW7EXJI"
            events={events}
            eventContent={renderEventContent}
            eventClassNames={(arg) => {
              if (arg.event.extendedProps.location == "Tarup") {
                return "tarup " + "thema-" + arg.event.title.replace(".", " ")
              } else
                return "satrup " + "thema-" + arg.event.title.replace(".", " ")
            }}
          />
        )}
      </div>
    </>
  )
}

export default CalendarComponent
