/**
 *  Home page
 *
 *  This is considered to be the landing page
 *  Url, http://localhost:3000/
 *
 *  It contains a basic example on how to use images in gatsby
 */

import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HeroSection from "../components/home/heroSection"
import UeberUnsSection from "../components/home/ueberUnsSection"
import StandorteSection from "../components/home/standorte"

const Index = (): JSX.Element => (
  <Layout>
    <SEO
      title="Achims Fahrschule | Deine Fahrschule in Tarup und Satrup"
      description="Einfach und unkompliziert zum Führerschein. Das ist seit mittlerweile über 20 Jahren erfolgreich unsere Mission."
    />
    <HeroSection />
    <UeberUnsSection />
    <StandorteSection />
  </Layout>
)

export default Index
