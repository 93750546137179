import React from "react"

import { classNameProps } from "../../types/classNameProps"

const Street1 = ({ className = "moin" }: classNameProps): JSX.Element => {
  return (
    <svg
      id="a"
      className={`street1 ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 547.65 483.96"
    >
      <rect
        className="animated-stripes"
        x="10.32"
        y="460.48"
        width="20.04"
        height="2.5"
        transform="translate(-441.5 479.17) rotate(-89.64)"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <rect
        className="animated-stripes"
        x="10.54"
        y="420.4"
        width="20.04"
        height="2.5"
        transform="translate(-401.21 439.56) rotate(-89.64)"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <path
        className="animated-stripes"
        d="M19.54,378.45l-.07,13.13h2.5l.07-13.12c.01-2.27,.1-4.57,.26-6.81h0l-2.5-.18c-.16,2.3-.25,4.65-.26,6.98Z"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <path
        className="animated-stripes"
        d="M23.13,352.5l2.42,.64c1.71-6.38,4.02-12.58,6.9-18.52l-2.25-1.1c-2.96,6.08-5.32,12.43-7.07,18.97Z"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <path
        className="animated-stripes"
        d="M40.75,315.26l2,1.5c3.96-5.28,8.42-10.18,13.31-14.62l-1.68-1.85c-5,4.55-9.57,9.56-13.62,14.97Z"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <path
        className="animated-stripes"
        d="M70.58,288.15l1.3,2.14c5.64-3.44,11.59-6.33,17.78-8.64l-.87-2.34c-6.34,2.36-12.43,5.33-18.21,8.85Z"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <path
        className="animated-stripes"
        d="M108.34,274.09l.41,2.47c5.85-.97,11.77-1.44,17.7-1.41h2.05v-2.49h-2.03c-6.07-.04-12.13,.44-18.12,1.43Z"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <rect
        className="animated-stripes"
        x="157.31"
        y="264.05"
        width="2.5"
        height="20.04"
        transform="translate(-116.35 431.18) rotate(-89.69)"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <rect
        className="animated-stripes"
        x="197.39"
        y="264.27"
        width="2.5"
        height="20.04"
        transform="translate(-76.7 471.46) rotate(-89.69)"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <path
        className="animated-stripes"
        d="M247.6,267.07c-6.22,2.22-12.63,3.84-19.16,4.84l.38,2.48c6.69-1.02,13.26-2.68,19.63-4.96h0l-.84-2.36Z"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <path
        className="animated-stripes"
        d="M265.5,258.67l1.27,2.15c5.82-3.45,11.29-7.44,16.36-11.92h0l-1.66-1.88c-4.94,4.38-10.29,8.28-15.97,11.65Z"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <path
        className="animated-stripes"
        d="M294.96,232.57l1.98,1.53c4.13-5.36,7.73-11.09,10.77-17.13h0l-2.24-1.13c-2.97,5.9-6.49,11.5-10.52,16.73Z"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <path
        className="animated-stripes"
        d="M312.63,197.41l2.41,.68c1.83-6.51,3.03-13.18,3.58-19.92h0l-2.49-.21c-.55,6.58-1.72,13.09-3.5,19.45Z"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <rect
        className="animated-stripes"
        x="307.84"
        y="146.77"
        width="20.04"
        height="2.5"
        transform="translate(168.14 465.08) rotate(-89.69)"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <path
        className="animated-stripes"
        d="M316.99,117.88l2.5,.18c.46-6.59,1.54-13.11,3.23-19.49h0l-2.42-.65c-1.74,6.53-2.84,13.22-3.31,19.96Z"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <path
        className="animated-stripes"
        d="M327.35,78.94l2.25,1.09c2.89-5.94,6.33-11.59,10.29-16.88l-2-1.5c-4.05,5.41-7.58,11.2-10.54,17.28Z"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <path
        className="animated-stripes"
        d="M351.5,46.67l1.68,1.85c4.89-4.44,10.18-8.42,15.81-11.86h0l-1.3-2.14c-5.77,3.53-11.19,7.6-16.2,12.15Z"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <path
        className="animated-stripes"
        d="M385.9,25.66l.87,2.35c6.19-2.31,12.58-4.02,19.09-5.11l-.41-2.47c-6.67,1.12-13.22,2.87-19.55,5.23Z"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <rect
        className="animated-stripes"
        x="434.38"
        y="10.27"
        width="2.5"
        height="20.04"
        transform="translate(413.02 455.81) rotate(-89.69)"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <rect
        className="animated-stripes"
        x="474.46"
        y="10.48"
        width="2.5"
        height="20.04"
        transform="translate(452.67 496.09) rotate(-89.69)"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <rect
        className="animated-stripes"
        x="514.54"
        y="11.7"
        width="2.5"
        height="20.04"
        transform="translate(491.32 537.38) rotate(-89.69)"
        fill="white"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <path
        className="animated-form"
        d="M547.65,40.91l-124.11-.66c-6.95-.05-13.89,.77-20.64,2.42-37.13,9.16-64.69,42.48-64.9,82.22l-.24,45.03c-.37,69-56.8,124.83-125.8,124.46l-85.62-.46c-46.92-.25-85.29,37.72-85.54,84.64l-.56,105.4"
        fill="none"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
      <path
        className="animated-form-reverse"
        d="M.2,483.96l.56-105.61c.37-69,56.8-124.83,125.8-124.46l85.62,.46c46.92,.25,85.29-37.71,85.54-84.64l.24-45.03C298.33,55.67,354.76-.16,423.76,.2l123.89,.66"
        fill="none"
        stroke="#564c6e"
        strokeMiterlimit="10"
        strokeWidth=".6"
      />
    </svg>
  )
}

export default Street1
