import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import LicenceLink from "./licenceLink"
import { AutoIcon, MofaIcon, MotoIcon, TraktorIcon } from "../shared/icons"
import { Link } from "gatsby"

type LicencesNavigationProps = {
  links: Map<string, string>
  activeURL?: string
  isDetailPage?: boolean
}

const LicencesNavigation = ({
  links,
  activeURL = "",
  isDetailPage = false,
}: LicencesNavigationProps): JSX.Element => {
  const baseUrl = "/fuehrerscheinklassen"
  return (
    <div className="flex flex-col space-y-4 md:space-y-8 md:items-center pt-10 pb-10 bg-purple-50">
      <div className="flex flex-wrap space-y-4  md:space-x-4 sm:space-y-0 md:justify-center md:w-full relative">
        {isDetailPage ? (
          <div className="w-full lg:w-fit justify-self-start md:justify-center md:flex md:mb-8 lg:left-0 lg:absolute">
            <Link className="text-bold" to="/fuehrerscheinklassen">
              <span className="mr-2">{"< "}</span>
              {"Zurück"}
            </Link>
          </div>
        ) : (
          ""
        )}
        <div className="flex space-x-4 w-full  md:justify-start sm:w-fit shrink-0">
          <LicenceLink
            active={activeURL == "b" ? true : false}
            to={`${baseUrl}/${String(links.get("B"))}`}
          >
            B
          </LicenceLink>
          <LicenceLink
            active={activeURL == "be" ? true : false}
            to={`${baseUrl}/${String(links.get("BE"))}`}
          >
            BE
          </LicenceLink>
          <AutoIcon className="h-9 mt-1"></AutoIcon>
        </div>
        <div className="flex space-x-4 w-full md:justify-start sm:w-fit shrink-0 sm:ml-4 md:ml-0">
          <div className="flex space-x-4">
            <LicenceLink
              active={activeURL == "a1" ? true : false}
              to={`${baseUrl}/${String(links.get("A1"))}`}
            >
              A1
            </LicenceLink>
            <LicenceLink
              active={activeURL == "a2" ? true : false}
              to={`${baseUrl}/${String(links.get("A2"))}`}
            >
              A2
            </LicenceLink>
            <LicenceLink
              active={activeURL == "a" ? true : false}
              to={`${baseUrl}/${String(links.get("A"))}`}
            >
              A
            </LicenceLink>
            <MotoIcon className="h-9 mt-1"></MotoIcon>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap space-y-4 xss:space-x-4 xss:space-y-0">
        <div className="flex space-x-4">
          <LicenceLink
            active={activeURL == "mofa" ? true : false}
            to={`${baseUrl}/${String(links.get("Mofa"))}`}
          >
            Mofa
          </LicenceLink>
          <MofaIcon className="h-9 mt-1"></MofaIcon>
        </div>
        <div className="flex space-x-4 w-full xss:w-fit">
          <div className="flex space-x-4">
            <LicenceLink
              active={activeURL == "traktor" ? true : false}
              to={`${baseUrl}/${String(links.get("Traktor"))}`}
            >
              Traktor
            </LicenceLink>
            <TraktorIcon className="h-9 mt-1"></TraktorIcon>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LicencesNavigation
