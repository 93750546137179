import React, { useRef } from "react"
import { useState } from "react"
import { Link } from "gatsby"
import gsap from "gsap"
import Flip from "gsap/Flip"

gsap.registerPlugin(Flip)

import "./navigation.css"

import Logo from "../shared/logo"
import BurgerMenu from "./burgerMenu"
import VoranmeldungIcon from "./voranmeldungIcon"
import Menue from "./menue"
import VoranmeldungForm from "./voranmeldungForm"
import { AppContext } from "../layout"
import OpeningHours from "./openingHours"
import { useLayoutEffect } from "react"

const NavigationComponent = (): JSX.Element => {
  const burgerRef = useRef<HTMLDivElement>(null)
  const closeRef = useRef<HTMLDivElement>(null)
  const voranmeldungRef = useRef<HTMLDivElement>(null)

  const el = useRef<HTMLDivElement>(null)

  const [active, toggleMenue] = useState<boolean>(false)

  // const [activeVoranmeldung, toggleVoranmeldung] = useState<boolean>(false)
  const { isOpen: activeVoranmeldung, toggleMenu: toggleVoranmeldung } =
    React.useContext(AppContext)

  useLayoutEffect(() => {
    // capture state
    const state = Flip.getState("#opening-hours")

    Flip.from(state, {
      duration: 0.3,
      ease: "power1.inOut",
      absolute: true,
    })
  }, [activeVoranmeldung, active])

  const onHandleClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    const timeline = gsap.timeline()

    // Animationen wenn Burger Icon geklickt:
    if (e.currentTarget.id === "burger-menue") {
      if (active) {
        toggleMenue(false)
      } else {
        gsap.to(voranmeldungRef.current, {
          opacity: 0,
        })

        timeline
          .to(burgerRef.current, {
            opacity: 0,
            duration: 0.1,
            ease: "ease-in-out",
            scaleX: 0,
            onComplete: () => toggleMenue(true),
          })
          .to(burgerRef.current, {
            opacity: 1,
            duration: 0.1,
            scaleX: 1,
            ease: "ease-in-out",
          })
      }

      toggleVoranmeldung(false)
    }
    // Animationen wenn Voranmelde Icon geklickt:
    if (e.currentTarget.id === "voranmeldung-icon") {
      if (activeVoranmeldung) {
        toggleVoranmeldung(false)
      } else {
        toggleVoranmeldung(true)
      }
      toggleMenue(false)
    }
    // Animationen wenn Schliessen Icon geklickt:
    if (e.currentTarget.id === "close-icon") {
      timeline
        .to(closeRef.current, {
          opacity: 0,
          duration: 0.1,
          scaleX: 0,
          ease: "ease-in-out",
          onComplete: () => {
            toggleVoranmeldung(false)
            toggleMenue(false)
          },
        })
        .to(closeRef.current, {
          opacity: 1,
          scaleX: 1,
          duration: 0.3,
          ease: "ease-in-out",
        })
    }
  }

  return (
    // Navigation Component
    <nav id="navbar-container" className="fixed w-full  z-20 ">
      <div className="left-0 top-0 h-20 sm:h-32  xl:h-40 flex items-center bg-white border border-b-4 border-gray-100 overflow-y-visible z-20 relative flex-nowrap">
        {/* Logo */}
        <div className="pl-6 pr-4 xs:pr-0 md:pl-14 xll:pl-32 xxxl:pl-64  flex flex-shrink">
          <Link className="flex flex-shrink" to="/">
            <Logo className="w-full xxs:w-24 xs:w-32 md:w-40 xl:w-48 cursor-pointer flex-shrink" />
          </Link>
        </div>
        <OpeningHours></OpeningHours>
        {/* Rechte Seite Buttons  */}
        <div
          className="justify-self-end flex ml-auto h-full relative bg-white"
          ref={el}
        >
          {/* Voranmeldung */}
          {activeVoranmeldung == false && active == false ? (
            <div
              ref={voranmeldungRef}
              id="voranmeldung-icon"
              onClick={onHandleClick}
              className="flex flex-col justify-center items-center  py-2  border-l-4 border-gray-100 h-full px-2 xxs:px-6 md:px-8 cursor-pointer transition-colors fill-secondary hover:fill-primary text-secondary hover:text-primary"
            >
              <VoranmeldungIcon className="w-8 h-10 md:h-auto md:w-10 " />
              <span className="text-bold text-xs mt-2">Voranmeldung</span>
            </div>
          ) : (
            ""
          )}
          {/* Hamburger Menü */}
          {activeVoranmeldung == false && active == false ? (
            <div
              onClick={onHandleClick}
              id="burger-menue"
              className="flex flex-col items-center justify-center  px-6 md:px-8  border-l-4 border-gray-100 "
            >
              <div
                id="burger-menue-wrapper"
                ref={burgerRef}
                className="w-8 lg:w-10 flex justify-center "
              >
                <BurgerMenu className="w-8 lg:w-10 block cursor-pointer transition-colors stroke-secondary hover:stroke-primary" />
              </div>
            </div>
          ) : (
            // Scliessen Icon
            <div
              id="close-icon"
              onClick={onHandleClick}
              className="flex flex-col justify-center items-center  py-2 border-l-4  border-gray-100 h-full px-6 md:px-8 cursor-pointer"
            >
              <div ref={closeRef} className="w-8 lg:w-10 flex justify-center ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="45.962"
                  height="45.962"
                  viewBox="0 0 45.962 45.962"
                  className="w-6 lg:w-8 transition-colors text-secondary hover:text-primary  stroke-current"
                  data-v-54d70a30=""
                  data-v-0d5d2240=""
                >
                  <g transform="translate(-13369.52 4469.839)">
                    <line
                      x2="62"
                      transform="translate(13370.58 -4468.778) rotate(45)"
                      fill="none"
                      strokeWidth="4"
                    ></line>{" "}
                    <line
                      x2="62"
                      transform="translate(13370.58 -4424.938) rotate(-45)"
                      fill="none"
                      strokeWidth="4"
                    ></line>
                  </g>
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>{" "}
      <VoranmeldungForm active={activeVoranmeldung} />
      {/* Menü */}
      <Menue active={active} />
    </nav>
  )
}

export default NavigationComponent
