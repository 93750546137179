import React from "react"
import RegisterButton from "../shared/registerButton"
import { StaticImage } from "gatsby-plugin-image"
import GuideGreenLayout from "./guideGreenLayout"
import { useState } from "react"

const Guide1 = (): JSX.Element => {
  const [activeText, toggleText] = useState<boolean>(false)

  const onHandleClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    toggleText(!activeText)
  }

  return (
    <GuideGreenLayout>
      {/* Reihe  */}
      <div className="flex flex-col  relative sm:flex-row sm:flex-wrap guide-section">
        {/* Heading */}
        <div className="h-14 xss:h-6 z-10 relative sm:w-full sm:h-full sm:whitespace-nowrap ">
          <h2 className="text-white block animated-left">
            Der Theorieunterricht
          </h2>
        </div>
        {/* Bild */}
        <div className="img-wrapper flex w-full pl-12 sm:w-1/2 sm:order-1 sm:self-center sm:absolute sm:right-0  sm:overflow-hidden animated-img-right">
          <StaticImage
            alt="Anmeldung bei der Fahrschule"
            className="w-full h-full object-cover "
            src="../../images/AchimsFahrschule-unterricht.jpg"
          />
        </div>
        {/* text */}
        <div className="w-full sm:w-1/2 flex flex-col overflow-hidden ">
          <p className="text-white  relative  xl:w-3/3 xl:pr-12 animated-opacity">
            Dein Weg zum Führerschein beginnt mit dem Theorieunterricht vor Ort
            in unseren Räumlichkeiten. Dort lernst du nicht nur Verkehrs- und
            Vorfahrtsregeln kennen, sondern auch andere Themengebiete rund ums
            Fahren. Der Theorieunterricht legt dabei die Grundlage für deine
            Teilnahme am Straßenverkehr und ist somit ein wesentlicher
            Bestandteil deiner Ausbildung. Eine Theoriestunde umfasst 90
            Minuten.
          </p>
        </div>
      </div>
    </GuideGreenLayout>
  )
}

export default Guide1
