/**
 *  Imprint page
 *
 *  This is considered to be the landing page
 *  Url, http://localhost:3000/
 *
 *  It contains a basic example on how to use images in gatsby
 */

import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import CalendarComponent from "../components/calendar/calendarComponent"

const Index = (): JSX.Element => {
  return (
    <Layout>
      <SEO
        title="Achims Fahrschule | Dein Fahrplan für den Theorieunterricht"
        description="Unser Kalender zeigt dir, wann welches Thema behandelt wird. Filtere die Termine nach Ort oder Thema für einen besseren Überblick."
      >
        {/* Stylesheet Link für Full Calendar  (Dirty Hack)*/}
        <link rel="stylesheet" href="/fullCalendar-styles.css"></link>
      </SEO>
      <div className="theorieunterricht-page container-fluid ">
        {/* Intro */}
        <div className="first-section-spacing-top section-vertical-spacing relative overflow-hidden flex flex-wrap">
          <h1 className="w-full">
            Dein Fahrplan zum <br /> Theorieunterricht
          </h1>
          <div className="w-full ">
            <p className="w-full bg-white sm:w-7/12 xxs:mb-0">
              Unser Kalender zeigt dir, wann welches Thema behandelt wird.
              Filtere die Termine nach Ort oder Thema für einen besseren
              Überblick.
            </p>
          </div>
        </div>
        <div
          className="mb-12 md:mb-16 xl:mb-32 xxxl:mb-48;
"
        >
          <CalendarComponent></CalendarComponent>
        </div>
      </div>
    </Layout>
  )
}

export default Index
