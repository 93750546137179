import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import GuideGreenLayout from "./guideGreenLayout"
import { useState } from "react"
import MoreButton from "./moreButton"
import Collapsible from "react-collapsible"

const Guide3 = (): JSX.Element => {
  const [activeText, toggleText] = useState<boolean>(false)

  const onHandleClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    toggleText(!activeText)
  }

  return (
    <GuideGreenLayout>
      {/* Reihe  */}
      <div className="flex flex-col  relative sm:flex-row sm:flex-wrap guide-section">
        {/* Heading */}
        <div className="h-14 xss:h-6 z-10 relative w-full sm:h-full sm:whitespace-nowrap">
          <h2 className="text-white block animated-left">Die Theorieprüfung</h2>
        </div>
        {/* Bild */}
        <div className="img-wrapper flex w-full pl-12 sm:w-1/2 sm:order-1 sm:self-center sm:absolute sm:right-0  sm:overflow-hidden animated-img-right">
          <StaticImage
            alt="Theorieprüfung"
            className="w-full h-full object-cover"
            src="../../images/AchimsFahrschule-theoriepruefung.jpg"
          />
        </div>
        {/* text */}
        <div className="w-full sm:w-1/2 flex flex-col overflow-hidden">
          <p className="text-white  relative  xl:w-3/3 xl:pr-12 animated-opacity">
            Bist du ausreichend auf deine Theorieprüfung vorbereitet, kannst du
            diese endlich absolvieren. Nach entsprechender Vorbereitung,
            meisterst du das Ganze mit links! Sollte wider Erwarten etwas nicht
            geklappt haben, kannst du die Prüfung wiederholen. Das ist kein
            Problem!
          </p>
          <Collapsible
            easing="ease-in-out"
            className="flex flex-col "
            openedClassName="flex flex-col"
            triggerClassName="order-1"
            triggerOpenedClassName="order-1"
            transitionTime={150}
            trigger={
              <div
                onClick={onHandleClick}
                className=" relative w-full animated-opacity order-1"
              >
                <MoreButton>
                  {activeText === true ? "Weniger anzeigen" : "Weiterlesen"}
                </MoreButton>
              </div>
            }
          >
            <p className="text-white  relative  xl:w-3/3 xl:pr-12 animated-opacity">
              In der theoretischen Prüfung musst du nachweisen, dass du
              ausreichende Kenntnisse über das Führen von Kraftfahrzeugen und
              den maßgebenden gesetzlichen Vorschriften sowie der
              umweltbewussten und energiesparenden Fahrweise hast und du mit den
              Gefahren des Straßenverkehrs vertraut bist.
            </p>
            <p className="text-white  relative  xl:w-3/3 xl:pr-12 animated-opacity">
              Die Prüfung dauert i.d.R. 15 Minuten. Das Mindestalter, welches
              für deinen angestrebten Führerschein vorausgesetzt wird, muss in
              mindestens drei Monaten erreicht werden, bevor die theoretische
              Prüfung abgelegt werden kann.
            </p>
          </Collapsible>
        </div>
      </div>
    </GuideGreenLayout>
  )
}

export default Guide3
