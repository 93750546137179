/**
 *  Imprint page
 *
 *  This is considered to be the landing page
 *  Url, http://localhost:3000/
 *
 *  It contains a basic example on how to use images in gatsby
 */

import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { useStaticQuery, graphql } from "gatsby"

const Index = (): JSX.Element => {
  const data = useStaticQuery<GatsbyTypes.GetContactIntoImpressumQuery>(graphql`
    query GetContactIntoImpressum {
      site {
        siteMetadata {
          telefon
          email
          whatsapp
          STRASSE1
          PLZ1
          ORT1
          STRASSE2
          PLZ2
          ORT2
        }
      }
    }
  `)

  if (!data.site?.siteMetadata)
    throw new Error("SiteMetaData.title possibly undefined")

  const contactData = data.site?.siteMetadata
  console.log(contactData)
  // Make Numbers only
  const makeDigits = (string: string): string => {
    const res = string.replace(/\D/g, "")
    return res
  }

  return (
    <Layout>
      <SEO title="Achims Fahrschule | Impressum" />
      <div className="impressum-page container-fluid ">
        <div className="first-section-spacing-top pt-12 md:pt-16 xl:pt-32 xxxl:pt-48  relative overflow-hidden flex flex-wrap ">
          <h1 className="w-full sm:w-7/12">Impressum</h1>
        </div>
        {/* Reihe */}
        <div className="w-full md:flex md:flex-no-wrap section-spacing-bottom md:space-x-8">
          {/* Spalte 1 */}
          <div className="md:w-1/2">
            <h2 className="text-xl md:text-3xl text-secondary">
              Angaben gemäß § 5 TMG
            </h2>
            <p>
              <span className="text-bold">Hans-Joachim Wicka</span>
              <br />
              <span className="">Achims Fahrschule</span>
              <br />
              {contactData.STRASSE1}
              <br />
              {contactData.PLZ1} {contactData.ORT1} <br />
            </p>

            <p>
              <span className="text-bold">Kontakt</span>
              <br />
              <a href={`tel:${contactData.telefon}`}>{contactData.telefon}</a>
              <br />
              <a href={`mailto:${contactData.email}`}>{contactData.email} </a>
              <br />
            </p>
            <p>
              <span className="text-bold">Unsere Fahrschulen</span>
              <br />
              <span>Achims Fahrschule Tarup</span>
              <br />
              {contactData.STRASSE1}
              <br />
              {contactData.PLZ1} {contactData.ORT1}
              <br />
              <br />
              <span>Achims Fahrschule Satrup</span>
              <br />
              {contactData.STRASSE2}
              <br />
              {contactData.PLZ2} {contactData.ORT2} <br />
            </p>
          </div>
          {/* Spalte 2 */}
          <div className="md:w-1/2">
            <p>
              <span className="text-bold">EU-Streitschlichtung</span>
              <br />
              Die Europ&auml;ische Kommission stellt eine Plattform zur
              Online-Streitbeilegung (OS) bereit:{" "}
              <a
                href="https://ec.europa.eu/consumers/odr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://ec.europa.eu/consumers/odr/
              </a>
              .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
            </p>
            <p>
              <span className="text-bold">
                Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
              </span>
              <br />
              Wir sind nicht bereit oder verpflichtet, an
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              teilzunehmen.
            </p>
            <p>
              <span className="text-bold"> Haftung für Inhalte</span>
              <br />
              Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
              Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
              verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
              jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
              Informationen zu überwachen oder nach Umständen zu forschen, die
              auf eine rechtswidrige Tätigkeit hinweisen.
            </p>
            <p>
              Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
              Informationen nach den allgemeinen Gesetzen bleiben hiervon
              unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
              Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
              Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
              diese Inhalte umgehend entfernen.
            </p>
            <p>
              {" "}
              <span className="text-bold"> Haftung für Links</span>
              <br />
              Unser Angebot enthält Links zu externen Websites Dritter, auf
              deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
              diese fremden Inhalte auch keine Gewähr übernehmen. Für die
              Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
              überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
              Verlinkung nicht erkennbar.
            </p>

            <p>
              Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
              jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
              zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
              derartige Links umgehend entfernen. Urheberrecht Die durch die
              Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
              unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
              Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
              Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung
              des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser
              Seite sind nur für den privaten, nicht kommerziellen Gebrauch
              gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber
              erstellt wurden, werden die Urheberrechte Dritter beachtet.
              Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
              Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
              werden, bitten wir um einen entsprechenden Hinweis. Bei
              Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
              umgehend entfernen.
            </p>

            <p>
              <span className="text-bold">
                Konzeption, Programmierung und Gestaltung:
              </span>
              <br />
              Saemann & Heller GmbH <br />
              Birklück 10 <br />
              24999 Wees
            </p>
            <p>
              <a href="https://saemannheller.de">https://saemannheller.de</a>
            </p>
            <p>
              Tel.: +49 (0) 4631 9889 040 <br />
              Fax. +49 (0) 4631 9889 049 <br />
              Mail: info(at)saemannheller.de <br />
            </p>
            <p>
              <span className="text-bold">Bildquellen</span>
              <ul>
                <li>Saemann & Heller GmbH</li>
                <li>Adobe Stock</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Index
