/**
 *  Layout component
 *
 *  This stateless component uses a gatsby useStatiQuery hook to get data with graphql
 *  It also shows how to handle this kind of data
 */

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Icongrid from "./shared/iconGrid"

import NavigationComponent from "./navigation/navigation"
import Footer from "./footer/footer"

// import { gsap } from "gsap"

import CookieConsent from "react-cookie-consent"

type LayoutProps = {
  children: React.ReactNode
  location?: string
}

const defaultContext = {
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toggleMenu: (active: boolean) => {
    // NotImplemented
  },
}
export const AppContext = React.createContext(defaultContext)

const Layout = ({ children, location }: LayoutProps): JSX.Element => {
  const el = React.useRef<HTMLDivElement>(null)
  // const q = gsap.utils.selector(el)

  const data = useStaticQuery<GatsbyTypes.GetSiteTitleQuery>(graphql`
    query GetSiteTitle {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  if (!data.site?.siteMetadata?.title)
    throw new Error("SiteMetaData.title possibly undefined")

  const { title } = data.site?.siteMetadata

  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const toggleMenu = (active: boolean): void => {
    setIsOpen(active)
  }

  //Animation
  // wait until DOM has been rendered
  // React.useEffect(() => {
  //   gsap.from(q("#main-content div.hero-section *:not(#klassen-section"), {
  //     y: 100,
  //     opacity: 0,
  //     stagger: {
  //       from: "start",
  //       amount: 1.3,
  //       ease: "power1.inout",
  //     },
  //   })
  // }, [])

  return (
    <AppContext.Provider value={{ isOpen, toggleMenu }}>
      <div>
        <CookieConsent
          location="bottom"
          buttonText="Alles Klar!"
          declineButtonText="ich weiß ja nicht"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#554B6D" }}
          buttonStyle={{
            color: "#554B6D",
            backgroundColor: "white",
            fontSize: "14px",
            borderRadius: "45px",
            padding: "10px 25px",
            className: "text-bold",
          }}
          expires={150}
        >
          <div className="w-full flex flex-row justify-between">
            <div>
              Diese Webseite speichert nur technisch notwendige Cookies. Mehr
              dazu findest du in unseren
              <a className="text-bold" href="/datenschutz" target="_blank">
                {" "}
                Datenschutzerklärungen
              </a>
              .
            </div>
          </div>
        </CookieConsent>{" "}
        <Icongrid></Icongrid>
        <NavigationComponent />
        <main id="main-content" ref={el} className="relative z-10 ">
          <div className="overflow-x-hidden overflow-y-hidden relative snap-y snap-mandatory">
            {children}
          </div>
        </main>
        <Footer location={location} />
      </div>
    </AppContext.Provider>
  )
}

export default Layout
