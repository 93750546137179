import React from "react"

type ThemenStylesProps = {
  checkedBoxes: {
    [key: string]: {
      [key: string]: boolean
    }
  }
}

const ThemenStyles = ({ checkedBoxes }: ThemenStylesProps): JSX.Element => {
  return (
    <>
      {!checkedBoxes["allgemeine"]["Thema 1"] ? (
        <style>
          {`
  .thema-1{
      display:none !important;
  }
`}{" "}
        </style>
      ) : (
        ""
      )}
      {!checkedBoxes["allgemeine"]["Thema 2"] ? (
        <style>
          {`
  .thema-2{
      display:none !important;
  }
`}{" "}
        </style>
      ) : (
        ""
      )}
      {!checkedBoxes["allgemeine"]["Thema 3"] ? (
        <style>
          {`
  .thema-3 {
      display:none !important;
  }
`}{" "}
        </style>
      ) : (
        ""
      )}
      {!checkedBoxes["allgemeine"]["Thema 4"] ? (
        <style>
          {`
  .thema-4 {
      display:none !important;
  }
`}{" "}
        </style>
      ) : (
        ""
      )}

      {!checkedBoxes["allgemeine"]["Thema 5"] ? (
        <style>
          {`
  .thema-5{
      display:none !important;
  }
`}{" "}
        </style>
      ) : (
        ""
      )}
      {!checkedBoxes["allgemeine"]["Thema 6"] ? (
        <style>
          {`
  .thema-6 {
      display:none !important;
  }
`}{" "}
        </style>
      ) : (
        ""
      )}
      {!checkedBoxes["allgemeine"]["Thema 7"] ? (
        <style>
          {`
  .thema-7 {
      display:none !important;
  }
`}{" "}
        </style>
      ) : (
        ""
      )}
      {!checkedBoxes["allgemeine"]["Thema 8"] ? (
        <style>
          {`
  .thema-8{
      display:none !important;
  }
`}{" "}
        </style>
      ) : (
        ""
      )}
      {!checkedBoxes["allgemeine"]["Thema 9"] ? (
        <style>
          {`
  .thema-9 {
      display:none !important;
  }
`}{" "}
        </style>
      ) : (
        ""
      )}
      {!checkedBoxes["allgemeine"]["Thema 10"] ? (
        <style>
          {`
  .thema-10 {
      display:none !important;
  }
`}{" "}
        </style>
      ) : (
        ""
      )}
      {!checkedBoxes["allgemeine"]["Thema 11"] ? (
        <style>
          {`
 .thema-11 {
      display:none !important;
  }
`}{" "}
        </style>
      ) : (
        ""
      )}
      {!checkedBoxes["allgemeine"]["Thema 12"] ? (
        <style>
          {`
  .thema-12 {
      display:none !important;
  }
`}{" "}
        </style>
      ) : (
        ""
      )}
      {!checkedBoxes["klasseB"]["Thema 13"] ? (
        <style>
          {`
  .thema-13 {
      display:none !important;
  }
`}{" "}
        </style>
      ) : (
        ""
      )}
      {!checkedBoxes["klasseB"]["Thema 14"] ? (
        <style>
          {`
  .thema-14 {
      display:none !important;
  }
`}{" "}
        </style>
      ) : (
        ""
      )}
      {!checkedBoxes["klasseA"]["Thema 15"] ? (
        <style>
          {`
  .thema-15 {
      display:none !important;
  }
`}{" "}
        </style>
      ) : (
        ""
      )}
      {!checkedBoxes["klasseA"]["Thema 16"] ? (
        <style>
          {`
  .thema-16 {
      display:none !important;
  }
`}{" "}
        </style>
      ) : (
        ""
      )}
      {!checkedBoxes["klasseA"]["Thema 17"] ? (
        <style>
          {`
  .thema-17 {
      display:none !important;
  }
`}{" "}
        </style>
      ) : (
        ""
      )}
      {!checkedBoxes["klasseA"]["Thema 18"] ? (
        <style>
          {`
  .thema-18 {
      display:none !important;
  }
`}{" "}
        </style>
      ) : (
        ""
      )}
    </>
  )
}

export default ThemenStyles
