import React from "react"

type ThemaProps = {
  children: React.ReactNode
  thema?: string
  key?: number
}

const Thema = ({ children, thema, key }: ThemaProps): JSX.Element => {
  return (
    <div key={key} className="flex pt-6 items-center justify-between">
      <div className="pl-8 flex items-center">
        {children}
        <p className="leading-normal ml-4 text-gray-800">{thema}</p>
      </div>
    </div>
  )
}
export default Thema
