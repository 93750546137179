import React from "react"
import { AppContext } from "../layout"

type RegisterButtonProps = {
  children: React.ReactNode
  id?: string
}

const RegisterButton = ({ children, id }: RegisterButtonProps): JSX.Element => {
  const { isOpen, toggleMenu } = React.useContext(AppContext)
  return (
    <button
      id={id}
      className="bg-secondary w-fit text-white text-sm xll:text-lg px-6 py-3 text-bold rounded-full transition-colors hover:bg-primary"
      onClick={() => {
        if (isOpen) {
          toggleMenu(false)
        } else {
          toggleMenu(true)
        }
      }}
    >
      {children}
    </button>
  )

  // <Link
  //   to="/"
  //   className="bg-secondary text-white text-sm xll:text-lg px-6 py-3 text-ultrabold"
  // >
  //   {children}
  // </Link>
}

export default RegisterButton
