import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import GuideLayout from "./guideLayout"
import Street2 from "./street2"
import { useState } from "react"
import MoreButton from "./moreButton"

import Collapsible from "react-collapsible"

const Guide2 = (): JSX.Element => {
  const [activeText, toggleText] = useState<boolean>(false)

  const onHandleClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    toggleText(!activeText)
  }

  return (
    <div className="overflow-hidden relative flex justify-center  guide-section guide-section2 ">
      {/* Background Image */}
      <Street2 className="absolute -z-10 h-full w-full scale-105 translate-x-32  md:translate-x-9" />

      <GuideLayout>
        {/* Reihe  */}
        <div className="lg:flex sm:space-x-12  relative">
          {/* Spalte 1 */}
          <div className="w-full sm:w-1/2 md:w-2/3">
            <div className="z-10 relative h-20 xss:h-14 sm:h-28 md:h-32 lg:h-16 xl:h-32">
              <h2 className="animated-left">
                Üben für die theoretische Prüfung
              </h2>
            </div>
            <div className="img-wrapper pr-12 sm:pr-0 w-full overflow-hidden sm:flex sm:justify-end animated-img-left">
              <StaticImage
                className="w-full h-full object-cover sm:w-5/6 "
                src="../../images/achims-fahrschule-lernen.jpg"
                alt="Theorieunterricht"
              />
            </div>
          </div>
          {/* Spalte 2 */}
          <div className="sm:flex sm:flex-col sm:justify-center lg:w-1/3 flex flex-col overflow-hidden relative">
            <p className="sm:w-5/6 animated-opacity bg-white lg:bg-transparent ">
              Neben der praktischen Fahrprüfung musst du ebenfalls eine
              theoretische Prüfung beim TÜV erfolgreich absolvieren. Aber keine
              Sorge - wir bereiten dich optimal auf die Theorieprüfung vor!
            </p>
            <Collapsible
              easing="ease-in-out"
              className="flex flex-col "
              openedClassName="flex flex-col"
              triggerClassName="order-1"
              triggerOpenedClassName="order-1"
              transitionTime={150}
              trigger={
                <div
                  onClick={onHandleClick}
                  className=" relative w-full animated-opacity order-1"
                >
                  <MoreButton>
                    {activeText === true ? "Weniger anzeigen" : "Weiterlesen"}
                  </MoreButton>
                </div>
              }
            >
              <p className="sm:w-5/6 animated-opacity bg-white lg:bg-transparent ">
                Während des Unterrichts werden alle prüfungsrelevanten Themen
                behandelt. Solltest du bei einem Thema unsicher sein, frage
                während des Unterrichts nach und lasse es dir noch genauer
                erklären. Du kannst deinen Fahrlehrer auch außerhalb des
                Unterrichts jederzeit ansprechen!
              </p>
              <p className="sm:w-5/6 animated-opacity bg-white lg:bg-transparent ">
                Um dich bestmöglichst auf den Multiple-Choice-Test
                vorzubereiten, lernst du mit Hilfe von Übungsbögen, die Fragen
                des Tests beinhalten. Lerne mit einer App auf deinem Smartphone/
                Tablet oder schau ganz einfach bei uns in der Fahrschule vorbei
                und übe mit unseren Bögen.
              </p>
            </Collapsible>
          </div>
        </div>
      </GuideLayout>
    </div>
  )
}

export default Guide2
