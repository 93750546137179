import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import GuideGreenLayout from "./guideGreenLayout"
import { useState } from "react"
import MoreButton from "./moreButton"
import Collapsible from "react-collapsible"

const Guide5 = (): JSX.Element => {
  const [activeText, toggleText] = useState<boolean>(false)

  const onHandleClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    toggleText(!activeText)
  }

  return (
    <div className="">
      <GuideGreenLayout>
        {/* Reihe  */}
        <div className="flex flex-col  relative sm:flex-row sm:flex-wrap guide-section">
          {/* Heading */}
          <div className="h-14 xss:h-6 z-10 relative w-full sm:h-full sm:whitespace-nowrap">
            <h2 className="text-white block animated-left">
              Die praktische Prüfung
            </h2>
          </div>
          {/* Bild */}
          <div className="img-wrapper flex w-full pl-12 sm:w-1/2 sm:order-1 sm:self-center sm:absolute sm:right-0  sm:overflow-hidden animated-img-right">
            <StaticImage
              alt="Anmeldung bei der Fahrschule"
              className="w-full h-full object-cover"
              src="../../images/AchimsFahrschule-praktische-pruefung.jpg"
            />
          </div>
          {/* text */}
          <div className="w-full sm:w-1/2 flex flex-col">
            <p className="text-white  relative  xl:w-3/3 xl:pr-12 animated-opacity">
              Hast du alles drauf und bist ausreichend auf die praktische
              Prüfung vorbereitet, geht es los. Die praktische Prüfung geht
              insgesamt 45 Minuten und startet mit einer Sicherheitskontrolle.
              Dabei überprüfst du das Licht, die Reifen und die Bremsen des
              Fahrzeugs.
            </p>
            <Collapsible
              easing="ease-in-out"
              className="flex flex-col "
              openedClassName="flex flex-col"
              triggerClassName="order-1"
              triggerOpenedClassName="order-1"
              transitionTime={150}
              trigger={
                <div
                  onClick={onHandleClick}
                  className=" relative w-full animated-opacity order-1"
                >
                  <MoreButton>
                    {activeText === true ? "Weniger anzeigen" : "Weiterlesen"}
                  </MoreButton>
                </div>
              }
            >
              <div className="text-white  relative  xl:w-3/3 xl:pr-12 animated-opacity">
                <p>
                  Danach erfolgt die persönliche Einstellung von Lenkrad, Sitz,
                  Kopfstützen und aller Spiegel. Nach der Sicherheitskontrolle
                  beginnt das Fahren. Generell werden Strecken innerhalb und
                  außerhalb geschlossener Ortschaften abgefahren und
                  Grundfahraufgaben bewältigt.
                </p>
                <p>
                  Grundfahraufgaben sind z.B. ein Wendemanöver durchführen,
                  rückwärts oder seitlich einparken und eine Gefahrenbremsung
                  durchführen. Hast du jede der Aufgaben fachgerecht gemeistert,
                  erhältst du deinen Führerschein und kannst nun endlich
                  eigenverantwortlich ein Kraftfahrzeug führen. Sollte jedoch
                  mal etwas schief laufen, kannst du die praktische Prüfung, wie
                  auch die theoretische Prüfung, wiederholen.
                </p>
              </div>
            </Collapsible>
          </div>
        </div>
      </GuideGreenLayout>
    </div>
  )
}

export default Guide5
