import React from "react"

import { Link, graphql, useStaticQuery } from "gatsby"
import ContactLinks from "../shared/contactLinks"
import LocationMap from "./locationMap"

import "./menue.css"
import { Location } from "../../types/enum"

type MenueProps = {
  active: boolean
}

const Menue = ({ active }: MenueProps): JSX.Element => {
  const data = useStaticQuery<GatsbyTypes.GetContactDataLocationsQuery>(graphql`
    query GetContactDataLocations {
      site {
        siteMetadata {
          STRASSE1
          STRASSE2
          PLZ1
          PLZ2
          ORT1
          ORT2
          OPENING1A
          OPENING1B
          OPENING2A
          OPENING2B
        }
      }
    }
  `)

  if (!data.site?.siteMetadata)
    throw new Error("SiteMetaData.title possibly undefined")

  const locationData = data.site?.siteMetadata

  // Adressen
  const locations = {
    location1: {
      street: locationData.STRASSE1,
      city: locationData.ORT1,
      zipcode: locationData.PLZ1,
    },
    location2: {
      street: locationData.STRASSE2,
      city: locationData.ORT2,
      zipcode: locationData.PLZ2,
    },
  }
  // Öffnungszeiten
  const opening = {
    opening1: {
      option1: locationData.OPENING1A,
      option2: locationData.OPENING1B,
    },
    opening2: {
      option1: locationData.OPENING2A,
      option2: locationData.OPENING2B,
    },
  }

  return (
    <div
      id="menue"
      className={`overflow-y-scroll w-full bg-white absolute h-screen top-0 z-10 transition-transform -translate-y-full ${
        active ? "active relative" : ""
      }`}
    >
      <div className="container-fluid pb-24">
        <div className="">
          {/* Reihe 1*/}
          <div className="flex flex-col w-full md:flex-row md:flex-nowrap menue-vertical-spacing">
            {/* Spalte 1 Links */}
            <div className="md:w-1/2">
              <ul
                id="nav-link-list"
                className="text-2xl leading-tight text-ultrabold  lg:text-3xl xl:text-4xl xxl:text-5xl"
              >
                <li>
                  <Link activeClassName="text-primary" to="/">
                    Startseite
                  </Link>
                </li>
                <li>
                  <Link
                    activeClassName="text-primary"
                    to="/fuehrerscheinklassen"
                  >
                    Führescheinklassen
                  </Link>
                </li>
                <li>
                  <Link
                    activeClassName="text-primary"
                    to="/dein-weg-zum-fuehrerschein"
                  >
                    Dein Weg zum Führerschein
                  </Link>
                </li>
                <li>
                  <Link activeClassName="text-primary" to="/theorieunterricht">
                    Dein Fahrplan für den Theorieunterricht
                  </Link>
                </li>
                <li>
                  <Link activeClassName="text-primary" to="/kontakt">
                    Kontakt
                  </Link>
                </li>
              </ul>
            </div>
            {/* Spalte 2 Kontakt */}
            <div className="md:w-1/2 md:flex md:justify-center md:items-center mt-10 md:mt-0">
              <ContactLinks className="relative" />
            </div>
          </div>
          {/* Reihe 2 Anfahrt */}
          <div className=" menue-vertical-spacing w-full flex flex-col space-y-10 md:space-y-0  md:flex-row md:flex-nowrap border-t-2 border-gray-200  md:space-x-8">
            {/* Spalte 1 Tarup */}
            <div className="lg:w-1/2">
              <LocationMap
                className="relative"
                title="Tarup"
                link="https://goo.gl/maps/iGyTXJXjVhDojKNi6"
                address={locations.location1}
                opening={opening.opening1}
                imageType={Location.TARUP}
              />
            </div>
            {/* Spalte 2 Satrup*/}
            <div className="lg:w-1/2">
              <LocationMap
                className="relative"
                title="Satrup"
                link="https://goo.gl/maps/y2SRRjsiusFJxipRA"
                address={locations.location2}
                opening={opening.opening2}
                imageType={Location.SATRUP}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Menue
