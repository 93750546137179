/* eslint-disable graphql/template-strings */
import React from "react"

import Layout from "../components/layout"
import { graphql, PageProps } from "gatsby"
import SEO from "../components/seo"
import LicenceTeaserComponent from "../components/licenceTeaser/licenceTeaser"
import LicenceInfoBox from "../components/licenceInfoBox/licenceInfoBox"
import LicencesNavigation from "../components/licencesNavigation/licencesNavigation"

import { formatLinks } from "../helper/format"

const LicenceDetailTemplate = ({
  data,
  location,
}: PageProps<GatsbyTypes.GetMarkdownPageQuery>): JSX.Element => {
  if (!data.markdownRemark)
    throw new Error("Could not get markdown for this page")

  const { frontmatter: teaser } = data.markdownRemark

  if (!teaser?.icon || !teaser?.image) {
    throw new Error("Icon or image available")
  }

  if (!data.allMarkdownRemark)
    throw new Error("Could not get allMarkdown for this page")

  const { nodes } = data.allMarkdownRemark
  const links = formatLinks([...nodes])

  const metaDescription = { text: "" }

  switch (teaser.category) {
    case "B":
      metaDescription.text =
        "Alle Informationen rund um den Führerschein der Klasse B, BF17 und B197. Auch bekannt als Autoführerschein. Diese Führerscheinklasse berechtigt dich dazu, ein Kraftfahrzeug zu fahren."
      break
    case "BE":
      metaDescription.text =
        "Alle Informationen rund um den Führerschein der Klasse BE. Auch bekannt als Anhängerführerschein. Diese Führerscheinklasse berechtigt dich dazu, ein Kraftfahrzeug mit Anhänger zu fahren."
      break
    case "A1":
      metaDescription.text =
        "Alle Informationen rund um den Führerschein der Klasse A1. Diese Führerscheinklasse berechtigt dich dazu, Leichtkrafträder bis 125 cm³ zu fahren."
      break
    case "A2":
      metaDescription.text =
        "Alle Informationen rund um den Führerschein der Klasse A2. Diese Führerscheinklasse berechtigt dich dazu, Motorräder mit einer Motorleistung von bis zu 35 kW (48 PS) zu fahren."
      break
    case "A":
      metaDescription.text =
        "Alle Informationen rund um den Führerschein der Klasse A. Auch bekannt als der große Motorradführerschein. Diese Führerscheinklasse berechtigt dich dazu, ein Motorrad jeglicher Art zu fahren."
      break
    case "Mofa":
      metaDescription.text =
        "Alle Informationen rund um den Führerschein der Klasse Mofa. Auch bekannt als Mofaführerschein. Diese Führerscheinklasse berechtigt dich dazu, ein Mofa zu fahren."
      break
    case "Traktor":
      metaDescription.text =
        "Alle Informationen rund um den Führerschein der Klasse Traktor. Auch bekannt als Traktorführerschein. Diese Führerscheinklasse berechtigt dich dazu, einen Traktor zu fahren."
      break
    default:
      "Alle Informationen rund um den Führerschein"
      break
  }

  return (
    <Layout>
      <SEO
        title={`Achims Fahrschule | Führerscheinklasse ${teaser.category} in Tarup und Satrup`}
        description={metaDescription.text}
      />
      <div className="bg-purple-50  mt-20 sm:mt-32 xl:mt-40 ">
        <div className="container-fluid">
          <LicencesNavigation
            isDetailPage={true}
            activeURL={
              // Ziehe Führerscheinklasse aus aktueller Seitenpostion (location.pathname)
              location.pathname
                .split("/")[2]
                .substring(location.pathname.split("/")[2].lastIndexOf("-") + 1)
            }
            links={links}
          />
        </div>
      </div>
      <div className="my-24 md:my-12 md:py-12 lg:my-24 lg:py-24 xl:my-28 xl:py-28 xll:my-32 xll:py-32 xxxl:py-48 xxxl:my-48  overflow-hidden">
        <div className="container-fluid ">
          <LicenceTeaserComponent
            category={teaser?.category}
            title={teaser?.title}
            description={teaser?.description}
            categoryIcon={teaser.icon.childImageSharp?.gatsbyImageData}
            teaserImage={teaser.image.childImageSharp?.gatsbyImageData}
          />
        </div>
      </div>
      <div className="container-fluid">
        <div className=" mb-12 md:mb-16 xl:mb-32 xxxl:mb-48">
          <LicenceInfoBox
            title="Voraussetzungen"
            location={location.pathname.split("/")[2]}
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query GetMarkdownPage($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        category
        contentType
        description
        id
        slug
        title
        icon {
          childImageSharp {
            gatsbyImageData
          }
        }
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { contentType: { eq: "licenceTeaser" } } }
      sort: { fields: frontmatter___id, order: ASC }
    ) {
      nodes {
        frontmatter {
          category
          slug
        }
      }
    }
  }
`

export default LicenceDetailTemplate
