import React from "react"
import { useEffect } from "react"

import RegisterButton from "../shared/registerButton"
import { StaticImage } from "gatsby-plugin-image"
import gsap from "gsap"
import SimpleButton from "../shared/simpleButton"

const UeberUnsSection = (): JSX.Element => {
  // Use Effect für ScrollAnimationen
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: "#komp-section",
          start: "top center-=25%",
        },
      })
      .from(
        "#komp-img",
        {
          y: 200,
          opacity: 0,
          ease: "power4.out",
        },
        "-=0.5"
      )
      .from(
        "#komp-title",
        {
          y: -100,
          opacity: 0,
          ease: "power4.out",
        },
        "-=0.3"
      )
      .from("#komp-text", {
        opacity: 0,
        ease: "power4.out",
      })
      .from(
        "#komp-btn",
        {
          opacity: 0,
          ease: "power4.out",
        },
        "-=0.3"
      )
  })

  return (
    <div
      id="komp-section"
      className="container-fluid py-12 sm:py-16 xl:py-32 xxxl:py-48 sm:flex sm:flex-wrap lg:pt-2 xl:pt-16 xxl:pt-32 snap-center"
    >
      <h2
        id="komp-title"
        className="lg:w-2/3 lg:transform lg:translate-y-14 xl:translate-y-16 lg:z-10"
      >
        Deine Fahrschule, die Spaß mit Kompetenz verbindet.
      </h2>
      {/* Reihe */}
      <div className="sm:flex sm:flex-nowrap sm:space-x-8 lg:space-x-16">
        {/* Spalte 1 */}
        <div className="sm:w-1/2 md:w-2/3">
          <div id="komp-img" className="img-wrapper lg:pl-16 xll:pl-24">
            <StaticImage
              src="../../images/AchimsFahrschule-Achim-Wicka-dsktp.jpg"
              alt="Foto vom Fahrlehrer Achim Wicka"
            ></StaticImage>
          </div>
        </div>
        {/* Spalte 2 */}
        <div className="sm:w-1/2 md:w-1/3 sm:flex sm:flex-col sm:justify-center">
          {/* Text */}
          <p id="komp-text">
            Endlich unabhängig sein! Der Weg zur Unabhängigkeit durch deinen
            eigenen Führerschein muss gar nicht stressig oder kompliziert sein.
            Wir nehmen uns die nötige Zeit um dich an dein Ziel zu führen. Mit
            unterschiedlichen Methoden gewährleisten wir, dass jeder unserer
            Fahrschüler individuell gefördert und geschult wird. Das macht nicht
            nur Spaß, sondern ist auch super effizient.
          </p>
          {/* Button */}
          <SimpleButton to="/dein-weg-zum-fuehrerschein">
            Dein Weg zum Führerschein
          </SimpleButton>
        </div>{" "}
      </div>
    </div>
  )
}

export default UeberUnsSection
