export function formatLinks<
  T extends {
    frontmatter: GatsbyTypes.Maybe<
      Pick<GatsbyTypes.MarkdownRemarkFrontmatter, "slug" | "category">
    >
  }
>(nodes: T[]): Map<string, string> {
  return nodes.reduce<Map<string, string>>((allLinks, node) => {
    if (
      node.frontmatter &&
      node.frontmatter.slug &&
      node.frontmatter.category
    ) {
      const { slug, category } = node.frontmatter
      return allLinks.set(category, slug)
    }
    return allLinks
  }, new Map())
}
