import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import GuideLayout from "./guideLayout"
import Street2 from "./street2"
import { useState } from "react"

import Collapsible from "react-collapsible"
import MoreButton from "./moreButton"

const Guide4 = (): JSX.Element => {
  const [activeText, toggleText] = useState<boolean>(false)

  const onHandleClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    toggleText(!activeText)
  }

  return (
    <div className="overflow-hidden relative flex justify-center guide-section ">
      {/* Background Image */}
      <Street2 className="absolute -z-10 h-full w-full scale-105 translate-x-32  md:translate-x-9" />
      <GuideLayout>
        {/* Background Image */}
        <div></div>
        {/* Reihe  */}
        <div className="lg:flex sm:space-x-12  ">
          {/* Spalte 1 */}
          <div className="w-full sm:w-1/2 md:w-2/3">
            <div className=" relative z-10  h-20 xss:h-14 sm:h-28 md:h-32 lg:h-16 xl:h-32">
              <h2 className="animated-left">
                Ein Kraftfahrzeug selbstständig führen
              </h2>
            </div>
            <div className="img-wrapper pr-12 sm:pr-0 w-full overflow-hidden sm:flex sm:justify-end animated-img-left">
              <StaticImage
                className="w-full h-full object-cover sm:w-5/6"
                src="../../images/AchimsFahrschule-Fahren.jpg"
                alt="AchimsFahrschule-Fahren"
              />
            </div>
          </div>
          {/* Spalte 2 */}
          <div className="sm:flex sm:flex-col sm:justify-center lg:w-1/3 flex flex-col overflow-hidden relative">
            <p className="sm:w-5/6 animated-opacity bg-white lg:bg-transparent ">
              Parallel zum Unterricht und dem Üben für die theoretische Prüfung,
              kannst du anfangen das Fahren zu üben. Dabei übernimmst du unter
              Begleitung und Einweisung deines Fahrlehrers selbständig die
              Führung eines Kraftfahrzeugs. Als erstes startest du mit
              Übungsstunden, bei denen du dich mit der Bedienung des Fahrzeugs
              vertraut machst.
            </p>
            <Collapsible
              easing="ease-in-out"
              className="flex flex-col "
              openedClassName="flex flex-col"
              triggerClassName="order-1"
              triggerOpenedClassName="order-1"
              transitionTime={150}
              trigger={
                <div
                  onClick={onHandleClick}
                  className=" relative w-full animated-opacity order-1"
                >
                  <MoreButton>
                    {activeText === true ? "Weniger anzeigen" : "Weiterlesen"}
                  </MoreButton>
                </div>
              }
            >
              <p className="sm:w-5/6 animated-opacity bg-white lg:bg-transparent ">
                Dies dient dazu, dass du ein Gefühl für das geführte Fahrzeug
                erhältst und lernst damit umzugehen. Anschließend fährst du mit
                den Pflicht- und Spezialstunden fort. Insgesamt gibt es 12
                Pflichtstunden, die jeweils 45 Minuten dauern. Davon sind drei
                nachts, vier auf der Autobahn und fünf Überlandfahrten.
                Zusätzlich müssen, abhängig der zu erlernenden
                Führerscheinklasse, 4 bis 6 Spezialstunden absolviert werden.
              </p>
            </Collapsible>
          </div>
        </div>
      </GuideLayout>
    </div>
  )
}

export default Guide4
