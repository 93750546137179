/**
 *  Home page
 *
 *  This is considered to be the landing page
 *  Url, http://localhost:3000/
 *
 *  It contains a basic example on how to use images in gatsby
 */

import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import GuideGreenLayout from "../components/guide/guideGreenLayout"
import GuideLayout from "../components/guide/guideLayout"
import Guide1 from "../components/guide/Guide1"

import "../styles/dein-weg-zum-fuehrerschein.css"
import Guide2 from "../components/guide/Guide2"
import Guide3 from "../components/guide/Guide3"
import Guide4 from "../components/guide/Guide4"
import Guide5 from "../components/guide/Guide5"
import Street1 from "../components/guide/street1"

import { StaticImage } from "gatsby-plugin-image"
import { PageProps } from "gatsby"
import Street2 from "../components/guide/street2"

import { gsap } from "gsap"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const { useEffect, useRef } = React

const Index = ({ location }: PageProps): JSX.Element => {
  const el = React.useRef<HTMLDivElement>(null)
  const q = gsap.utils.selector(el)

  // wait until DOM has been rendered
  useEffect(() => {
    gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger)
    // Street Animation Intro
    // Street Linke Seitenbahn
    gsap.from(".street1 .animated-form", {
      drawSVG: "0% 0%",
      ease: "power1.inOut",
      duration: 1.5,
      opacity: 1,
    })
    // // Street Rechte Seitenbahn
    gsap.from(".street1 .animated-form-reverse", {
      drawSVG: "100% 100%",
      ease: "power1.inOut",
      duration: 1.5,
      opacity: 1,
    })
    // // Street Sprustreifen
    gsap.from(".street1 .animated-stripes", {
      opacity: 0,
      stagger: {
        from: "end",
        amount: 1.5,
        ease: "power1.inout",
      },
    })
    // // Animation Guide Sections
    const sections = gsap.utils.toArray(".guide-section")
    sections.forEach((section, timeline) => {
      //Heading, Text und Button Animation
      timeline = gsap.timeline({
        scrollTrigger: {
          trigger: section,
          start: "top bottom-=15%",
          end: "bottom center+=50%",
          scrub: true,
        },
      })
      console.log(section.querySelector("div.animated-img-right"))
      timeline
        .from(
          section.getElementsByClassName(
            section.querySelector("div.animated-img-right") == null
              ? "animated-img-left"
              : "animated-img-right"
          ),
          0.39,
          {
            x:
              section.querySelector("div.animated-img-right") == null
                ? -250
                : 250,
            opacity: 0,
          }
        )
        .from(
          section.getElementsByClassName("animated-left"),
          {
            x: -500,
            opacity: 0,
          },
          "-=0.43"
        )
        .from(section.getElementsByClassName("animated-opacity"), {
          opacity: 0,
        })
      //   //Spurstreifen
      gsap.from(section.getElementsByClassName("animated-stripes"), {
        opacity: 0,
        y: 300,
        stagger: {
          from: "end",
          ease: "power1.inout",
          amount: 1,
        },
        scrollTrigger: {
          trigger: section,
          start: "top center+=25%",
          end: "bottom center+=25%",
          scrub: true,
        },
      })
      //   //Seitenbahn
      gsap.from(section.getElementsByClassName("animated-form"), {
        drawSVG: "0%",
        ease: "power1.inOut",
        scrollTrigger: {
          trigger: section,
          start: "top center+=25%",
          end: "bottom center+=25%",
          scrub: true,
        },
      })
    })
  }, [])

  return (
    <Layout location={location.pathname}>
      <SEO
        title="Achims Fahrschule | Dein Weg zum Führerschein"
        description="Wir zeigen dir die Schritte, die du gehen musst, bis du deinen eigenen Führerschein in den Händen hältst. Transparent und unkompliziert."
      />
      <div ref={el} id="dein-weg">
        {/* Intro */}
        <div className="container-fluid first-section-spacing-top section-vertical-spacing relative overflow-hidden flex flex-wrap xl:pb-80">
          <h1 className="w-full sm:w-7/12">Dein Weg zum Führerschein</h1>
          <div className="w-full ">
            <p className="w-full md:w-8/12 lg:w-5/12 bg-white">
              Komm während unserer Öffnungszeiten in einer unserer Fahrschulen
              in Flensburg Tarup oder Satrup vorbei. Dort beantworten wir deine
              offenen Fragen und nehmen deine Anmeldung entgegen. Nutze unser
              Angebot der Voranmeldung, um Zeit vor Ort zu sparen.
            </p>
            <p className="w-full md:w-8/12 lg:w-5/12 bg-white">
              Denn hierbei kannst du deine Daten bereits im Vorfeld bei uns
              hinterlegen. Nimm bitte deinen Ausweis oder ein vergleichbares
              Dokument mit in die Fahrschule, da dieses für die Anmeldung
              benötigt wird. Wir freuen uns auf dich!
            </p>
          </div>
          {/* Street  */}
          <div className="h-full w-full absolute -z-10 flex justify-end -right-1/2 sm:-right-1/4 xl:right-px">
            <Street1 className="street1 h-full  scale-150 xl:scale-100" />
          </div>
        </div>
        <Guide1 />
        <Guide2 />
        <Guide3 />
        <Guide4 />
        <Guide5 />
        <div className="h-48 md:h-96 w-full relative guide-section -z-10 overflow-visible">
          <Street2 className="last-street h-full w-full md:scale-150 translate-x-64  md:translate-x-9" />
        </div>
      </div>
    </Layout>
  )
}

export default Index
