import React from "react"
import { classNameProps } from "../../types/classNameProps"

const Logo = ({ className, isWhite = false }: classNameProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="244.148"
      height="56.983"
      viewBox="0 0 244.148 56.983"
      className={className}
    >
      <g
        id="Gruppe_2196"
        data-name="Gruppe 2196"
        transform="translate(6042.162 4767.115)"
      >
        <g
          id="Gruppe_2195"
          data-name="Gruppe 2195"
          transform="translate(-5967.16 -4722.949)"
        >
          <path
            id="Pfad_7658"
            data-name="Pfad 7658"
            d="M752.741,460.591v4.369h6.283V466.5h-6.283v5.065H750.95v-12.53h8.843v1.557Z"
            transform="translate(-750.95 -458.891)"
            fill={isWhite ? "white" : "#554b6d"}
          />
          <path
            id="Pfad_7659"
            data-name="Pfad 7659"
            d="M778.771,468.432h-6.66l-1.378,3.132h-1.844l5.675-12.53h1.773l5.692,12.53h-1.88ZM778.144,467l-2.7-6.141-2.7,6.141Z"
            transform="translate(-755.305 -458.891)"
            fill={isWhite ? "white" : "#554B6D"}
          />
          <path
            id="Pfad_7660"
            data-name="Pfad 7660"
            d="M805.667,459.034v12.53h-1.791V465.98h-7.2v5.585h-1.79v-12.53h1.79v5.388h7.2v-5.388Z"
            transform="translate(-761.616 -458.891)"
            fill={isWhite ? "white" : "#554B6D"}
          />
          <path
            id="Pfad_7661"
            data-name="Pfad 7661"
            d="M828.653,471.564l-2.7-3.848c-.251.017-.519.036-.788.036h-3.1v3.813h-1.789v-12.53h4.887c3.258,0,5.227,1.647,5.227,4.368a3.954,3.954,0,0,1-2.757,3.956l2.972,4.207ZM828.6,463.4c0-1.79-1.2-2.811-3.491-2.811h-3.043v5.639h3.043C827.4,466.23,828.6,465.192,828.6,463.4Z"
            transform="translate(-767.778 -458.891)"
            fill={isWhite ? "white" : "#554B6D"}
          />
          <path
            id="Pfad_7662"
            data-name="Pfad 7662"
            d="M842.137,470.087l.662-1.4a6.454,6.454,0,0,0,4.135,1.45c2.167,0,3.1-.841,3.1-1.933,0-3.062-7.59-1.128-7.59-5.782,0-1.933,1.5-3.58,4.8-3.58a7.368,7.368,0,0,1,4.028,1.11l-.591,1.431a6.477,6.477,0,0,0-3.437-1.02c-2.13,0-3.043.9-3.043,1.987,0,3.062,7.59,1.146,7.59,5.747,0,1.914-1.54,3.561-4.852,3.561A7.442,7.442,0,0,1,842.137,470.087Z"
            transform="translate(-773.085 -458.845)"
            fill={isWhite ? "white" : "#554B6D"}
          />
          <path
            id="Pfad_7663"
            data-name="Pfad 7663"
            d="M863.172,465.254a6.338,6.338,0,0,1,6.641-6.409,6.193,6.193,0,0,1,4.762,1.951l-1.163,1.128a4.642,4.642,0,0,0-3.527-1.486,4.817,4.817,0,1,0,0,9.631,4.659,4.659,0,0,0,3.527-1.5l1.163,1.128a6.218,6.218,0,0,1-4.78,1.969A6.333,6.333,0,0,1,863.172,465.254Z"
            transform="translate(-778.191 -458.845)"
            fill={isWhite ? "white" : "#554B6D"}
          />
          <path
            id="Pfad_7664"
            data-name="Pfad 7664"
            d="M898.578,459.034v12.53h-1.79V465.98h-7.2v5.585H887.8v-12.53h1.79v5.388h7.2v-5.388Z"
            transform="translate(-784.169 -458.891)"
            fill={isWhite ? "white" : "#554B6D"}
          />
          <path
            id="Pfad_7665"
            data-name="Pfad 7665"
            d="M913.044,466.159v-7.125h1.79v7.052c0,2.775,1.271,4.029,3.527,4.029s3.544-1.253,3.544-4.029v-7.052h1.736v7.125c0,3.633-1.986,5.548-5.3,5.548C915.049,471.708,913.044,469.792,913.044,466.159Z"
            transform="translate(-790.296 -458.891)"
            fill={isWhite ? "white" : "#554B6D"}
          />
          <path
            id="Pfad_7666"
            data-name="Pfad 7666"
            d="M938.075,459.034h1.789v10.973h6.785v1.557h-8.574Z"
            transform="translate(-796.372 -458.891)"
            fill={isWhite ? "white" : "#554B6D"}
          />
          <path
            id="Pfad_7667"
            data-name="Pfad 7667"
            d="M967.4,470.007v1.557h-9.094v-12.53h8.843v1.557H960.1v3.85h6.283v1.521H960.1v4.045Z"
            transform="translate(-801.283 -458.891)"
            fill={isWhite ? "white" : "#554B6D"}
          />
        </g>
        <path
          id="Pfad_7696"
          data-name="Pfad 7696"
          d="M743.159,419.016c0-10.724,8.206-18.327,19.383-18.327,6.5,0,11.731,2.366,15.154,6.646l-6.344,5.739a10.42,10.42,0,0,0-8.307-4.128c-5.79,0-9.817,4.027-9.817,10.069s4.027,10.068,9.817,10.068a10.42,10.42,0,0,0,8.307-4.128l6.344,5.74c-3.424,4.279-8.659,6.645-15.154,6.645C751.365,437.34,743.159,429.738,743.159,419.016Z"
          transform="translate(-6716.22 -5167.678)"
          fill={isWhite ? "white" : "#94C337"}
        />
        <path
          id="Pfad_7697"
          data-name="Pfad 7697"
          d="M828.943,401.62v35.241h-9.969V423.118H805.382v13.744h-9.968V401.62h9.968v13.241h13.593V401.62Z"
          transform="translate(-6728.903 -5167.904)"
          fill={isWhite ? "white" : "#94C337"}
        />
        <path
          id="Pfad_7699"
          data-name="Pfad 7699"
          d="M849.795,401.62h9.969v35.241h-9.969Z"
          transform="translate(-6742.104 -5167.904)"
          fill={isWhite ? "white" : "#94C337"}
        />
        <path
          id="Pfad_7700"
          data-name="Pfad 7700"
          d="M904.948,436.861l-.1-18.678-9.062,15.205h-4.431l-9.011-14.7v18.174H873.13V401.62h8.206l12.384,20.34L905.8,401.62h8.206l.1,35.241Z"
          transform="translate(-6747.769 -5167.904)"
          fill={isWhite ? "white" : "#94C337"}
        />
        <path
          id="Pfad_7701"
          data-name="Pfad 7701"
          d="M934.094,433.464l3.272-7.351A22.215,22.215,0,0,0,949,429.588c4.128,0,5.739-1.158,5.739-2.869,0-5.589-20.037-1.511-20.037-14.6,0-6.294,5.135-11.429,15.606-11.429a26.389,26.389,0,0,1,12.788,3.071l-3.071,7.4a20.827,20.827,0,0,0-9.766-2.719c-4.179,0-5.689,1.409-5.689,3.173,0,5.386,19.987,1.359,19.987,14.348,0,6.142-5.136,11.378-15.607,11.378C943.156,437.34,937.417,435.78,934.094,433.464Z"
          transform="translate(-6762.566 -5167.678)"
          fill={isWhite ? "white" : "#94C337"}
        />
        <path
          id="Pfad_7698"
          data-name="Pfad 7698"
          d="M725.137,417.379v18.965H715.2v-7.776h-13.7v7.776h-9.783V417.379c0-10.836,6.773-16.857,16.707-16.857S725.137,406.543,725.137,417.379Zm-9.934,3.512v-4.364c0-5.318-2.809-7.776-6.823-7.776-4.064,0-6.874,2.458-6.874,7.776v4.364Z"
          transform="translate(-6703.734 -5167.637)"
          fill={isWhite ? "white" : "#94C337"}
        />
        <rect
          id="Rechteck_242"
          data-name="Rechteck 242"
          width="7.101"
          height="4.679"
          transform="translate(-6026.455 -4739.279)"
          fill={isWhite ? "white" : "#28044a"}
        />
        <rect
          id="Rechteck_243"
          data-name="Rechteck 243"
          width="7.101"
          height="4.679"
          transform="translate(-6042.162 -4739.279)"
          fill={isWhite ? "white" : "#28044a"}
        />
        <rect
          id="Rechteck_244"
          data-name="Rechteck 244"
          width="24.275"
          height="4.679"
          transform="translate(-6042.162 -4752.816)"
          fill={isWhite ? "white" : "#28044a"}
        />
        <rect
          id="Rechteck_245"
          data-name="Rechteck 245"
          width="61.238"
          height="4.679"
          transform="translate(-6042.162 -4724.452)"
          fill={isWhite ? "white" : "#28044a"}
        />
      </g>
    </svg>
  )
}
export default Logo
