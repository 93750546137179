import React from "react"

type GuideLayoutProps = {
  children?: React.ReactNode
}

const GuideLayout = ({ children }: GuideLayoutProps): JSX.Element => {
  return (
    <div className="relative flex justify-center items-center section-vertical-spacing overflow-hidden guide-layout">
      {/* Content */}
      <div className="relative container-fluid">{children}</div>
    </div>
  )
}

export default GuideLayout
