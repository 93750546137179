import React from "react"
import { classNameProps } from "../../types/classNameProps"
import RegisterButton from "./registerButton"
import { Link, useStaticQuery, graphql } from "gatsby"
import { EmailIcon, PhoneIcon, WhatsappIcon } from "../shared/icons"
import "./contactLinks.css"

interface contactLinksProps extends classNameProps {
  isRendered?: boolean
}

const ContactLinks = ({
  className,
  isRendered = true,
}: contactLinksProps): JSX.Element => {
  const data = useStaticQuery<GatsbyTypes.GetContactDataLightQuery>(graphql`
    query GetContactDataLight {
      site {
        siteMetadata {
          telefon
          email
          whatsapp
        }
      }
    }
  `)

  if (!data.site?.siteMetadata)
    throw new Error("SiteMetaData.title possibly undefined")

  const contactData = data.site?.siteMetadata

  console.log(contactData)

  // Make Numbers only
  const makeDigits = (string: string): string => {
    const res = string.replace(/\D/g, "")
    return res
  }

  return (
    <ul className="contact-link-list">
      {isRendered ? (
        <li>
          Hast du Fragen? Rufe uns an oder schicke
          <br /> uns eine WhatsApp- Nachricht!
        </li>
      ) : (
        ""
      )}
      <li>
        <EmailIcon className="contact-icon" />
        <a href={`mailto:${contactData.email}`}>E-Mail</a>
      </li>
      <li>
        <PhoneIcon className="contact-icon" />
        <a href={`tel:${makeDigits(JSON.stringify(contactData.telefon))}`}>
          {contactData.telefon}
        </a>
      </li>
      <li>
        <WhatsappIcon className="contact-icon" />
        <a href={`https://wa.me/${contactData.whatsapp}`}>WhatsApp</a>
      </li>
      <li>
        <RegisterButton>Voranmeldung</RegisterButton>
      </li>
    </ul>
  )
}

export default ContactLinks
