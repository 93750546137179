import React from "react"

type GuideGreenLayoutProps = {
  children?: React.ReactNode
}

const GuideGreenLayout = ({ children }: GuideGreenLayoutProps): JSX.Element => {
  return (
    <div className="relative flex justify-center items-center section-vertical-spacing-green">
      {/* Background */}
      <div className="w-full scale-x-125 h-full absolute bg-primary rotate-1"></div>
      {/* Content */}
      <div className="relative container-fluid">{children}</div>
    </div>
  )
}

export default GuideGreenLayout
