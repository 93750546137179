import { Link } from "gatsby"
import React from "react"

type SimpleButtonProps = {
  children: React.ReactNode
  id?: string
  to?: string
}

const SimpleButton = ({
  children,
  id,
  to = "/",
}: SimpleButtonProps): JSX.Element => {
  return (
    <Link to={to}>
      <button
        id={id}
        className="bg-secondary w-fit text-white text-sm xll:text-lg px-6 py-3 text-bold rounded-full transition-colors hover:bg-primary"
      >
        {children}
      </button>
    </Link>
  )

  // <Link
  //   to="/"
  //   className="bg-secondary text-white text-sm xll:text-lg px-6 py-3 text-ultrabold"
  // >
  //   {children}
  // </Link>
}

export default SimpleButton
