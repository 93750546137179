/**
 *  Imprint page
 *
 *  This is considered to be the landing page
 *  Url, http://localhost:3000/
 *
 *  It contains a basic example on how to use images in gatsby
 */

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactLinks from "../components/shared/contactLinks"

import Location from "../components/contact/location"
import "../components/contact/contact.css"

const Index = (): JSX.Element => {
  const [isChecked, setIsChecked] = useState<boolean>(false)

  // ErrorMessage State
  const [errorMessage, setErrorMessage] = useState<string>("")

  // Check if Form was succesfully sent
  const [isSending, setIsSending] = useState<boolean>(false)

  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  /**
   * Quellen : https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/forms_and_events/
   * https://stackoverflow.com/questions/59813926/usestate-to-update-multiple-values-in-react
   *
   */
  // Inputfelder State
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    website: "",
  })

  // Textarea State
  const [textareaValue, setTextareaValue] = useState<string>("")

  // Input Felder Change Listener
  const changeHandler = (e: React.FormEvent<HTMLInputElement>): void => {
    setFormValues({
      ...formValues,
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  // textArea Feld Change Listener
  const changeTextarea = (e: any): void => {
    setTextareaValue(e.currentTarget.value)
  }

  // Validierung des Datenschutz Inputs
  const checkInput = (event: any): void => {
    setIsChecked((current) => !current)
  }

  // Wurde die Checkbox Datenschutz geklick?
  const checkForm = (event: any): void => {
    event.preventDefault()

    console.log("Checkform...")
    if (isChecked) {
      // Sende Nachricht an Server
      submit(event)
    } else setErrorMessage("Du musst den Datenschutzerklärungen zustimmen!")
  }

  // Sende Nachricht an Backend
  const submit = (event: any): void => {
    event.preventDefault()

    setIsSending(true)

    fetch(
      "https://cms.achimsfahrschule.net/api/forms/submit/kontaktformular?token=697eba4bf671957e73ff9354a57194",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          form: {
            name: formValues.name,
            email: formValues.email,
            message: textareaValue,
            // Wenn der Wert von Website Input nicht leer ist, soll dieser mitgeschickt werden( Nachricht wird dann über das Backend an HoneyPot Formular gesendet)
            ...(formValues.website != "" && { website: formValues.website }),
          },
        }),
      }
    )
      .then((entry) => entry.json())
      .then((entry) => {
        setIsSending(false)

        if (entry.error) {
          setErrorMessage(entry.error)
        } else {
          setErrorMessage("Deine Nachricht wurde erfolgreich versendet!")
          setIsSuccess(true)
          setTextareaValue("")
          setFormValues({ name: "", email: "", website: "" })
        }
      })
  }

  return (
    <Layout>
      <SEO
        title="Achims Fahrschule | Nimm Kontakt mit uns auf"
        description="Du hast eine allgemeine Frage oder ein bestimmtes Anliegen? Nutze unsere Kontaktmöglichkeiten, um Kontakt mit uns aufzunehmen!"
      />
      <div className="impressum-page container-fluid ">
        <div className="first-section-spacing-top pt-12 md:pt-16 xl:pt-32 xxxl:pt-48  relative overflow-hidden flex flex-wrap ">
          <h1 className="w-full sm:w-7/12">Kontakt</h1>
        </div>
        {/* Reihe Kontaktlinks / Kontaktformular*/}
        <div className="w-full xl:flex xl:flex-no-wrap section-spacing-bottom xl:space-x-48">
          {/* Verschachtelte Reihe */}
          <div className="w-full sm:flex sm:flex-no-wrap sm:space-x-12 xl:w-1/2 xl:flex-wrap xl:flex-col xl:space-x-0">
            {/* Spalte Text */}
            <div className="sm:w-1/2 xl:w-full xl:h-fit">
              <p>
                Du hast eine allgemeine Frage oder ein bestimmtes Anliegen?
                Nutze unsere Kontaktmöglichkeiten, um Kontakt mit uns
                aufzunehmen!
              </p>
            </div>
            {/* Spalte 2 Kontakt */}
            <div className="sm:w-1/2 sm:flex sm:justify-center sm:items-center mt-10 sm:mt-0 xl:items-start xl:justify-start xl:w-full">
              <ContactLinks className="relative" isRendered={false} />
            </div>
          </div>
          {/* Kontaktformular */}
          <div className="w-full xl:w-1/2 mt-12 md:mt-24 xl:mt-0 relative">
            {/* Sending Animation */}
            <div
              className={`absolute w-full h-full  justify-center items-center z-10 ${
                isSending == true ? "flex" : "hidden"
              }`}
            >
              <div className="lds-ring border-primary">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            {/* <!--Formular --> */}
            <form
              onSubmit={checkForm}
              id="formular"
              className={`w-full flex flex-col flex-wrap lg:flex-row relative ${
                isSending == true ? "opacity-20" : ""
              }`}
            >
              {/* Inputs */}
              <div className="flex flex-col mb-12 w-full md:flex-row md:flex-wrap ">
                {/* Reihe Name /e_mail */}
                <div className="w-full md:flex md:flex-nowrap md:space-x-6">
                  {/* <!-- name --> */}
                  <div className="w-full ">
                    <label className="" htmlFor="name">
                      Name
                    </label>
                    <input
                      id="name"
                      className="border border-gray-500 w-full"
                      type="text"
                      name="name"
                      placeholder="Name"
                      onChange={changeHandler}
                      value={formValues.name}
                    />
                  </div>
                  {/* <!-- EMail --> */}
                  <div className="w-full">
                    <label className="" htmlFor="email">
                      E-Mail
                    </label>
                    <input
                      id="email"
                      className="border border-gray-500 w-full"
                      type="text"
                      name="email"
                      placeholder="E-Mail"
                      onChange={changeHandler}
                      value={formValues.email}
                    />
                  </div>
                  {/* <!-- Website / Honeypot --> */}
                  <div className="w-full hidden">
                    <label className="" htmlFor="website">
                      Website
                    </label>
                    <input
                      id="website"
                      className="border border-gray-500 w-full"
                      type="text"
                      name="website"
                      placeholder="Website"
                      onChange={changeHandler}
                      value={formValues.website}
                    />
                  </div>
                </div>
              </div>
              {/* <!-- Nachrichten Feld --> */}
              <div className="w-full mb-12">
                <label className="" htmlFor="message">
                  Was möchtest du uns mitteilen?
                </label>
                <textarea
                  id="message"
                  className=" w-full"
                  name="message"
                  placeholder="Deine Nachricht"
                  onChange={changeTextarea}
                  value={textareaValue}
                ></textarea>
              </div>
              {/* <!-- Datenschutzerklärung --> */}
              <div className="message-container w-full mb-12">
                <label className="" htmlFor="datenschutz">
                  Datenschutzerklärung
                </label>
                <div className="w-full flex items-center">
                  <input
                    name="datenschutz"
                    type="checkbox"
                    onChange={checkInput}
                  />
                  <span>
                    Hiermit bestätigst Du, dass Du mit unseren{" "}
                    <a
                      href="/datenschutz"
                      target="_blank"
                      className="text-bold text-secondary hover:cursor-pointer"
                    >
                      Datenschutzerklärungen
                    </a>{" "}
                    einverstanden bist.
                  </span>
                </div>
              </div>
              {/*Success/ Error Messages */}
              <div
                className={`msg-container  w-full text-bold text-lg ${
                  isSuccess ? "text-primary" : "text-red-500"
                } ${errorMessage != "" ? "block mb-12" : "hidden"}`}
              >
                {errorMessage}
              </div>
              {/* <!-- Button Absenden --> */}
              <button
                type="submit"
                className="bg-secondary text-white text-sm xll:text-lg px-6 py-3 text-bold rounded-full"
              >
                Absenden
              </button>
            </form>
          </div>
        </div>
        {/* Anfahrt Öffnungszeiten */}
        <Location />
      </div>
    </Layout>
  )
}

export default Index
