/**
 *  Sample page
 *
 * Just a plain and simple example on how to create a page in gatsby with a custom component
 *
 */

import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SamplePage = ({ path }: PageProps): JSX.Element => (
  <Layout>
    <SEO title="Sample Page" />
    <h1>Hi from the sample page</h1>
    <p>you are currently here, ({path})</p>
    <Link to="/">Go back to the landing page</Link>
  </Layout>
)

export default SamplePage
