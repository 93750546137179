import React from "react"

type DropArrowProps = {
  isList: boolean
}

const DropArrow = ({ isList }: DropArrowProps): JSX.Element => {
  return (
    <div>
      {isList ? (
        <div>
          <svg
            width={10}
            height={6}
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.00016 0.666664L9.66683 5.33333L0.333496 5.33333L5.00016 0.666664Z"
              fill="#1F2937"
            />
          </svg>
        </div>
      ) : (
        <div>
          <svg
            width={10}
            height={6}
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.00016 5.33333L0.333496 0.666664H9.66683L5.00016 5.33333Z"
              fill="#1F2937"
            />
          </svg>
        </div>
      )}
    </div>
  )
}
export default DropArrow
